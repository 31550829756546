import { useState, useContext } from 'react';

import clsx from 'clsx';

// import Modal, { ModalTitle, ModalBody } from '/src/components/Modal';
// import { Button } from '/src/components/Button';

import './history.css';

import { Navigate } from 'react-router-dom';

import { useQuery } from 'react-query';

import { useTranslation } from 'react-i18next';

import { AUTH_CONTEXT } from '/src/context/AuthProvider';

import { unixToFormattedDate } from '/src/services/utils/time';

import api from '/src/services/api';

export const History = () => {
  const { member } = useContext(AUTH_CONTEXT);

  const { token, email } = member;

  const { i18n, t } = useTranslation();

  const [toggle, setToggle] = useState(true);

  const {
    data,
    isLoading,
    isError,
    failureCount
  } = useQuery(
    ['donations_history', {
      token,
      email
    }],
    () => api.getDonationsHistory({
      token,
      email
    }),
    {
      refetchOnWindowFocus: true,
      // this is tricky but it gonna make the query only be executed when we have token and email
      enabled: (!!token && !!email)
    });

  // console.log(data);

  const handleToggleHistory = () => {
    setToggle(!toggle);
  };

  if (isLoading || (!token && !email)) {
    return (
      <div className='loading-container'>
        <div className='lds-dual-ring'/>
      </div>
    );
  }

  if (isError) {
    if (failureCount > 3) {
      return <Navigate
        to='/500'
        state={{ isError: true }}
      />;
    }
    return null;
  };

  return (
    <div className='page-container'>
      <h1 className='page-container__title'>
        {
          t('panel_history')
        }
      </h1>
      <div className='history-columns'>
        <div>{t('history_data')}</div>
        <div>{t('history_project')}</div>
        <div>{t('history_donation')}</div>
        <div>{t('history_receipt')}</div>
      </div>
      <div className='page-card'>
        {
          data?.donations?.map((donation, i) => (
            <div
              key={donation.name + i}
              className={clsx('history-row', toggle && 'active')}
            >
              <div className='history-container'>
                <div>
                  <div className='history-name'>
                    {donation.name || ' - '}
                    <button
                      type='button'
                      className='history-arrow'
                      onClick={() => handleToggleHistory()}
                    >
                    </button>
                  </div>
                  <div className='history-date'>
                    {
                      unixToFormattedDate(donation?.donated_at)
                    }
                  </div>
                </div>
                <div>
                  <div className='history-donation'>
                    <span>
                      {
                        t('history_donation')
                      }
                    </span>
                    {donation.amount}€
                  </div>
                  <div className='history-receipt'>
                    <span>
                      {
                        t('history_receipt')
                      }
                    </span>
                    <button>
                      {
                        t('history_download')
                      }
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
        {
          data?.donations?.length === 0 && (
            <div className='history-row'>
              <div className='history-container'>
                <div>
                  <div className='history-name'>
                    {
                      t('history_no_donations')
                    }
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};
