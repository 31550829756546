const getDonationsHistory = async ({
  token,
  email,
  locale = 'es'
}) => {
  const response = await fetch(`https://dev1.youdrop.org/${locale}/donations/history`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      email
    })
  });

  if (response.status !== 200) {
    throw new Error(response.json());
  }

  return await response.json();
};

export {
  getDonationsHistory
};
