import PropTypes from 'prop-types';

import './style.css';

// ${Icon} {
//   visibility: ${props => (props.checked ? 'visible' : 'hidden')}
// }

export const CheckBox = ({ checked, onChange, ...props }) => {
  return (
    <div onClick={onChange} className='checkbox-container'>
      <input
        type="checkbox"
        className='checkbox--hidden'
        checked={checked}
        onChange={onChange}
        {...props}
        />
      <div className='checkbox'>
        {checked && <div className='checkbox__mark' />}
      </div>
    </div>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func
};
