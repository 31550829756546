// 2021-05-01 format to left days
const getDaysLeft = (date) => {
  const today = new Date();
  const endDate = new Date(date * 1000);
  const timeDiff = endDate.getTime() - today.getTime();

  const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));

  if (daysLeft < 0) {
    return 0;
  }

  return daysLeft;
};

export {
  getDaysLeft
};
