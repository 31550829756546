.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}
.header > button {
  color: var(--color1);
  border: 2px solid var(--color1);
  background: transparent;
  padding: 9px 16px;
  border-radius: 100px;
  transition: all 0.3s ease;
  cursor: pointer;
}
.header .showButton:hover {
  background: var(--color1);
  color: white;
}

.arrows {
  display: none;
  flex-direction: row;
  align-items: center;
}


.arrows button {
  background: transparent;
  border: none;
  cursor: pointer;
  width: 7.5px;
  height: 15px;
  padding: 14px;
}

.arrows button:first-child {
  mask: url(/src/assets/icons/left_arrow_slider.svg) no-repeat center;
  -webkit-mask: url(/src/assets/icons/left_arrow_slider.svg) no-repeat center;
  background-color: var(--color1);
}

.arrows button:last-child {
  mask: url(/src/assets/icons/right_arrow_slider.svg) no-repeat center;
  -webkit-mask: url(/src/assets/icons/right_arrow_slider.svg) no-repeat center;
  background-color: var(--color1);
}

.header > button:hover {
  background: var(--color1);
  color: white;
}

.header h2 {
  font: normal normal bold 2rem/2.6rem Rubik;
  color: #212529;
  display: block;
}