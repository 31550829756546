import { useTranslation } from 'react-i18next';

import * as styles from './AuthorBox.module.css';

const AuthorBox = (props) => {
  return (
    <div className={styles.box}>
      <header>
        {props.title}
      </header>
      {props.children}
    </div>
  );
};

const AuthorHeader = (props) => {
  return (
    <div className={styles.header}>
      <div className={styles.avatar}>
        <img
          alt="Avatar"
          src={props.avatar}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = require('/src/assets/icons/member.svg');
          }}
        />
      </div>
      <div className={styles.name}>
        <span className={styles.nameText}>
          {props.name} {props.lastName}
        </span>
        <span className={styles.positionText}>
          {props.position}
        </span>
      </div>
    </div>
  );
};

const AuthorBody = (props) => {
  return (
    <div className={styles.body}>
      {props.children}
    </div>
  );
};

export {
  AuthorHeader,
  AuthorBody
};

export default AuthorBox;
