// React
import { useState } from 'react';

// Libs
import { Waypoint } from 'react-waypoint';
import { useSpring, animated } from '@react-spring/web';

const Animation = ({ children, delay, animation, className, disableExit }, props) => {
  const [inView, setInview] = useState(false);

  const transition = useSpring({
    delay,
    to: animation(inView)
  });
  return (
    <Waypoint
      onEnter={() => setInview(true)}
      onLeave={() => !disableExit && setInview(false)}
    >
      <animated.div className={className} {...props} style={transition}>
        {children}
      </animated.div>
    </Waypoint>
  );
};

Animation.defaultProps = {
  delay: 500,
  animation: (inView) => ({
    opacity: !inView ? 0 : 1
  }),
  disableExit: false
};

export default Animation;
