import PropTypes from 'prop-types';

export const ArrowSvg = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8.556" height="16.167" viewBox="0 0 8.556 16.167">
      <path id="Path_411" data-name="Path 411" d="M5.329,7.5.227,1.547A.937.937,0,1,1,1.65.328L7.273,6.89a.933.933,0,0,1,0,1.219L1.65,14.672A.937.937,0,1,1,.227,13.453Z" transform="translate(0.501 0.584)" fill={color || '#55c8dc'} stroke={color || '#55c8dc'} strokeWidth="1"/>
    </svg>
  );
};

ArrowSvg.propTypes = {
  color: PropTypes.string
};
