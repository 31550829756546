import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLOR_CONTEXT } from '../context/ColorProvider';

import clsx from 'clsx';

import { animated, useTransition } from '@react-spring/web';

import api from '../services/api';

import { Button } from '../components/Button';
import { IconTick } from '../components/svg/IconTick';

import svgEyeShow from '../assets/icons/eye.svg';
import svgEyeHide from '../assets/icons/eye_slash.svg';

import './password.css';

const initialValidations = {
  password: { validated: true, message: '' },
  passwordConfirmation: { validated: true, message: '' },
};

export const PasswordPage = () => {
  //const navigate = useNavigate();

  const { selectedColor } = useContext(COLOR_CONTEXT);

  const [form, setForm] = useState({
    password: '',
    passwordConfirmation: ''
  });

  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [validations, setValidations] = useState(initialValidations);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [passwordConfirmationFocused, setPasswordConfirmationFocused] = useState(false);

  const validateForm = (name) => {
    const {
      password,
      passwordConfirmation
    } = form;

    const formValidations = { ...initialValidations };

    if (password.length === 0) {
      formValidations.password.message = 'El camp contrasenya no pot estar buit';
      formValidations.password.validated = false;
    }

    if (passwordConfirmation.length === 0) {
      formValidations.passwordConfirmation.message = 'El camp contrasenya no pot estar buit';
      formValidations.passwordConfirmation.validated = false;
    }

    setValidations({ ...formValidations });

    // console.log(Object.values(formValidations));
    return !Object.values(formValidations).some(({ validated }) => !validated);
  };

  const handleBlur = (event) => {
    if (event.target.id === 'password') {
      setPasswordFocused(false);
    }
    else if (event.target.id === 'passwordConfirmation') {
      setPasswordConfirmationFocused(false);
    }
    if (!event.target.validity.valid) {
      invalidateFormValue(event.target.id, 'El camp no és vàlid');
    }
  };

  const handleInputChange = (event, name) => {
    form[name] = event.target.value;
    setForm({ ...form });

    if (event.target.validity.valid) {
      if (name === 'password') {
        setPasswordFocused(false);
      }
      else if (name === 'passwordConfirmation') {
        setPasswordConfirmationFocused(false);
      }

      validateFormValue(name);
    }
    else if (name === 'password') {
      setPasswordFocused(true);
    }
    else if (name === 'passwordConfirmation') {
      setPasswordConfirmationFocused(false);
    }
  };

  return (
    <div className='entrance-box entrance-box--minheight'>
        <div className='password-container'>
          <span className='password-title'>Introdueix una nova contrasenya</span>
          <span className='password-page-desc'>Lorem ipsum dolor sit amet.</span>
          <form
            className='form'
            onSubmit={(e) => {
              e.preventDefault();
              handleResetPassword();
            }}
          >
            <div className='form-group'>
              <label className='form__label'>
                <span className={`form__label__text ${!validations.password.validated && 'form__label__text-error'}`}>
                    Contrasenya
                </span>
                <input
                  onChange={(e) => handleInputChange(e, 'password')}
                  value={form.password}
                  onBlur={handleBlur}
                  onFocus={() => setPasswordFocused(true)}
                  type={showPassword ? 'text' : 'password'}
                  id='password'
                  minLength={8}
                  className={`form-control ${!validations.password.validated && 'form-control--error'}`}
                  pattern='^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,32}$'
                />
                {
                  !validations.password.validated && (
                    <span className='form__label__error-message'>
                      {validations.password.message}
                    </span>
                  )
                }
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPassword(!showPassword);
                  }}
                  type='button'
                  className='password-eye'
                >
                  <object
                    className='password-eye__icon'
                    data={showPassword ? svgEyeShow : svgEyeHide}
                    width="20" height="18"
                    type='image/svg+xml'
                  />
                </button>
              </label>
            </div>
            {
              (<div className={`password-box ${passwordFocused && 'password-box--show'}`}>
                <span className='password-box__info'>La contrasenya ha de incloure :</span>
                <div className='password-box__badges-wrapper'>
                  <span className='password-box__badge'>
                    {
                      form.password.length >= 8 && (<div className='password-box__check-icon'>
                        <IconTick fill={selectedColor?.color2} />
                      </div>)
                    }
                    Mínim 8 caràcters
                  </span>
                  <span className='password-box__badge'>
                    {
                      /(?=.*[A-Z])/.test(form.password) && (<div className='password-box__check-icon'>
                        <IconTick fill={selectedColor?.color2} />
                      </div>)
                    }
                    1 lletra majúscula
                  </span>
                  <span className='password-box__badge'>
                    {
                      /(?=.*[0-9])/.test(form.password) && (<div className='password-box__check-icon'>
                      <IconTick fill={selectedColor?.color2} />
                      </div>)
                    }
                    1 número
                  </span>
                  <span className='password-box__badge'>
                    {
                      /(?=.*[a-z])/.test(form.password) && (<div className='password-box__check-icon'>
                        <IconTick fill={selectedColor?.color2} />
                      </div>)
                    }
                    1 lletra minúscula
                  </span>
                  <span className='password-box__badge'>
                    {
                      /(?=.*[!@#$%^&*])/.test(form.password) && (<div className='password-box__check-icon'>
                        <IconTick fill={selectedColor?.color2} />
                      </div>)
                    }
                    1 símbol: ! @ + - . $ € % = #
                  </span>
                </div>
              </div>)
            }
            <div className='form-group'>
              <label className='form__label'>
                <span className='form__label__text'>Confirma la contrasenya</span>
                <input
                  onChange={(e) => setForm({ ...form, passwordConfirmation: e.target.value })}
                  value={form.passwordConfirmation}
                  type='password'
                  minLength={8}
                  className={`form-control ${!validations.passwordConfirmation.validated && 'form-control--error'}`}
                  pattern='^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,32}$'
                />
                {
                  !validations.passwordConfirmation.validated && (
                    <span className='form__label__error-message'>
                      {validations.passwordConfirmation.message}
                    </span>
                  )
                }
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPasswordConfirmation(!showPasswordConfirmation);
                  }}
                  type='button'
                  className='password-eye'
                >
                  <object
                    className='password-eye__icon'
                    data={showPasswordConfirmation ? svgEyeShow : svgEyeHide}
                    width="20" height="18"
                    type='image/svg+xml'
                  />
                </button>
              </label>
            </div>
            <Button
              style={{ backgroundColor: selectedColor?.color2 }}
              type='submit'
              className='form-button'
              disabled={loading}
            >
              CANVIAR CONTRASENYA
            </Button>
          </form>
        </div>
    </div>
  );
};
