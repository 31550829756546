
import PropTypes from 'prop-types';

export const LogoSvg = ({ color }) => {
  // const media = window.matchMedia('(min-width: 62rem)');

  // const width = media.matches ? '128' : '106';
  // const height = media.matches ? '120' : '100';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="150.909" height="30" viewBox="0 0 150.909 30">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_193" data-name="Rectangle 193" width="150.909" height="30" fill={color}/>
        </clipPath>
      </defs>
      <g id="Group_145" data-name="Group 145" transform="translate(0 -19)">
        <g id="Group_132" data-name="Group 132" transform="translate(0 19)" clipPath="url(#clip-path)">
          <path id="Path_371" data-name="Path 371" d="M14.455,30A14.391,14.391,0,0,1,4.229,25.756a14.566,14.566,0,0,1,0-20.524L9.443,0l5.022,5.077L19.527.06l5.154,5.172a14.566,14.566,0,0,1,0,20.524A14.391,14.391,0,0,1,14.455,30M9.436,3.792,6.115,7.124a11.815,11.815,0,0,0,16.68,16.739,11.88,11.88,0,0,0,0-16.739l-3.28-3.291-3.168,3.14,3.3,3.311a7.4,7.4,0,0,1,0,10.419,7.329,7.329,0,0,1-10.382,0,7.4,7.4,0,0,1,0-10.419l3.309-3.32ZM14.455,8.86,11.15,12.177a4.708,4.708,0,0,0,0,6.634,4.668,4.668,0,0,0,6.61,0,4.709,4.709,0,0,0,0-6.634Z" transform="translate(0)" fill={color}/>
          <path id="Path_372" data-name="Path 372" d="M684.651,115.655a.616.616,0,0,1-.637-.637v-6.536l-6.675-11.3-.055-.166a.609.609,0,0,1-.028-.194.568.568,0,0,1,.554-.554h1.44a.8.8,0,0,1,.443.125.689.689,0,0,1,.277.346l5.4,8.973,5.4-8.973a1.154,1.154,0,0,1,.319-.346.735.735,0,0,1,.429-.125h1.413a.564.564,0,0,1,.416.166.532.532,0,0,1,.166.388.621.621,0,0,1-.028.194.709.709,0,0,1-.083.166l-6.647,11.3v6.536a.619.619,0,0,1-.18.457.654.654,0,0,1-.484.18Z" transform="translate(-640.315 -91.017)" fill={color}/>
          <path id="Path_373" data-name="Path 373" d="M980.32,197.544a7.032,7.032,0,0,1-3.518-.8,5.4,5.4,0,0,1-2.16-2.23,7.67,7.67,0,0,1-.831-3.254q-.028-.471-.028-1.2t.028-1.177a7.5,7.5,0,0,1,.845-3.268,5.517,5.517,0,0,1,2.174-2.216,7.982,7.982,0,0,1,6.979,0,5.522,5.522,0,0,1,2.174,2.216,7.515,7.515,0,0,1,.845,3.268q.027.443.028,1.177t-.028,1.2a7.677,7.677,0,0,1-.831,3.254,5.4,5.4,0,0,1-2.16,2.23,7.032,7.032,0,0,1-3.517.8m0-2.133a3.577,3.577,0,0,0,2.742-1.094,4.808,4.808,0,0,0,1.108-3.2q.027-.415.028-1.052t-.028-1.052a4.809,4.809,0,0,0-1.108-3.2,3.579,3.579,0,0,0-2.742-1.094,3.623,3.623,0,0,0-2.756,1.094,4.665,4.665,0,0,0-1.094,3.2q-.028.415-.028,1.052t.028,1.052a4.663,4.663,0,0,0,1.094,3.2,3.621,3.621,0,0,0,2.756,1.094" transform="translate(-920.668 -172.629)" fill={color}/>
          <path id="Path_374" data-name="Path 374" d="M1279.883,202.344a5.456,5.456,0,0,1-3.005-.789,4.967,4.967,0,0,1-1.869-2.2,7.8,7.8,0,0,1-.637-3.24V188.3a.616.616,0,0,1,.637-.637h1.385a.616.616,0,0,1,.637.637v7.672q0,4.127,3.6,4.127a3.622,3.622,0,0,0,2.756-1.094,4.226,4.226,0,0,0,1.039-3.033V188.3a.616.616,0,0,1,.637-.637h1.357a.608.608,0,0,1,.471.18.649.649,0,0,1,.166.457V201.43a.647.647,0,0,1-.166.457.607.607,0,0,1-.471.18h-1.274a.616.616,0,0,1-.637-.637v-1.219a5.745,5.745,0,0,1-1.814,1.551,5.865,5.865,0,0,1-2.811.582" transform="translate(-1204.861 -177.429)" fill={color}/>
          <path id="Path_375" data-name="Path 375" d="M1589.814,115.655a.607.607,0,0,1-.471-.18.646.646,0,0,1-.166-.457V96.933a.687.687,0,0,1,.166-.485.609.609,0,0,1,.471-.18h6.287a10.477,10.477,0,0,1,4.6.859,5.35,5.35,0,0,1,2.548,2.534,9.729,9.729,0,0,1,.831,4.113q.027,1.246.028,2.188t-.028,2.16a10.463,10.463,0,0,1-.845,4.224,5.112,5.112,0,0,1-2.492,2.493,10.488,10.488,0,0,1-4.5.817Zm2.077-2.354h4.21a7.34,7.34,0,0,0,3.047-.526,3.238,3.238,0,0,0,1.648-1.689,8.03,8.03,0,0,0,.54-3.074q.055-.831.055-1.454v-1.246q0-.623-.055-1.426a5.8,5.8,0,0,0-1.274-3.974q-1.219-1.288-4.1-1.288h-4.071Z" transform="translate(-1502.495 -91.017)" fill={color}/>
          <path id="Path_376" data-name="Path 376" d="M1933.058,202.067a.616.616,0,0,1-.637-.637v-13.1a.666.666,0,0,1,.18-.471.6.6,0,0,1,.457-.194h1.274a.662.662,0,0,1,.665.665v1.219a3.518,3.518,0,0,1,1.523-1.412,5.283,5.283,0,0,1,2.327-.471h1.108a.575.575,0,0,1,.443.18.65.65,0,0,1,.166.457v1.135a.571.571,0,0,1-.609.609h-1.662a3.152,3.152,0,0,0-2.354.872,3.228,3.228,0,0,0-.859,2.368v8.143a.6.6,0,0,1-.194.457.667.667,0,0,1-.471.18Z" transform="translate(-1827.016 -177.429)" fill={color}/>
          <path id="Path_377" data-name="Path 377" d="M2115.147,197.544a7.03,7.03,0,0,1-3.517-.8,5.4,5.4,0,0,1-2.16-2.23,7.682,7.682,0,0,1-.831-3.254q-.027-.471-.027-1.2t.027-1.177a7.5,7.5,0,0,1,.845-3.268,5.515,5.515,0,0,1,2.174-2.216,7.983,7.983,0,0,1,6.979,0,5.515,5.515,0,0,1,2.174,2.216,7.506,7.506,0,0,1,.845,3.268q.027.443.027,1.177t-.027,1.2a7.679,7.679,0,0,1-.832,3.254,5.4,5.4,0,0,1-2.16,2.23,7.032,7.032,0,0,1-3.518.8m0-2.133a3.577,3.577,0,0,0,2.742-1.094,4.807,4.807,0,0,0,1.109-3.2q.026-.415.027-1.052t-.027-1.052a4.809,4.809,0,0,0-1.109-3.2,3.579,3.579,0,0,0-2.742-1.094,3.622,3.622,0,0,0-2.755,1.094,4.663,4.663,0,0,0-1.094,3.2q-.027.415-.027,1.052t.027,1.052a4.661,4.661,0,0,0,1.094,3.2,3.62,3.62,0,0,0,2.755,1.094" transform="translate(-1993.595 -172.629)" fill={color}/>
          <path id="Path_378" data-name="Path 378" d="M2412.883,202.529a.615.615,0,0,1-.637-.637V183.5a.615.615,0,0,1,.637-.637h1.274a.617.617,0,0,1,.637.637v1.219a5.306,5.306,0,0,1,1.772-1.523,5.691,5.691,0,0,1,2.77-.609,6.063,6.063,0,0,1,2.644.526,4.949,4.949,0,0,1,1.8,1.44,6.525,6.525,0,0,1,1.066,2.077,9.034,9.034,0,0,1,.387,2.493q.028.443.028.942t-.028.942a9.1,9.1,0,0,1-.387,2.479,6.332,6.332,0,0,1-1.066,2.077,5.145,5.145,0,0,1-1.8,1.44,5.926,5.926,0,0,1-2.644.54,5.593,5.593,0,0,1-2.7-.6,5.654,5.654,0,0,1-1.786-1.482v6.425a.645.645,0,0,1-.167.457.608.608,0,0,1-.471.18Zm5.872-7.229a3.643,3.643,0,0,0,2.257-.623,3.472,3.472,0,0,0,1.177-1.634,7.541,7.541,0,0,0,.415-2.174q.027-.8,0-1.606a7.544,7.544,0,0,0-.415-2.174,3.474,3.474,0,0,0-1.177-1.634,3.643,3.643,0,0,0-2.257-.623,3.56,3.56,0,0,0-2.2.637,3.782,3.782,0,0,0-1.247,1.62,6.142,6.142,0,0,0-.457,2.008q-.027.443-.028,1.052t.028,1.08a4.9,4.9,0,0,0,.457,1.911,3.814,3.814,0,0,0,1.288,1.551,3.641,3.641,0,0,0,2.161.609" transform="translate(-2280.669 -172.629)" fill={color}/>
          <path id="Path_379" data-name="Path 379" d="M2711.442,115.655a.615.615,0,0,1-.637-.637v-1.745a.654.654,0,0,1,.179-.485.622.622,0,0,1,.458-.18h1.773a.62.62,0,0,1,.457.18.655.655,0,0,1,.18.485v1.745a.616.616,0,0,1-.637.637Zm.249-4.6a.615.615,0,0,1-.637-.637V96.9a.615.615,0,0,1,.637-.637h1.3a.573.573,0,0,1,.442.18.647.647,0,0,1,.167.457v13.516a.645.645,0,0,1-.167.457.572.572,0,0,1-.442.18Z" transform="translate(-2562.943 -91.017)" fill={color}/>
        </g>
      </g>
    </svg>
  );
};

LogoSvg.propTypes = {
  color: PropTypes.string
};
