import { useEffect, createRef, useRef } from 'react';

import clsx from 'clsx';

import { useInView } from 'react-intersection-observer';

import { getDaysLeft } from './helpers';

import { useTranslation } from 'react-i18next';

import ProgressBar from '/src/components/ProgressBar/ProgressBar';
import BoxPoints from './BoxPoints/BoxPoints';

import * as styles from './ProjectsSlider.module.css';

const getBackgroundImage = (image = '/locales/images/portada_Foto2.jpeg') => {
  return {
    backgroundImage: `url(${image})`
  };
};

const ProjectsSlider = ({
  projects,
  onProjectClick,
  delaySlide = 5000,
}) => {
  const projectsViews = projects.map(() => {
    return useInView({
      threshold: 0.5
    });
  });

  const projectsRefs = projects.map(() => {
    return createRef();
  });

  const boxRef = useRef(0);
  const sliderIndexRef = useRef(0);

  useEffect(() => {
    if (projectsRefs.length && boxRef.current) {
      const intervalID = setInterval(() => {
        const isLast = sliderIndexRef.current >= projectsRefs.length;

        if (isLast) {
          sliderIndexRef.current = 0;
        }

        if (projectsRefs[sliderIndexRef.current].current) {
          const leftWidth = projectsRefs[sliderIndexRef.current].current.scrollWidth;

          boxRef.current.scrollBy({
            behavior: 'smooth',
            top: 0,
            left: isLast ? -boxRef.current.scrollWidth : leftWidth
          });

          sliderIndexRef.current++;
        }
      }, delaySlide);

      return () => clearInterval(intervalID);
    }
  }, [projectsRefs, boxRef]);

  const handleClickPoint = (i) => {
    projectsRefs[i].current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start'
    });
  };

  const { t } = useTranslation();

  return (
    <div className={styles.boxWrapper}>
      {
        projects.length > 1 && (
          <BoxPoints
            onClickPoint={(i) => handleClickPoint(i)}
            projects={projects}
            projectsViews={projectsViews}
          />
        )
      }
      <div ref={boxRef} className={styles.box}>
        {
          projects.length > 1
          ?
            projects.map((project, i) => (
              <div
                key={i}
                ref={projectsViews[i].ref}
                className={styles.boxInner}
              >
                <img
                  ref={projectsRefs[i]}
                  className={styles.blackBackground}
                  src={`${process.env.HOST_URL || ''}/locales/shared/images/${project.id}.jpg`}
                  alt="project"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/locales/images/default_project.jpeg';
                  }}
                />
                <button
                  onClick={() => onProjectClick(project)}
                  className={styles.boxHead}
                >
                  <div>
                    <span className={styles.boxTitle}>{project.title}</span>
                    <span className={styles.boxDesc}>{project.description}</span>
                  </div>
                  <div
                    className={styles.boxButton}
                    // onClick={() => onProjectClick(project)}
                  >
                      {getDaysLeft(project.finishes_at)} {t('project_days_left')}
                  </div>
                </button>
                <ProgressBar
                  current={project.total}
                  goal={project.goal}
                  options={{
                    showBubble: true
                  }}
                />
                <div className={clsx(styles.boxGoalWrapper, projects.length === 1 && styles.boxGoalNoMargin)}>
                  <span className={styles.boxGoalLabel}>{t('project_goal')}</span>
                  <span className={styles.boxGoalLabel}>{project.goal}€</span>
                </div>
              </div>
            ))
          :
            <p>No se encontraron proyectos.</p>
        }
      </div>
    </div>
  );
};

export default ProjectsSlider;
