import { useContext } from 'react';

import {
  Routes,
  Route,
  Navigate,
  Outlet
} from 'react-router-dom';

import PropTypes from 'prop-types';

// ========================================
// Public Routes
// ========================================
import { Entrance } from './public/login/entrance';
import { ColorsPage } from './public/colors/ColorsPage';
import { LoginPage } from './public/login/LoginPage';
import { RegisterPage } from './public/login/RegisterPage';
import { ResetPage } from './public/login/ResetPage';
import { PasswordPage } from './PasswordPage';

import { LandingPage } from './public/landing/LandingPage';
import LegalPage from './public/legal/LegalPage';
import PrivacyPage from './public/legal/PrivacyPage';

// ========================================
// Private Routes
// ========================================
import { PrivateRoute } from '../components/helpers/PrivateRoute';
import { Home } from './private/Home/Home';
import { Categories } from './private/Categories/Categories';
// import { Payment } from './private/Payment/Payment';
import { Panel } from './private/Panel/Panel';
import { Account } from './private/Panel/AccountPage';
import { History } from './private/Panel/HistoryPage';
import { SavedCrowdfundings } from './private/Panel/SavedCrowdfundingsPage';
import { Help } from './private/Panel/HelpPage';
import { Project } from './private/Project/Project';
import { UploadProject } from './private/UploadProject/UploadProject';
import { SentPage } from './private/UploadProject/SentPage';
import { Notifications } from './private/Notifications/Notifications';
import { DonationSuccess } from './private/Project/DonationSuccess/DonationSuccess';

// ========================================
// Error Routes - Redirects
// ========================================
import { Page404 } from './errors/Page404';
import { Page500 } from './errors/Page500';

import { AUTH_CONTEXT } from '../context/AuthProvider';

const RedirectIfLoggedIn = ({ shouldRedirect, children }) => {
  if (shouldRedirect) {
    return <Navigate replace to='/home' />;
  }

  return children || <Outlet />;
};

export const Routing = () => {
  const { loggedIn } = useContext(AUTH_CONTEXT);

  return (
    <Routes>
      <Route element={<RedirectIfLoggedIn shouldRedirect={loggedIn}/>}>
        <Route path="login" element={<Entrance />}>
          <Route path="" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />}/>
          <Route path="password-recovery" element={<ResetPage />}/>
          <Route path="password-change" element={<PasswordPage />}/>
        </Route>
      </Route>
      <Route path="legal_notice" element={<LegalPage></LegalPage>}></Route>
      <Route path="privacy_policy" element={<PrivacyPage></PrivacyPage>}></Route>
      <Route element={<PrivateRoute/>}>
        <Route path="home" element={<Home />} />
        <Route path="color" element={<ColorsPage />} />
        <Route path="categories" element={<Categories />} />
        <Route path="project/donation-success" element={<DonationSuccess />} />
        <Route path="project/:id" element={<Project />} />
        <Route path="upload-project" element={<UploadProject />} />
        {/* <Route path="payment" element={<Payment />} /> */}
        <Route path="panel" element={<Panel />}>
          <Route path="account" element={<Account/>} />
          <Route path="history" element={<History/>} />
          <Route path="savedCrowdfundings" element={<SavedCrowdfundings/>} />
          <Route path="help" element={<Help/>} />
        </Route>
        <Route path="notifications" element={<Notifications />} />
      </Route>
      <Route
        path="/"
        element={<LandingPage/>}
      />
      <Route path="404" element={<Page404/>}/>
      <Route path="500" element={<Page500/>}/>
      <Route path="sent-project" element={<SentPage/>}/>
      <Route
        path="*"
        element={<Page404 notFound={true}/>}
      />
    </Routes>
  );
};

RedirectIfLoggedIn.propTypes = {
  children: PropTypes.node,
  shouldRedirect: PropTypes.bool.isRequired
};
