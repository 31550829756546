import { useEffect } from 'react';
import checkIfImageExists from '/src/services/utils/image';

// This hook is used to check if the image exists and if it does, set the image url
const useBackgroundImage = (id, setImgUrl) => {
  useEffect(() => {
    if (id) {
      const newImage = `https://youdrop.org/locales/shared/images/${id}.jpg`;

      // console.log(newImage);

      checkIfImageExists(newImage, (err, exists) => {
        if (err || !exists) {
          setImgUrl('/locales/images/default_project.jpeg');
        } else {
          setImgUrl(newImage);
        }
      });
    }
  }, [id]);
};

export default useBackgroundImage;
