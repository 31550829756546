
const register = async ({ terms, email, password, license, colorsetID, language = 'es' }) => {
  try {
    const response = await fetch(`https://dev1.youdrop.org/${language}/users/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        terms,
        password,
        email,
        license,
        colorset_id: colorsetID
      })
    });

    // console.log(response);

    const result = await response.json();

    // if (!response.ok) {
    //   throw new Error('Response was not ok', result);
    // }

    return { ...result, statusNumber: response.status };
  } catch (error) {
    return error;
  }
};

const login = async ({ email, password }) => {
  try {
    const response = await fetch('https://dev1.youdrop.org/users/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        password,
        email
      })
    });

    // console.log(response);

    const result = await response.json();

    return { ...result, statusNumber: response.status };
  } catch (error) {
    return error;
  }
};

const recoverPassword = async ({ email, language = 'es' }) => {
  try {
    const response = await fetch(`https://dev1.youdrop.org/${language}/users/passwordrecovery`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email
      })
    });

    // console.log(response);

    const result = await response.json();

    return { ...result, statusNumber: response.status };
  } catch (error) {
    return error;
  }
};

const validateAuthToken = async ({ email, token }) => {
  try {
    const response = await fetch('https://dev1.youdrop.org/auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify({
        email
      })
    });

    const result = await response.json();

    return { ...result, statusNumber: response.status };
  } catch (error) {
    return error;
  }
};

export {
  register,
  login,
  recoverPassword,
  validateAuthToken
};
