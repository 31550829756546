import { useState } from 'react';

const useMember = ({ email, token, id, avatarKey } = { token: '', email: '', id: 0, avatarKey: 0 }) => {
  const [member, setMember] = useState({
    email,
    token,
    id,
    avatarKey
  });

  const loadMember = ({ email, token, id }) => {
    setMember({ email, token, id, avatarKey: 0 });
  };

  /*
    Private Routes gonna redirect when email or token is null
  */
  const unloadMember = () => {
    setMember({ email: null, token: null, id: null, avatarKey: 0 });
  };

  const checkLoggedIn = () => {
    return Boolean(member.email && member.token);
  };

  const renderAvatars = () => {
    if (member.avatarKey === 0) {
      setMember({ ...member, avatarKey: 1 });
      return;
    }

    setMember({ ...member, avatarKey: member.avatarKey + 1 });
  };

  return {
    member,
    loadMember,
    unloadMember,
    checkLoggedIn,
    renderAvatars
  };
};

export default useMember;
