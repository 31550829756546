import { useTranslation, Trans } from 'react-i18next';

import './legal.css';

import { Link } from 'react-router-dom';

import privacyIcon from '/src/assets/icons/privacidad.svg';

import Navbar from '/src/routes/public/landing/components/navbar';
import { Footer } from '/src/components/Footer';

const PrivacyPage = () => {
  const { t } = useTranslation();

  return (
    <div className='legal-container'>
      <Navbar forceStuck={true}></Navbar>
      <div className='legal-content'>
        <img src={privacyIcon} className='legal-icon' />
        <div className='legal-section'>
          <h1>{t('privacy_title_1')}</h1>
          <h2>{t('privacy_title_2')}</h2>
          <span>{t('privacy_text_2')}</span>
          <span>{t('privacy_text_2_b')}</span>
        </div>
        <div className='legal-section'>
          <h2>{t('privacy_title_3')}</h2>
          <span>{t('privacy_text_3')}</span>
          <span>{t('privacy_text_3b')}</span>
          <ul>
            <li>
              {t('privacy_text_3_li1')}
            </li>
            <li>
              {t('privacy_text_3_li2')}
            </li>
            <li>
              {t('privacy_text_3_li3')}
            </li>
            <li>
              {t('privacy_text_3_li4')}
            </li>
            <li>
              {t('privacy_text_3_li5')}
            </li>
          </ul>
          <h2>{t('privacy_title_4')}</h2>
          <span>{t('privacy_text_4')}</span>
          <ul>
            <li>{t('privacy_text_4_li1')}</li>
            <li>{t('privacy_text_4_li2')}</li>
            <li>{t('privacy_text_4_li3')}</li>
            <li>{t('privacy_text_4_li4')}</li>
          </ul>
          <span>{t('privacy_text_4_1')}</span>
          <span>{t('privacy_text_4_2')}</span>
        </div>
        <div className='legal-section'>
          <h2>{t('privacy_title_5')}</h2>
          <span>{t('privacy_text_5_1')}</span>
          <span>{t('privacy_text_5_2')}</span>
          <span>{t('privacy_text_5_3')}</span>
          <ul>
            <li>{t('privacy_text_5_3_li1')}</li>
            <li>{t('privacy_text_5_3_li2')}</li>
          </ul>
        </div>
        <div className='legal-section'>
          <h2>{t('privacy_title_6')}</h2>
          <span>{t('privacy_text_6_1')}</span>
          <span>{t('privacy_text_6_2')}</span>
        </div>
        <div className='legal-section'>
          <h2>{t('privacy_title_7')}</h2>
          <span>{t('privacy_text_7_1')}</span>
          <span>{t('privacy_text_7_2')}</span>
          <span>{t('privacy_text_7_3')}</span>
        </div>
        <div className='legal-section'>
          <h2>{t('privacy_title_8')}</h2>
          <span>{t('privacy_text_8_1')}</span>
          <ul>
            <li><Trans>{t('privacy_text_8_1_li1')}</Trans></li>
            <li><Trans>{t('privacy_text_8_1_li2')}</Trans></li>
            <li><Trans>{t('privacy_text_8_1_li3')}</Trans></li>
            <li><Trans>{t('privacy_text_8_1_li4')}</Trans></li>
            <li>
              <Trans>{t('privacy_text_8_1_li5')}</Trans>
              <ul>
                <li>{t('privacy_text_8_1_li5_li1')}</li>
                <li>{t('privacy_text_8_1_li5_li2')}</li>
                <li>{t('privacy_text_8_1_li5_li3')}</li>
                <li>{t('privacy_text_8_1_li5_li4')}</li>
              </ul>
            </li>
            <li>{t('privacy_text_8_li6')}</li>
          </ul>
          <span>{t('privacy_text_8_li6_1')}</span>
          <span>{t('privacy_text_8_li6_2')}</span>
          <span>{t('privacy_text_8_li6_3')}</span>
          <ul>
            <li>{t('privacy_text_8_li6_3_li1')}</li>
            <li>{t('privacy_text_8_li6_3_li2')}</li>
          </ul>
          <span>{t('privacy_text_8_li6_4')}</span>
          <span>{t('privacy_text_8_li6_5')}</span>
          <span>{t('privacy_text_8_li6_6')}</span>
        </div>
        <div className='legal-section'>
          <h2>{t('privacy_title_9')}</h2>
          <span>{t('privacy_text_9_1')}</span>
          <span>{t('privacy_text_9_2')}</span>
        </div>
        <div className='legal-section'>
          <h2>{t('privacy_title_10')}</h2>
          <span>{t('privacy_title_10_1')}</span>
          <span>{t('privacy_title_10_2')}</span>
          <span>{t('privacy_title_10_3')}</span>
        </div>
      </div>
      <Footer color={'blue'}></Footer>
    </div>
  );
};

export default PrivacyPage;
