import svgSent from '/src/assets/icons/ilustracio_projecte_enviat.svg';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Link, Navigate, useLocation } from 'react-router-dom';

import * as styles from './sent.module.css';

export const SentPage = (props) => {
  const { t } = useTranslation();

  const location = useLocation();
  const isSent = location.state?.sent || false;

  // If you try to access directly to this page, it will redirect to home
  if (!isSent) {
    return <Navigate to="/" />;
  }

  return (
    <div
      className={styles.container}
    >
      <div className={styles.innerWrapper}>
        <div className='youdrop-logo youdrop-logo--blue youdrop-logo--top youdrop-logo--long' alt="YouDrop Logo" />
        <img className={styles.image} type="image/svg+xml" src={svgSent}/>
        <h1 className={styles.title}>
          {
            t('sent_title')
          }
        </h1>
        <p className={styles.description}>
          {
            t('sent_text')
          }
        </p>
        <Link to="/">
          <button className={styles.button}>
            {
              t('sent_go_home')
            }
          </button>
        </Link>
      </div>
    </div>
  );
};

SentPage.propTypes = {
  sent: PropTypes.bool
};