
.boxPoints {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: 25;
  width: 100%;
  height: 15px;
  gap: 13px;
}
.boxPoint {
  background: #ffffff75 0% 0% no-repeat padding-box;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: 50%;
  bottom: 100px;
  z-index: 40;
  cursor: pointer;
  border: none;
}

.boxPoint.active {
  background: #FFFFFF 0% 0% no-repeat padding-box;
}