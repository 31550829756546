import PropTypes from 'prop-types';

const DropSave = ({ color, width, height }) => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 30">
    <g id="Grupo_402" data-name="Grupo 402" transform="translate(-346 -533)">
      <circle id="Elipse_5" data-name="Elipse 5" cx="15" cy="15" r="15" transform="translate(346 533)" fill={color || '#fff'}/>
      <path id="Trazado_188" data-name="Trazado 188" d="M5.307,15A5.378,5.378,0,0,1,1.29,13.326,5.609,5.609,0,0,1,.042,8.863C.437,5.028,4.733.585,4.916.4L5.3,0l.392.392c.183.183,4.484,4.524,4.879,8.472a5.629,5.629,0,0,1-1.259,4.469A5.372,5.372,0,0,1,5.307,15m0-13.42c-1.05,1.17-3.89,4.57-4.181,7.395A4.55,4.55,0,0,0,2.1,12.593a4.284,4.284,0,0,0,3.2,1.314A4.28,4.28,0,0,0,8.5,12.6a4.571,4.571,0,0,0,.981-3.626c-.291-2.9-3.121-6.242-4.173-7.393" transform="translate(356 540.5)" fill="#212529"/>
    </g>
  </svg>);
};

DropSave.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

DropSave.defaultProps = {
  width: 30,
  height: 30
};

export default DropSave;
