import { useState } from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import api from '/src/services/api';

import { useTranslation } from 'react-i18next';

function stringToNumber (str) {
  str = str.replaceAll('.', '');
  const num = Number(str);
  return num.toFixed(2);
}

function YourComponentWithPayPal ({
  crowdfundingid,
  amount,
  email,
  token,
  onSuccess,
  onError
}) {
  const [canreasing, setCanreasing] = useState(true);

  const { i18n } = useTranslation();
  const amountNum = stringToNumber(amount);

  return (
    <div>
      <PayPalButtons
        style={{
          layout: 'vertical',
        }}
        fundingSource={'paypal'}
        forceReRender={[amountNum]}
        createOrder={async (data, actions) => {
          // Call your server to set up the transaction
          let responseID = 0;

          try {
            const result = await api.donatePaypal({
              crowdfundingid,
              amount: amountNum,
              email,
              token
            });

            // console.log('result ', result);
            responseID = result.response.id;
          } catch (error) {
            console.log('error al donar ', error);
          }
          return responseID;
          // return actions.order.create({
          //   purchase_units: [
          //     {
          //       amount: {
          //         value: '10.00' // Set the payment amount here
          //       }
          //     }
          //   ]
          // });
        }}
        onApprove={async (data, actions) => {
          try {
            const result = await api.processPaypalPayment({
              email,
              token,
              locale: i18n.resolvedLanguage,
              crowdfundingid,
              paymentintent: data.orderID,
              canreasing
            });

            if (result.status === 'success') {
              onSuccess(data, amountNum);
            }
          } catch (error) {
            console.log('error al procesar el pago ', error);
            onError(error);
          }
        }}
        onError={(err) => onError(err)}
      />
    </div>
  );
}

export default YourComponentWithPayPal;
