import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import PropTypes from 'prop-types';

import * as styles from './ProjectCard.module.css';

import { getDaysLeft } from '../../routes/private/Home/components/helpers';

import ProgressBar from '/src/components/ProgressBar/ProgressBar';
import useBackgroundImage from '/src/services/hooks/useBackgroundImage';

import DropSave from '../svg/DropSave';

const ProjectCard = ({
  title,
  description,
  id,
  goal,
  current,
  endDate,
  onClick,
  saved,
  onSave,
  disabled,
  saveColor,
  tag
}) => {
  const [imgUrl, setImgUrl] = useState('/locales/images/default_project.jpeg');

  const { t } = useTranslation();

  const headerTag = {
    'data-before': tag || 'Ninguna'
  };

  useBackgroundImage(id, setImgUrl);

  return (
    <div className={clsx(styles.cardBox, disabled && styles.cardBoxDisabled)}>
      <header
        {...headerTag}
        style={{ backgroundImage: `url(${imgUrl})` }}
        className={styles.cardHeaderImage}
      >
        <button
          className={clsx(styles.saveButton)}
          type='button'
          onClick={() => onSave(id)}
          disabled={disabled}
        >
          <DropSave color={saved && saveColor ? saveColor : null} />
        </button>
      </header>
      <button
        onClick={() => onClick(id)}
        type='button'
        className={styles.innerCard}
        disabled={disabled}
      >
        <div className={styles.cardHeadWrapper}>
          <span className={styles.cardTitle}>{title}</span>
          <span className={styles.cardDesc}>{description}</span>
        </div>
        <ProgressBar
          current={current}
          goal={goal}
        />
        <div className={styles.boxGoalWrapper}>
          <span className={styles.boxGoalLabel}>{t('project_goal')}</span>
          <span className={styles.boxGoalLabel}>{goal}€</span>
        </div>
        <span className={styles.daysLeftLabel}>
          {getDaysLeft(endDate)} {t('project_days_left')}
        </span>
      </button>
    </div>
  );
};

ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  goal: PropTypes.number.isRequired,
  current: PropTypes.number,
  endDate: PropTypes.number,
  onClick: PropTypes.func,
  onSave: PropTypes.func,
  tag: PropTypes.string,
  saved: PropTypes.bool,
  saveColor: PropTypes.string,
  disabled: PropTypes.bool
};

ProjectCard.defaultProps = {
  onSave: () => {},
  onClick: () => {},
  saved: false,
  saveColor: '#fff'
};

export default ProjectCard;
