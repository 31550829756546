import { useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { COLOR_CONTEXT } from '/src/context/ColorProvider';

import dropCharImage from '/src/assets/images/drop_char.png';
import './styles/entrance-screen.css';

export const Entrance = () => {
  const { selectedColor } = useContext(COLOR_CONTEXT);
  const navigate = useNavigate();

  return (
    <div
      className='entrance-container'
      style={{ backgroundColor: selectedColor?.color2 }}
    >
      <div className='youdrop-logo youdrop-logo--white youdrop-logo--top youdrop-logo--long' alt="YouDrop Logo" />
      <div className='entrance-left-col'>
        <img className='youdrop-char' src={dropCharImage} alt="YouDrop Character" />
        <h1 className='entrance-title'>Projectes que et necessiten</h1>
        <span className='entrance-description'>Volem aportar la nostra gota d’aigua per a un món millor. Apostem per construir i fer créixer junts projectes viables i sostenibles.</span>
      </div>
      <Outlet/>
      {/* <button onClick={() => navigate('/color')} type='button' className='colors-btn'>
        <span className='colors-btn__text'>&#x2190; Torna a escollir 🎨</span>
      </button> */}
    </div>
  );
};
