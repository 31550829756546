
/**
 * Converts a Unix timestamp to a formatted date string in the format 'dd/mm/yyyy'.
 *
 * @param {number} unixTimestamp - The Unix timestamp to be converted.
 * @returns {string} A string in the format 'dd/mm/yyyy' representing the converted date.
 */
const unixToFormattedDate = (unixDate) => {
  if (!unixDate) return null;

  const dateUpdated = new Date(unixDate * 1000);

  const dateMap = {
    day: dateUpdated.getDate(),
    month: dateUpdated.getMonth() + 1,
    year: dateUpdated.getFullYear()
  };

  const { day, month, year } = dateMap;

  return `${day}/${month}/${year}`;
};

export {
  unixToFormattedDate
};
