import { useContext, useState, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button } from '/src/components/Button';
import { useShow } from '/src/services/hooks/useShow';
import { useNavigate } from 'react-router-dom';
import { AUTH_CONTEXT } from '/src/context/AuthProvider';
import Modal, { ModalTitle, ModalBody } from '/src/components/Modal';
import clsx from 'clsx';
import closeIconSVG from '/src/assets/icons/cancel.svg';
import zipIconPNG from '/src/assets/icons/zip.png';
import useForm from '../Panel/hooks/useForm';
import file2Base64 from '/src/services/utils/base64';
import api from '/src/services/api';
import './uploadproject.css';

export const UploadProject = () => {
  const { member } = useContext(AUTH_CONTEXT);

  const myFormRef = useRef(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { token, email } = member;

  const [draggingImage, setDraggingImage] = useState(false);
  const [draggingVideo, setDraggingVideo] = useState(false);
  const [draggingZip, setDraggingZip] = useState(false);
  const [fetchError, setFetchError] = useState('');
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [zip, setZip] = useState(null);
  const [imageError, setImageError] = useState('');
  const [videoError, setVideoError] = useState('');
  const [zipError, setZipError] = useState('');
  const [loading, setLoading] = useState(false);

  // console.log(zip);

  const {
    form,
    changeFormValue,
    checkValidation,
    resetAllValidations
  } = useForm({
    title: {
      type: 'name'
    },
    description: {
      type: 'textarea'
    },
    contact: {
      type: 'textarea'
    },
    target: {
      type: 'currency'
    }
  });

  const modalConfirmation = useShow(false);

  const handleZipChange = async (e) => {
    e.preventDefault();

    const fileSelected = e.target.files[0];

    setDraggingZip(false);

    if (fileSelected && fileSelected.type !== 'application/x-zip-compressed' && fileSelected.type !== 'application/zip') {
      setZipError('Por favor, selecciona un archivo ZIP válido');
      setZip(null);
      return;
    }

    if (fileSelected && fileSelected.size > 20971520) {
      setZipError('Por favor, selecciona un archivo ZIP de menos de 20MB');
      setZip(null);
      return;
    }

    try {
      // setZip(fileSelected);

      const reader = new FileReader();
      reader.readAsArrayBuffer(fileSelected);
      reader.onload = (e) => {
        // setZip(reader.result.split(',').pop());
        setZip(reader.result);
      };

      // const base64 = await file2Base64(fileSelected);
      // setZip(base64);
      setZipError('');
    } catch (error) {
      setZip(null);
      setZipError('Error al cargar el archivo ZIP');
    }
  };

  const handleVideoChange = async (e) => {
    e.preventDefault();

    const fileSelected = e.target.files[0];

    setDraggingVideo(false);

    if (fileSelected && fileSelected.type !== 'video/mp4' && fileSelected.type !== 'video/quicktime') {
      setVideoError('Por favor, selecciona un vídeo en formato MP4 o MOV');
      setVideo(null);
      return;
    }

    if (fileSelected && fileSelected.size > 10485760) {
      setVideoError('Por favor, selecciona un vídeo de menos de 10MB');
      setVideo(null);
      return;
    }

    try {
      const base64 = await file2Base64(fileSelected);

      setVideo(base64);
      setVideoError('');
    } catch (error) {
      setVideo(null);
      setVideoError('Error al cargar el vídeo');
    }
  };

  const handleImageChange = async (e) => {
    e.preventDefault();

    const fileSelected = e.target.files[0];

    setDraggingImage(false);

    if (fileSelected && fileSelected.type !== 'image/png' && fileSelected.type !== 'image/jpeg') {
      setImageError('Por favor, selecciona una imagen en formato PNG o JPG');
      setImage(null);
      return;
    }

    if (fileSelected && fileSelected.size > 2097152) {
      setImageError('Por favor, selecciona una imagen de menos de 2MB');
      setImage(null);
      return;
    }

    try {
      const base64 = await file2Base64(fileSelected);

      setImage(base64);
      setImageError('');
    } catch (error) {
      setImage(null);
      setImageError('Error al cargar la imagen');
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    if (!checkValidation()) {
      myFormRef?.current?.reportValidity();
      return;
    }

    modalConfirmation.handleShow();
  };

  const handleUploadProject = async (form, member, handleClose, setLoading) => {
    handleClose();
    setLoading(true);

    // console.log('handleUploadProject', form, member, handleClose, setLoading);

    try {
      const response = await api.uploadNewProject({
        token: member.token,
        email: member.email,
        goal: parseInt(form?.target?.replace('.', '')) || 0,
        image,
        video,
        zip,
        // from today to 30 days later UNIX timestamp
        // finishes_at: Math.floor(Date.now() / 1000) + 2592000,
        formValues: form,
        locale: i18n.language
      });

      if (response.status === 'success') {
        navigate('/sent-project', {
          state: {
            sent: true
          }
        });
      }

      // console.log('response', response);

      setFetchError('');
    } catch (error) {
      // console.log('error', error);
      if (error.status === 500) {
        navigate('/500', {
          state: {
            isError: true
          }
        });
      } else {
        resetAllValidations();
        setFetchError(error.message);
      }
    };

    resetAllValidations();
    setLoading(false);
  };

  if (loading || (!token && !email)) {
    return (
      <div className='loading-container'>
        <div className='lds-dual-ring'/>
      </div>
    );
  }

  return (
    <div className='newproject__container'>
      <Modal
        visible={modalConfirmation.show}
      >
        <ModalTitle
          onClose={() => modalConfirmation.handleClose()}
        >
          <h1>{
            t('uploadProject.modalTitle')
          }</h1>
        </ModalTitle>
        <ModalBody>
          <p>
            {
              t('uploadProject.modalText')
            }
          </p>
          <div className='modal-buttons'>
            <Button
              className='form-button form-button--secondary'
              onClick={() => modalConfirmation.handleClose()}
            >
              {
                t('uploadProject.modalButtonCancel')
              }
            </Button>
            <Button
              onClick={() => handleUploadProject(form, member, modalConfirmation.handleClose, setLoading)}
            >
              {
                t('uploadProject.modalButtonConfirm')
              }
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <h1 className='newproject__title'>
        {
          t('uploadProject.title')
        }
      </h1>
      {
        fetchError && (
          <span className='font-color-error mb-35'>
            {
              fetchError
            }
          </span>
        )
      }
      <form
        id='upload-project'
        onSubmit={(e) => handleSubmitForm(e)}
        ref={myFormRef}
        acceptCharset='UTF-8'
        className='newproject__form'
      >

        <div className='form-group'>
          <div className='form-info'>
            <h2 className='form-info__title'>
              {
                t('uploadProject.formTitle')
              }
            </h2>
            <p className='form-info__description'>
              {
                t('uploadProject.formTitleDescription')
              }
            </p>
          </div>
          <div className='form-card'>
            <label className='form__label'>
              <span className='form__label__text'>
                {
                  t('uploadProject.title_')
                }
              </span>
              <input
                onChange={(e) => changeFormValue({ value: e.target.value, key: 'title' })}
                value={form.title}
                // onBlur={(event) => {
                //   // setError('');
                //   resetError('title', event);
                // }}
                onFocus={(e) => setFetchError('')}
                type='name'
                id='title'
                form='upload-project'
                minLength={3}
                maxLength={120}
                className='form-control'
                // className={clsx('form-control', !validations.title.isValid && 'is-invalid')}
                required={true}
                disabled={loading}
              />
              <p className='char-count'>{form?.title?.length}/120</p>
              {
                !form.validations.title.isValid && (
                  <span className='form-error'>
                    {form.validations.title.message}
                  </span>
                )
              }
            </label>
          </div>
        </div>
        <div className='form-group'>
          <div className='form-info'>
            <h2 className='form-info__title'>
              {
                t('uploadProject.formProjectDescTitle')
              }
            </h2>
            <p className='form-info__description'>
              {
                t('uploadProject.formProjectDescDescription')
              }
            </p>
          </div>
          <div className='form-card'>
            <label className='form__label'>
              <span className='form__label__text'>
                {
                  t('uploadProject.description')
                }
              </span>
              <textarea
                onChange={(e) => (
                  changeFormValue({ value: e.target.value, key: 'description' })
                )}
                onFocus={(e) => setFetchError('')}
                value={form.description}
                // onBlur={(event) => {
                //   // setError('');
                //   resetError('description', event);
                // }}
                type='textarea'
                id='description'
                form='upload-project'
                minLength={30}
                maxLength={3000}
                className='form-control'
                // className={clsx('form-control', !validations.description.isValid && 'is-invalid')}
                required={true}
                disabled={loading}
              />
              <p className='char-count'>{form?.description?.length}/3000</p>
              {
                !form.validations.description.isValid && (
                  <span className='form-error'>
                    {form.validations.description.message}
                  </span>
                )
              }
            </label>
          </div>
        </div>
        <div className='form-group'>
          <div className='form-info'>
            <h2 className='form-info__title'>
              {
                t('uploadProject.formContact')
              }
            </h2>
            <p className='form-info__description'>
              {
                t('uploadProject.formContactDescription')
              }
            </p>
          </div>
          <div className='form-card'>
            <label className='form__label'>
              <span className='form__label__text'>
                {
                  t('uploadProject.contact')
                }
              </span>
              <textarea
                onChange={(e) => (
                  changeFormValue({ value: e.target.value, key: 'contact' })
                )}
                onFocus={(e) => setFetchError('')}
                value={form.contact}
                // onBlur={(event) => {
                //   // setError('');
                //   resetError('description', event);
                // }}
                type='textarea'
                id='contact'
                form='upload-project'
                minLength={30}
                maxLength={1500}
                className='form-control'
                // className={clsx('form-control', !validations.description.isValid && 'is-invalid')}
                required={true}
                disabled={loading}
              />
              <p className='char-count'>{form?.contact?.length}/1500</p>
              {
                !form.validations.description.isValid && (
                  <span className='form-error'>
                    {form.validations.description.message}
                  </span>
                )
              }
            </label>
          </div>
        </div>
        <div className='form-group'>
          <div className='form-info'>
            <h2 className='form-info__title'>
              {
                t('uploadProject.formTarget')
              }
            </h2>
            <p className='form-info__description'>
              {
                t('uploadProject.formTargetDescription')
              }
            </p>
          </div>
          <div className='form-card'>
            <label className='form__label'>
              <span className='form__label__text'>
                {
                  t('uploadProject.target')
                }
              </span>
              <input
                onChange={(e) => changeFormValue({ value: e.target.value, key: 'target' })}
                value={form.target}
                onFocus={(e) => setFetchError('')}
                type='currency'
                min={1}
                // onBlur={(event) => {
                //   // setError('');
                //   resetError('target', event);
                // }}
                placeholder='3000'
                form='upload-project'
                id='target'
                minLength={3}
                className='form-control form-control--currency'
                required={true}
                disabled={loading}
              />
              {
                !form.validations.target.isValid && (
                  <span className='form-error'>
                    {form.validations.target.message}
                  </span>
                )
              }
            </label>
          </div>
        </div>
        <div className='form-group'>
          <div className='form-info'>
            <h2 className='form-info__title'>
              {
                t('uploadProject.formImageTitle')
              }
            </h2>
            <p className='form-info__description'>
              {
                t('uploadProject.formImageDescription')
              }
            </p>
          </div>
          <div className='form-card form-card--file-upload'>
            <div className={clsx('file-upload__droparea', draggingImage && 'file-upload__droparea--dragging')}>
              {t('uploadProject.formImageDrag')}
              <label>
                <input
                  id='image-file'
                  type="file"
                  accept="image/jpeg, image/png"
                  onFocus={(e) => setFetchError('')}
                  className='file-upload__fileinput'
                  onChange={(e) => handleImageChange(e)}
                  onDragOver={(e) => {
                    e.stopPropagation();
                    if (!e.dataTransfer?.items[0]?.type?.includes('png') && !e.dataTransfer?.items[0]?.type?.includes('jpeg') && !e.dataTransfer?.items[0]?.type?.includes('jpg')) {
                      setImageError(t('uploadProject.formImageDragError'));
                      return;
                    }
                    setDraggingImage(true);
                  }}
                  onDragLeave={(e) => {
                    e.stopPropagation();
                    setDraggingImage(false);
                  }}
                >
                </input>
                <a>
                  {
                    t('uploadProject.formImageDragButton')
                  }
                </a>
              </label>
              <span>.jpg | .png | {t('uploadProject.formImageDragSize')} 2mb</span>
              {
                imageError && (
                  <span className='form-error'>
                    {imageError}
                  </span>
                )
              }
            </div>
            {
              image && (
                <div className='file-upload__preview'>
                  <button
                    type='button'
                    className='file-upload__preview__btn-close'
                  >
                      <img
                        src={closeIconSVG} alt='close'
                        onClick={() => setImage(null)}
                      />
                  </button>
                  <img src={image} alt='uploaded image preview' />
                </div>
              )
            }
          </div>
        </div>
        <div className='form-group'>
          <div className='form-info'>
            <h2 className='form-info__title'>
              <Trans i18nKey={'uploadProject.formVideoTitle'} t={t}>
                Vídeo presentación<br/>(Opcional)
              </Trans>
            </h2>
            <p className='form-info__description'>
              {
                t('uploadProject.formVideoDescription')
              }
            </p>
          </div>
          <div className='form-card form-card--file-upload'>
            <div className={clsx('file-upload__droparea', draggingVideo && 'file-upload__droparea--dragging')}>
              {t('uploadProject.formImageDrag')}
              <label>
                <input
                  id='video-file'
                  type="file"
                  accept="video/mp4, video/quicktime"
                  onFocus={(e) => setFetchError('')}
                  className='file-upload__fileinput'
                  required={false}
                  onChange={(e) => handleVideoChange(e)}
                  onDragOver={(e) => {
                    e.stopPropagation();
                    if (!e.target?.files[0]?.type.includes('mp4') && !e.target?.files[0]?.type.includes('mov')) {
                      return;
                    }
                    setDraggingVideo(true);
                  }}
                  onDragLeave={(e) => {
                    e.stopPropagation();
                    setDraggingVideo(false);
                  }}
                >
                </input>
                <a>
                  {
                    t('uploadProject.formImageDragButton')
                  }
                </a>
              </label>
              <span>.mp4 | .mov | {t('uploadProject.formImageDragSize')} 10mb</span>
              {
                videoError && (
                  <span className='form-error'>
                    {videoError}
                  </span>
                )
              }
            </div>
            {
              video && (
                <div className='file-upload__preview'>
                  <video>
                    <source src={video} type="video/mp4" alt="uploaded video preview"/>
                  </video>
                  <button
                    type='button'
                    className='file-upload__preview__btn-close'
                  >
                      <img
                        src={closeIconSVG} alt='close'
                        onClick={() => setVideo(null)}
                      />
                  </button>
                </div>
              )
            }
          </div>
          <div className='form-card__footnote'>
            {
              t('uploadProject.formVideoFootnote')
            }
          </div>
        </div>
        <div className='form-group'>
          <div className='form-info'>
            <h2 className='form-info__title'>
              <Trans i18nKey={'uploadProject.formZipTitle'} t={t}>
                Material gráfico y audiovisual<br/>(Opcional)
              </Trans>
            </h2>
            <p className='form-info__description'>
              {
                t('uploadProject.formZipDescription')
              }
            </p>
          </div>
          <div className='form-card form-card--file-upload'>
            <div className={clsx('file-upload__droparea', draggingZip && 'file-upload__droparea--dragging')}>
              {t('uploadProject.formImageDrag')}
              <label>
                <input
                  id='zip-file'
                  type="file"
                  accept=".zip"
                  onFocus={(e) => setFetchError('')}
                  className='file-upload__fileinput'
                  required={false}
                  onChange={(e) => handleZipChange(e)}
                  onDragOver={(e) => {
                    e.stopPropagation();
                    if (!e.target?.files[0]?.type.includes('zip')) {
                      return;
                    }
                    setDraggingZip(true);
                  }}
                  onDragLeave={(e) => {
                    e.stopPropagation();
                    setDraggingZip(false);
                  }}
                >
                </input>
                <a>
                  {
                    t('uploadProject.formImageDragButton')
                  }
                </a>
              </label>
              <span>.zip | {t('uploadProject.formImageDragSize')} 20mb</span>
              {
                zipError && (
                  <span className='form-error'>
                    {zipError}
                  </span>
                )
              }
            </div>
            {
              zip && (
                <div className='file-upload__preview' style={{ width: 80 + 'px', height: 80 + 'px' }}>
                  <button
                    type='button'
                    className='file-upload__preview__btn-close'
                  >
                      <img
                        src={closeIconSVG} alt='close'
                        onClick={() => setZip(null)}
                      />
                  </button>
                  <img src={zipIconPNG} alt='preview'/>
                </div>
              )
            }
          </div>
        </div>
        <Button
          className='form-button'
          type='submit'
          form='upload-project'
          disabled={!checkValidation() || loading}
        >
          {
            t('uploadProject.formButton')
          }
        </Button>
      </form>
    </div>
  );
};
