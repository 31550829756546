
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import * as styles from './Notifications.module.css';

import { COLOR_CONTEXT } from '/src/context/ColorProvider';
import { AUTH_CONTEXT } from '/src/context/AuthProvider';

import { NavbarContext } from '/src/components/Navbar';
import { useQuery } from 'react-query';

import { Navigate, useNavigate } from 'react-router-dom';

//import api from '/src/services/api';

import NoProjectsSVG from '/src/assets/icons/ilustracio_no_notificacions.svg';

export const Notifications = () => {

  const { member } = useContext(AUTH_CONTEXT);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const { token, email } = member;

  // if (isLoading || (!token && !email)) {
  //   return (
  //     <div className='loading-container'>
  //       <div className='lds-dual-ring'/>
  //     </div>
  //   );
  // }

  // if (isError) {
  //   if (failureCount > 3) {
  //     return <Navigate
  //       to='/500'
  //       state={{ isError: true }}
  //     />;
  //   }
  //   return null;
  // };


  return (
    <div className='container-app'>
      <div className={clsx('mb-40', styles.notificationsHeader)}>
        <h1 className={styles.notificationsTitle}>
          {
            t('notifications_title')
          }
        </h1>
        <div className={styles.markAsRead}>
          <button>
            {
              t('notifications_mark_read')
            }
          </button>
        </div>
      </div>
      <div className={clsx('mb-40', styles.notificationsMenu)}>
        <button className={styles.active}>
          {
            t('notifications_unread')
          }
        </button>
        <button className=''>
          {
            t('notifications_read')
          }
        </button>
      </div>
      {/* <div className={styles.noNotifications}>
        <img src={NoProjectsSVG} />
        <h2>
          {
            t('notifications_no_unread')
          }
        </h2>
      </div> */}

      <div className={styles.notificationsContainer}>

        <div className={styles.notificationRow}>
          <div className={styles.dateColumn}>
            29 Juny 2022
          </div>
          <div className={styles.card}>
            <div className={styles.thumbnail}>

            </div>
            <div className={styles.content}>
              <h1 className={styles.title}>
                Lorem ipsum dolor
              </h1>
              <p className={styles.date}>
                29 Juny 2022
              </p>
              <p className={styles.text}>
                Lorem ipsum dolor sit met consectetur aliqua alea jacta est lorem ipsum dolor consectetur alea jacta est cliqua lorem ipsum.
              </p>
            </div>
            <div className={styles.time}>
              09:00 AM
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};