
import { useContext, Fragment, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';

import { COLOR_CONTEXT } from '/src/context/ColorProvider';
import { AUTH_CONTEXT } from '/src/context/AuthProvider';

import { ColorPicker } from '/src/components/ColorPicker';

import logo from '/src/assets/logo.png';

import './colors-screen.css';

import api from '/src/services/api';

export const ColorsPage = () => {
  const {
    colors,
    selectedColor,
    handleSelectPallete
  } = useContext(COLOR_CONTEXT);

  const { member } = useContext(AUTH_CONTEXT);

  const { t } = useTranslation();

  const [isContinue, setIsContinue] = useState(false);

  const navigate = useNavigate();

  const handleContinue = async (color) => {
    if (isContinue) return;

    setIsContinue(true);

    if (selectedColor) {
      localStorage.setItem('color_id', selectedColor.id.toString());
    }
    try {
      await api.saveMemberColor({
        email: member.email,
        token: member.token,
        color: color.id
      });

      setTimeout(() => {
        setIsContinue(false);
        navigate('/home');
      }, 500);
    } catch (error) {
      console.log('Error saving color', error);
      setIsContinue(false);
    }
  };

  return (
    <div
      style={{ backgroundColor: selectedColor?.color2 || '#fff' }}
      className='container'
    >
      {
        !selectedColor && (
          <Fragment>
          <img className='youdrop-logo' src={logo} alt="YouDrop Logo" />
          <span className='colors-title'>
            {
              t('colors_description')
            }
          </span>
        </Fragment>)
      }
      {
        selectedColor && (
          <Fragment>
            <div className='youdrop-logo youdrop-logo--white youdrop-logo--top' alt="YouDrop Logo" />
            <div className='color-image__container'>
              <img className='color-image' src={selectedColor.image} alt="Color Visual Image" />
            </div>
            <span className='colors-title colors-title--white'>
              {selectedColor.name}
            </span>
            <span className='colors-description'>
              {selectedColor.description}
            </span>
          </Fragment>
        )
      }
      <div className='colors'>
      {
        colors.length > 0 && colors.map((color, i) => (
          <ColorPicker
            key={i}
            id={color.id}
            isSelected={color.id === selectedColor?.id}
            hasBorder={selectedColor !== null}
            color={color.color2}
            handleClick={(id) => handleSelectPallete(id, true)}
          />
        ))
      }
      </div>
      {
        selectedColor && (
          <button
            onClick={() => handleContinue(selectedColor)}
            className={clsx('colors-button', isContinue && 'colors-button--active')}
            style={{ color: selectedColor?.color2 || 'black' }}
          >
          {
            t('colors_continue')
          }
        </button>
        )
      }
    </div>
  );
};
