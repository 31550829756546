import PropTypes from 'prop-types';
import clsx from 'clsx';

export const Button = ({ disabled = false, loading, children, className, ...props }) => {
  return (
    <button
      className={clsx(className, loading && 'form-button--loading', disabled && 'form-button--disabled')}
      { ...props }
      disabled={disabled || loading}
    >
    {
      loading
        ? (
            <div className='small-circles-wrapper'>
              <span className='small-circle small-circle--animation1'></span>
              <span className='small-circle small-circle--animation2'></span>
              <span className='small-circle small-circle--animation3'></span>
            </div>
          )
        : children
    }
  </button>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
  loading: PropTypes.bool,
  className: PropTypes.string
};
