
import { useContext, useState, Fragment, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { useParams, Navigate, useNavigate } from 'react-router-dom';

import * as styles from './Project.module.css';

import clsx from 'clsx';

import { AUTH_CONTEXT } from '/src/context/AuthProvider';
import { COLOR_CONTEXT } from '/src/context/ColorProvider';
import { NavbarContext } from '/src/components/Navbar';

import { getDaysLeft } from '../Home/components/helpers';

// import { NavbarContext } from '/src/components/Navbar';
import { useQuery } from 'react-query';

import DropSave from '/src/components/svg/DropSave';
import CardsHeaderMenu from '/src/components/CardsHeaderMenu/CardsHeaderMenu';
import CardsWrapper from '/src/components/CardsWrapper/CardsWrapper';
import ProjectCard from '/src/components/ProjectCard/ProjectCard';

import { MediaQuery } from '/src/components/helpers/MediaQuery';

import useBackgroundImage from '/src/services/hooks/useBackgroundImage';

import DonationBox, {
  DonationHeader,
  DonationBody,
  DonationInput
} from './components/DonationBox';

import PayPal from '/src/components/Paypal/paypal';

import AuthorBox, { AuthorHeader } from './components/AuthorBox';

import useForm from '../Panel/hooks/useForm';
import { useShow } from '/src/services/hooks/useShow';

import api from '/src/services/api';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';

import Modal, { ModalTitle, ModalBody } from '/src/components/Modal';

const findCategoryName = (categories, id, i18n) => {
  return categories?.find(category => id === category.id)?.name[i18n.resolvedLanguage || 'ca'] || 'Ninguna';
};

const addZeroToTag = (tag) => {
  if (tag < 10) {
    return `0${tag}`;
  }
  return tag;
};

const Project = (props) => {
  const { selectedColor } = useContext(COLOR_CONTEXT);
  const { member } = useContext(AUTH_CONTEXT);
  const { categories } = useContext(NavbarContext);

  const { t, i18n } = useTranslation();

  const [imgUrl, setImgUrl] = useState('');
  const [saving, setSaving] = useState(false);

  const { form, changeFormValue } = useForm({
    donation: {
      type: 'currency'
    }
  });

  const modalDonation = useShow(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const { token, email } = member;

  const donationButtonRef = useRef();

  const {
    data,
    isLoading,
    isError,
    failureCount,
    refetch
  } = useQuery(
    ['project_info', {
      token,
      email,
      id
    }],
    () => api.getProjectInfo({
      token,
      email,
      id
    }),
    {
      refetchOnWindowFocus: true,
      // this is tricky but it gonna make the query only be executed when we have token and email
      enabled: (!!token && !!email)
    });

  if (data && data.crowdfunding) {
    // console.log(data.crowdfunding);
  }

  useBackgroundImage(id, setImgUrl);

  if (isLoading || (!token && !email)) {
    return (
      <div className='loading-container'>
        <div className='lds-dual-ring'/>
      </div>
    );
  }

  if (isError) {
    if (failureCount > 3) {
      return <Navigate
        to='/500'
        state={{ isError: true }}
      />;
    }
    return null;
  };

  const handleSaveProject = async (id, saved) => {
    setSaving(true);

    try {
      if (saved) {
        await api.unsaveProject({ token, email, id });
      } else {
        await api.saveProject({ token, email, id });
      }
    } catch (error) {
      console.error(error);
    } finally {
      refetch({
        token,
        email,
        id
      });
      setSaving(false);
    }
  };

  const handleCardClick = (id) => {
    navigate(`/project/${id}`);
  };

  return (
    <Fragment>
      <div className={clsx('container-app', styles.projectContainer)}>
        <Modal
          visible={modalDonation.show}
        >
          <ModalTitle
            onClose={modalDonation.handleClose}
          >
            <h2>
            {
              t('project.donation')
            }
            </h2>
          </ModalTitle>
          <ModalBody>
            <span style={{ marginBottom: '15px' }}>
              <Trans
                i18nKey='project.donationText'
                donation={form.donation}
                t={t}
              >
                Se va a realizar un pago de ({{ donation: form.donation }}€)
              </Trans>
            </span>
            <PayPal
              onSuccess={(result, amount) => {
                navigate('/project/donation-success', {
                  state: {
                    result,
                    donation: true,
                    project: {
                      id,
                      title: data?.crowdfunding?.title,
                      donation: amount
                    }
                  }
                });
              }}
              onError={(error) => {
                console.log('Error al realizar el pago', error);
              }}
              crowdfundingid={id}
              amount={form.donation}
              email={email}
              token={token}
            />
          </ModalBody>
        </Modal>
        <MediaQuery query='(max-width: 62rem)'>
          <div
            className={clsx(styles.headerBackground)}
            // style={{ backgroundImage: `url(${imgUrl})` }}
          >
            <button
              className={clsx(styles.saveButton)}
              type='button'
              onClick={() => handleSaveProject(id, data?.crowdfunding?.saved)}
              disabled={saving}
            >
              <DropSave
                width={50}
                height={50}
                color={data?.crowdfunding?.saved && selectedColor.color1 ? selectedColor.color1 : null}
              />
            </button>
          </div>
          <span className={styles.tags}>
          {
            data?.categories?.map((category, i) => (
              findCategoryName(categories, category.categoryId, i18n) || '')
            ).join(' | ')
          }
          </span>
        </MediaQuery>
        <div className={styles.headerDesktop}>
          <div>
            <h1 className={styles.title}>
              {
                data?.crowdfunding?.title
              }
            </h1>
            <span className={clsx(styles.text, styles.textSummary)}>
              {
                data?.crowdfunding?.summary
              }
            </span>
          </div>
          <MediaQuery query='(min-width: 62rem)'>
            <button
              className={clsx(styles.saveButtonDesktop, saving && styles.saving)}
              type='button'
              disabled={saving}
              onClick={() => handleSaveProject(id, data?.crowdfunding?.saved)}
            >
              {
                (data?.crowdfunding?.saved && t('project_save')) || t('project_unsave')
              }
            </button>
          </MediaQuery>
        </div>
        <div className={styles.gridContainer}>
          <div className={styles.col1}>
            <MediaQuery query='(min-width: 62rem)'>
              <img
                className={styles.headerImageDesktop}
                src={imgUrl}
                alt='header image'
              />
              <span className={styles.tags}>
                {
                  data?.categories?.map((category, i) => (
                    findCategoryName(categories, category.categoryId, i18n) || '')
                  ).join(' | ')
                }
              </span>
            </MediaQuery>
            <MediaQuery query='(max-width: 62rem)'>
              <DonationBox>
                <DonationHeader>
                  {
                    t('project_goal_header')
                  }
                </DonationHeader>
                <DonationBody>
                  <div className={styles.progressBarWrapper}>
                    <ProgressBar
                      current={data?.crowdfunding?.total}
                      goal={data?.crowdfunding?.goal}
                      options={{
                        showBubble: true
                      }}
                    />
                  </div>
                  <div className={styles.boxGoalWrapper}>
                    <span className={styles.boxGoalLabelLeft}>{t('project_goal')} {data?.crowdfunding?.goal}€</span>
                    <span className={styles.boxGoalLabel}>
                      {data?.crowdfunding?.total}€
                    </span>
                  </div>
                  <span className={styles.daysLeftLabel}>
                    {getDaysLeft(data?.crowdfunding?.finishes_at)} {t('project_days_left')}
                  </span>
                  <button
                    type='button'
                    onClick={() => donationButtonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })}
                    className={styles.button}
                  >
                    {
                      t('project_make_donation')
                    }
                  </button>
                  <span className={styles.donationText}>
                    {data?.crowdfunding?.donations} {t('project_donations')}
                  </span>
                </DonationBody>
              </DonationBox>
            </MediaQuery>
            <span
              dangerouslySetInnerHTML={{ __html: data?.crowdfunding?.description }}
              className={styles.text}
            >
            </span>
          </div>
          <div className={styles.col2}>
            <MediaQuery query='(min-width: 62rem)'>
              <DonationBox
                size='large'
              >
                <DonationHeader>
                  {
                    t('project_goal_header')
                  }
                </DonationHeader>
                <DonationBody>
                  <div className={styles.progressBarWrapper}>
                    <ProgressBar
                      current={data?.crowdfunding?.total}
                      goal={data?.crowdfunding?.goal}
                      options={{
                        showBubble: true
                      }}
                    />
                    </div>
                    <div className={styles.boxGoalWrapper}>
                      <span className={styles.boxGoalLabelLeft}>{t('project_goal')} {data?.crowdfunding?.goal}€</span>
                      <span className={styles.boxGoalLabel}>
                        {data?.crowdfunding?.total}€
                      </span>
                    </div>
                    <span className={styles.daysLeftLabel}>
                      {getDaysLeft(data?.crowdfunding?.finishes_at)} {t('project_days_left')}
                    </span>
                  <button
                    type='button'
                    onClick={() => donationButtonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })}
                    className={styles.button}
                  >
                    {
                      t('project_make_donation')
                    }
                  </button>
                  <span className={styles.donationText}>{data?.crowdfunding?.donations ? data.crowdfunding.donations : 0 } {t('project_donations')}</span>
                </DonationBody>
              </DonationBox>
            </MediaQuery>
            <AuthorBox
              title={t('project_author_header')}
            >
              <AuthorHeader
                name={data?.crowdfunding?.creator_name}
                lastName={data?.crowdfunding?.creator_lastname}
                position={data?.crowdfunding?.creator_position}
                avatar={`${process.env.HOST_URL || ''}/locales/shared/avatars/${data?.crowdfunding?.creator_id}.png`}
              />
              <video controls>
                <source src={`${process.env.HOST_URL || ''}/locales/shared/videos/${data?.crowdfunding?.id}.mp4`} type="video/mp4" alt="uploaded video preview"/>
              </video>
            </AuthorBox>
            <DonationBox>
              <DonationHeader>
                {
                  t('project_donation_header')
                }
              </DonationHeader>
              <DonationBody>
                <DonationInput
                  value={form.donation}
                  onChange={(e) => {
                    // console.log('on change', e.target.value);
                    changeFormValue({ key: 'donation', value: e.target.value });
                  }}
                />
                {/* <DonationInfo
                  title='Objectiu'
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                </DonationInfo> */}
                <button
                  ref={donationButtonRef}
                  onClick={() => modalDonation.handleTouch()}
                  id='donation-button'
                  disabled={!form.donation}
                  className={styles.button}
                >
                  {
                    t('project_make_donation')
                  }
                </button>
              </DonationBody>
            </DonationBox>

            {
              data.ods && data.ods.length > 0
                ? <DonationBox>
                  <DonationHeader>
                    {
                      t('project_ods_header')
                    }
                  </DonationHeader>
                  <DonationBody>
                    <div className={styles.odsWrapper}>
                      {
                        data?.ods?.map((od) => (
                          <img
                            key={od.tagId}
                            width={90}
                            height={90}
                            src={`/locales/images/ods/${i18n.resolvedLanguage}/${i18n.resolvedLanguage}_${addZeroToTag(od.tagId)}.jpg`}
                            alt={`ods-${od.tagId}`}
                          />
                        ))
                      }
                    </div>
                  </DonationBody>
                </DonationBox>
                : null
            }
          </div>
        </div>
      </div>
      <section
          className={styles.recommendedProjects}
        >
          <CardsHeaderMenu
            title={t('project_recommended')}
            disableArrows={true}
          />
          <CardsWrapper
            count={data?.recommended.length}
          >
            {
              data?.recommended?.map((project, i) => (
                <ProjectCard
                  key={project.id}
                  id={project.id}
                  title={project.title}
                  description={project.description}
                  goal={project.goal}
                  current={project.total}
                  endDate={project.finishes_at}
                  saved={project.saved}
                  disabled={saving}
                  onSave={() => handleSaveProject(project.id, project.saved)}
                  onClick={() => handleCardClick(project.id)}
                  saveColor={selectedColor?.color1}
                  tag={project.categories.length >= 1 && findCategoryName(categories, parseInt(project.categories[0]), i18n)}
                />
              ))
            }
          </CardsWrapper>
        </section>
    </Fragment>
  );
};

export {
  Project
};
