const getColors = async () => {
  try {
    const response = await fetch('https://dev1.youdrop.org/colorsets', {
      method: 'GET'
    });

    const result = await response.json();

    return { ...result, statusNumber: response.status };
  } catch (error) {
    return error;
  }
};

const saveMemberColor = async ({ email, token, color }) => {
  const response = await fetch('https://dev1.youdrop.org/users/color', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      colorset_id: color,
      email
    })
  });

  const result = await response.json();

  if (response.status !== 200) {
    const error = new Error('Failed to save color');
    error.status = response.status;
    throw error;
  }

  return { ...result, statusNumber: response.status };
};

const getMemberColor = async ({ token, email }) => {
  try {
    const response = await fetch(`https://dev1.youdrop.org/users/color/?email=${email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    });

    const result = await response.json();

    return result;
  } catch (error) {
    return error;
  }
};

export {
  getColors,
  saveMemberColor,
  getMemberColor
};
