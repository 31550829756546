const menuOptions = [
  {
    name: 'Inici',
    i18n: 'navbar.menu.home',
    action: {
      type: 'navigate',
      payload: '/home'
    }
  },
  {
    name: 'Categories',
    i18n: 'navbar.menu.categories',
    action: {
      type: 'submenu',
      payload: 'categories'
    }
  },
  {
    name: 'Filtres',
    i18n: 'navbar.menu.filters',
    action: {
      type: 'submenu',
      payload: 'filters'
    }
  },
  // {
  //   name: 'Blog',
  //   action: {
  //     type: 'navigate',
  //     payload: '/blog'
  //   }
  // },
  {
    name: 'Compta',
    i18n: 'navbar.menu.account',
    action: {
      type: 'navigate',
      payload: '/panel'
    }
  },
  {
    name: 'Notificacions',
    i18n: 'navbar.menu.notifications',
    action: {
      type: 'navigate',
      payload: '/notifications'
    }
  },
  // {
  //   name: 'Informació de pagament',
  //   i18n: 'navbar.menu.payment',
  //   action: {
  //     type: 'navigate',
  //     payload: '/payment-info'
  //   }
  // },
  {
    name: 'Projectes guardats',
    i18n: 'navbar.menu.savedCrowdfundings',
    action: {
      type: 'navigate',
      payload: '/panel/savedCrowdfundings'
    }
  }
];

const wideMenuOptions = [
  {
    name: 'Inici',
    slug: 'home',
    i18n: 'navbar.menu.home',
    action: {
      type: 'navigate',
      payload: '/home'
    }
  },
  {
    name: 'Categories',
    slug: 'categories',
    i18n: 'navbar.menu.categories',
    action: {
      type: 'submenu',
      payload: '/categories'
    }
  },
  // {
  //   name: 'Blog',
  //   slug: 'blog',
  //   action: {
  //     type: 'navigate',
  //     payload: '/blog'
  //   }
  // },
  {
    name: 'Puja el teu projecte',
    slug: 'upload-project',
    i18n: 'navbar.menu.upload',
    action: {
      type: 'navigate',
      payload: '/upload-project'
    }
  }
];

const categories = [
  {
    id: null,
    name: 'Totes'
  }
];

export default {
  categories,
  menuOptions,
  wideMenuOptions
};
