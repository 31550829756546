import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  BrowserRouter
} from 'react-router-dom';

import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import './i18n';

import COLOR_CONTEXT_PROVIDER from './context/ColorProvider';
import AUTH_CONTEXT_PROVIDER from './context/AuthProvider';

import './styles/global.css';
import { Routing } from './routes/index';

const container = document.getElementById('app');
const root = createRoot(container);

const queryClient = new QueryClient();

root.render(
  <PayPalScriptProvider options={
      {
        'client-id': process.env.PAYPAL_CLIENT_ID,
        currency: 'EUR'
      }
    }>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <COLOR_CONTEXT_PROVIDER>
          <AUTH_CONTEXT_PROVIDER>
            <Routing />
          </AUTH_CONTEXT_PROVIDER>
        </COLOR_CONTEXT_PROVIDER>
      </BrowserRouter>
    </QueryClientProvider>
  </PayPalScriptProvider>
);
