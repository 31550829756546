
import PropTypes from 'prop-types';

import clsx from 'clsx';
import * as styles from './DonationBox.module.css';

const DonationHeader = (props) => {
  return (
    <div className={styles.header}>
      <h3 className={styles.infoTitle}>
        {props.children}
      </h3>
    </div>
  );
};

const DonationBox = (props) => {
  return (
    <div className={clsx(styles.box, props.size === 'large' && styles.bigBoxDesktop)}>
      {props.children}
    </div>
  );
};

const DonationInput = (props) => {
  return (
    <div className={styles.inputWrapper}>
      <input
        type='currency'
        value={props.value}
        onChange={(e) => props.onChange(e)}
        placeholder="0"
        inputMode='numeric'
        className={styles.input}
      />
    </div>
  );
};

const DonationInfo = (props) => {
  return (
    <div className={styles.info}>
      {
        props.title && (
          <span className={styles.infoTitle}>
            {props.title}
          </span>
        )
      }
      <span>
        {props.children}
      </span>
    </div>
  );
};

const DonationBody = (props) => {
  return (
    <div className={styles.body}>
      {props.children}
    </div>
  );
};

DonationBox.propTypes = {
  children: PropTypes.node.isRequired
};

DonationHeader.propTypes = {
  children: PropTypes.node.isRequired
};

export {
  DonationHeader,
  DonationBody,
  DonationInput,
  DonationInfo
};

export default DonationBox;
