.boxProgressWrapper {
  position: relative;
  width: 100%;
  height: 7px;
  display: flex;
  margin-bottom: 15px;
}

.boxProgress {
  display: block;
  accent-color: var(--color1);
  width: 100%;
  height: 7px;
  border: 0px solid transparent;
  border-radius: 10px;
  /* transition: all 0.3s ease-in-out; */
}


.boxProgressCircle {
  position: absolute !important;
  left: 0;
  width: 15px;
  height: 15px;
  top: -4px;
  border-radius: 50%;
  background-color: var(--color1);
  z-index: 10;
  transition: all 0.3s ease-in-out;
}

.boxProgressCircleText {
  display: block;
  position: absolute !important;
  color: white;
  font: normal normal bold 1.4rem/2.1rem Rubik;
  top: -45;
  padding: 3px 7px;
  border-radius: 10px;
  background-color: var(--color1);
}

.boxProgress::-webkit-progress-value {
  background-color: var(--color1);
  border-radius: 10px;
}

.boxProgress::-moz-progress-bar {
  background-color: var(--color1);
  border-radius: 10px;
}


.boxProgressCircleText:after {
  content: "";
  position: absolute !important;
  display: block;

  /* position tooltip correctly */
  left: 40%;
  margin-left:-5px;

  /* vertically center */
  bottom: 0;
  transform: translateY(100%);

  /* the arrow */
  border: 10px solid var(--color1);
  border-color: var(--color1) transparent transparent transparent;
}