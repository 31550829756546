import { useState } from 'react';

const useIndexes = (defaultIndexes = {}) => {
  const [indexes, setIndexes] = useState(defaultIndexes);

  const resetIndex = (indexName) => {
    setIndexes({
      ...indexes,
      [indexName]: 0
    });
  };

  const organizeElements = (indexValue = 0, elements = []) => {
    if (elements.length === 0) {
      return [];
    }

    return elements.map((element, i) => {
      const newIndexPos = i + indexValue;
      element.index = newIndexPos;

      if (newIndexPos < 0) {
        element.index = (elements.length) + newIndexPos;
      } else if ((newIndexPos) - (elements.length - 1) > 0) {
        element.index = (newIndexPos) - elements.length;
      }

      return element;
    })
      .sort((a, b) => a.index - b.index);
  };

  return {
    indexes,
    sumIndex: (indexName, limit) => {
      if (indexes[indexName] === limit) {
        resetIndex(indexName);
        return;
      }

      setIndexes((prevIndexes) => ({
        ...prevIndexes,
        [indexName]: prevIndexes[indexName] + 1
      }));
    },
    subIndex: (indexName, limit) => {
      if (indexes[indexName] === -Math.abs(limit)) {
        resetIndex(indexName);
        return;
      }

      setIndexes((prevIndexes) => ({
        ...prevIndexes,
        [indexName]: prevIndexes[indexName] - 1
      }));
    },
    organizeElements
  };
};

export default useIndexes;
