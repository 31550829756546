import { useSpring, animated } from '@react-spring/web';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import arrowDownBlack from '/src/assets/icons/flecha_idioma_neg.svg';
import arrowDownWhite from '/src/assets/icons/flecha_idioma_pos.svg';
import dropIcon from '/src/assets/icons/icono_gota_idioma_activo.svg';

import { useShow } from '/src/services/hooks/useShow';

const Navbar = ({ forceStuck }) => {
  const languageMenu = useShow();

  const { i18n, t } = useTranslation();

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.99
  });

  const transition = useSpring({
    delay: 250,
    to: {
      opacity: !languageMenu.show ? 0 : 1,
      transform: !languageMenu.show ? 'translateY(-100%) scale(1, 0.2)' : 'translateY(0%) scale(1, 1)',
      pointerEvents: !languageMenu.show ? 'none' : 'all'
    }
  });

  return (
    <div ref={ref} className={clsx('landing__top-section__bar', (!inView || forceStuck) && 'landing__top-section__bar-stucked')}>
    {(inView && !forceStuck) && (<div className={clsx('youdrop-logo youdrop-logo--white')} alt="YouDrop Logo" />)}
    {(!inView || forceStuck) && (<div className={clsx('youdrop-logo youdrop-logo--long-blue')} alt="YouDrop Logo" />)}
    <div className='btn-lang-wrapper'>
      <button
        onClick={() => languageMenu.handleTouch()}
        className={clsx('btn-lang-selector', (!inView || forceStuck) && 'stucked', !languageMenu.show && 'active')}
      >
        {i18n.resolvedLanguage} <img src={(inView && arrowDownBlack) || arrowDownWhite} alt='arrow down' />
      </button>
      <button
        onClick={() => languageMenu.handleClose()}
        className={clsx('btn-lang-cross-mobile', (!inView || forceStuck) && 'stucked', languageMenu.show && 'active')}
      >
      </button>
      {
        <animated.div className='btn-lang__dropdown' style={transition}>
          <button className={clsx(i18n.resolvedLanguage === 'ca' && 'selected')} onClick={() => { i18n.changeLanguage('ca'); languageMenu.handleTouch(); }}>
            {i18n.resolvedLanguage === 'ca' && (<img src={dropIcon}/>)}
            {
              t('landing_ca')
            }
          </button>
          <button className={clsx(i18n.resolvedLanguage === 'es' && 'selected')} onClick={() => { i18n.changeLanguage('es'); languageMenu.handleTouch(); }}>
            {i18n.resolvedLanguage === 'es' && (<img src={dropIcon}/>)}
            {
              t('landing_es')
            }
          </button>
          <button className={clsx(i18n.resolvedLanguage === 'en' && 'selected')} onClick={() => { i18n.changeLanguage('en'); languageMenu.handleTouch(); }}>
            {i18n.resolvedLanguage === 'en' && (<img src={dropIcon}/>)}
            {
              t('landing_en')
            }
          </button>
          <button className={clsx(i18n.resolvedLanguage === 'fr' && 'selected')} onClick={() => { i18n.changeLanguage('fr'); languageMenu.handleTouch(); }}>
            {i18n.resolvedLanguage === 'fr' && (<img src={dropIcon}/>)}
            {
              t('landing_fr')
            }
          </button>
        </animated.div>
      }
    </div>
    </div>
  );
};

export default Navbar;
