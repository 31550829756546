const file2Base64 = async (file) => {
  const reader = new FileReader();

  const data = await new Promise((resolve, reject) => {
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
  return data;
};

export default file2Base64;
