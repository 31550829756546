
.projectsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.categoryTitle {
  margin-bottom: 2rem;
}
.noProjectsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 17rem;
  gap: 2rem;
  text-align: center;
  color: #5a5a5a;
}
@media screen and (min-width: 62rem) {
  .projectsContainer {
    justify-content: flex-start;
  }
}
