import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

const Delayed = ({ children, delay }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setShow(true);
    }, delay);

    return () => clearTimeout(id);
  }, [delay]);

  return (
    show && (
      <div>
        {children}
      </div>
    )
  );
};

Delayed.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number.isRequired
};

export default Delayed;
