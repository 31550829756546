import clsx from 'clsx';
import './style.css';

import PropTypes from 'prop-types';

import { useSpring, animated } from '@react-spring/web';

const ModalTitle = ({ children, onClose }) => {
  return (
  <div className='modal-content__title'>
      <button
          type='button'
          onClick={onClose}
          className='modal-close'
      >
      </button>
      {children}
  </div>
  );
};

const ModalBody = ({ children }) => {
  return <div className='modal-content__body'>{children}</div>;
};

const Modal = ({
  children,
  visible,
  size,
  position,
  clickableOutside
}) => {
  const modalProps = useSpring(
    (
      position === 'right' && {
        opacity: visible ? 1 : 0,
        transform: visible ? 'translate3d(0%,0,0)' : 'translate3d(100%,0%,0)'
      }
    ) ||
    {
      opacity: visible ? 1 : 0
    }
  );

  if (position !== 'right' && !visible) {
    return null;
  }

  return (
    <animated.div
      style={modalProps}
      className={
        clsx(
          'modal-wrapper', visible && 'modal-wrapper--show',
          position === 'right' && 'modal--right',
          clickableOutside && 'modal-wrapper--clickable-outside'
        )
      }
    >
      {
        (
          size !== 'none' && (
          <div className={
              clsx(
                'modal-content', (
                  (size === 'large' && 'modal-content--large') ||
                  (size === '50w' && 'modal-content--50w')
                )
              )
          }>
            {children}
          </div>)
        ) || children
      }
    </animated.div>
  );
};

ModalTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func
};

ModalBody.propTypes = {
  children: PropTypes.node
};

Modal.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['none', 'small', 'large', '50w']),
  position: PropTypes.oneOf(['center', 'right']),
  clickableOutside: PropTypes.bool
};

export default Modal;

export {
  ModalTitle,
  ModalBody
};
