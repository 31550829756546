import { useState, createContext, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useLocation, Link } from 'react-router-dom';

import { animated, useSpring, useTransition } from '@react-spring/web';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import './style.css';

import { LogoSvg } from '../../components/svg/Logo';
import { ArrowSvg } from '../../components/svg/Arrow';

import Modal, { ModalTitle, ModalBody } from '../../components/Modal';

import FilterSVG from '../../assets/icons/filtres.svg';
import HamSvg from '../../assets/icons/ham.svg';
import CancelSvgWhite from '../../assets/icons/cancel_white.svg';
import MagnifierSvg from '../../assets/icons/magnifier.svg';
import CancelSvg from '../../assets/icons/cancel.svg';
import MemberSvg from '../../assets/icons/member.svg';
import NotificationSvg from '../../assets/icons/notification.svg';

/* Hooks */
import { useShow } from '../../services/hooks/useShow';
import useFilters from './hooks/useFilters';

/* Components */
import { MediaQuery } from '../helpers/MediaQuery';
import { Button } from '../../components/Button';
import { CheckBox } from '../../components/CheckBox';

import api from '../../services/api';

import { AUTH_CONTEXT } from '/src/context/AuthProvider';

import config from './config';

import arrowDownWhite from '/src/assets/icons/flecha_idioma_pos.svg';
import dropIcon from '/src/assets/icons/icono_gota_idioma_activo.svg';

const {
  menuOptions,
  wideMenuOptions
} = config;

const NavbarContext = createContext();

const findCategoryName = (categories, id, i18n) => {
  return categories?.find(category => id === category.id)?.name[i18n.resolvedLanguage || 'ca'] || 'Ninguna';
};

const Navbar = ({ color, onLogout, onNavigate, children }) => {
  const { member } = useContext(AUTH_CONTEXT);

  const { t, i18n } = useTranslation();

  const [searchOn, setSearchOn] = useState(false);
  const [menuOn, setMenuOn] = useState(false);
  const [subMenu, setSubMenu] = useState('');

  const languageMenu = useShow(false);
  const { show, handleTouch, handleClose } = useShow(false);

  const [showFilters, setShowFilters] = useState(false);

  const {
    filters,
    changeFilterCategory,
    changeProjectsFilter,
    changeGoal,
    changePercentage,
    resetFilters
  } = useFilters();

  const location = useLocation();

  const { token, email } = member;

  const {
    data,
    isLoading,
    isError,
    failureCount
  } = useQuery(
    ['categories', { token, email }],
    () => api.getProjectsCategories({ token, email }),
    {
      refetchOnWindowFocus: true,
      enabled: (!!token && !!email)
    });

  const contentProps = useSpring({
    opacity: searchOn ? 0 : 1,
    marginRight: searchOn ? 0 : 0,
    transform: searchOn ? 'translate3d(-50%,0,0)' : 'translate3d(0%,0,0)'
  });

  const rightProps = useSpring({
    position: searchOn ? 'absolute' : 'static',
    left: searchOn ? 25 : 0,
    transform: searchOn ? 'translate3d(0%,0,0)' : 'translate3d(0%,0,0)'
  });

  const dropdownSpring = useSpring({
    opacity: menuOn ? 1 : 0
  });

  const transition = useTransition(subMenu, {
    from: { x: -220, y: 0, opacity: 0 },
    enter: { x: 0, y: 0, opacity: 1 },
    leave: { x: -220, y: 0, opacity: 0 }
  });

  const transitionMenuLang = useSpring({
    delay: 250,
    to: {
      opacity: !languageMenu.show ? 0 : 1,
      transform: !languageMenu.show ? 'translateY(-100%) scale(1, 0.2)' : 'translateY(0%) scale(1, 1)',
      pointerEvents: !languageMenu.show ? 'none' : 'all'
    }
  });

  const handleChangeLang = async (e, lang = 'es') => {
    e.preventDefault();

    const { token, email } = member;

    try {
      await api.updateLanguage({
        token,
        email,
        language: lang,
        locale: i18n.resolvedLanguage
      });

      i18n.changeLanguage(lang);
    } catch (error) {
      console.error('error changing the language ', error.message);
    } finally {
      languageMenu.handleTouch();
    }
  };

  const handleToggleSearch = (e) => {
    e.preventDefault();
    setSearchOn(!searchOn);
  };

  const handleToggleMenu = (e) => {
    e.preventDefault();
    setMenuOn(!menuOn);
    setSubMenu('');
  };

  const handleMenuNavigation = (e, action) => {
    e.preventDefault();

    setShowFilters(false);

    if (!action) {
      return;
    }

    if (action.type === 'navigate') {
      onNavigate(action.payload);
    } else if (action.type === 'submenu') {
      setSubMenu(action.payload);
    }
  };

  const isRouteCategories = location.pathname === '/categories';

  const footersContent = {
    default: (
      <div className='navbar__dropdown__footer'>
        <button onClick={() => onNavigate('/upload-project')} className='navbar__dropdown_footer__btn' type='button'>
          {
            t('uploadProject')
          }
        </button>
        <button onClick={() => onLogout()} className='navbar__dropdown_footer__btn--logout' type='button'>
          {
            t('logout')
          }
        </button>
      </div>
    ),
    categories: (
      <div/>
    ),
    filters: (
      <div className='navbar__dropdown__footer'>
        <button type='button' className='navbar__dropdown_footer__btn--filters'>
          <span>{
            t('navbar_filters')
          }</span>
        </button>
      </div>
    )
  };

  const dropdownContent = {
    default: (
      menuOptions.map((option, index) => (
        <animated.button onClick={(e) => handleMenuNavigation(e, option?.action)} key={index} className='navbar__dropdown-item' type='button'>
          <span>
          {
            option?.i18n ? t(option.i18n) : option.name
          }
          </span>
          {
            option.action.type === 'submenu' && (
              <div className='navbar__dropdown-arrow'>
                <ArrowSvg color={color}/>
              </div>
            )
          }
        </animated.button>
      ))
    ),
    categories: transition((style, item) => (
      item
        ? <animated.div style={style} className='navbar__dropdown-items-wrapper'>
        {
          data?.categories.map((category, index) => (
            <button key={index} className='navbar__dropdown-item' type='button'>
              <span>
                {category.name}
              </span>
            </button>
          ))
        }
      </animated.div>
        : ''
    )),
    filters: (
      <div/>
    )
  };

  return (
    <NavbarContext.Provider
      value={{
        categories: data?.categories,
        filters,
        findCategoryName
      }}
    >
      <Modal
        position={'right'}
        clickableOutside={true}
        visible={showFilters}
      >
        <ModalTitle
          onClose={() => setShowFilters(false)}
        >
          <h1>
          {
            t('filters_title')
          }
          </h1>
        </ModalTitle>
        <ModalBody>
          <form onSubmit={(e) => e.preventDefault()} id='filter'>
          <div className='form-group'>
            <div className='form-group__title'>
              {
                t('filters_projecte')
              }
            </div>
            <label
              className='form__checkbox__label'
              htmlFor='checkbox'
            >
              <CheckBox
                onChange={() => changeProjectsFilter('')}
                checked={!filters?.filter}
              />
              <span className='form__checkbox-text'>
                {t('categories_all')}
              </span>
            </label>
            <label
              className='form__checkbox__label'
              htmlFor='checkbox'
            >
              <CheckBox
                onChange={() => changeProjectsFilter('new')}
                checked={filters?.filter === 'new'}
              />
              <span className='form__checkbox-text'>
                {
                  t('filters_newprojects')
                }
              </span>
            </label>
            <label
              className='form__checkbox__label'
              htmlFor='checkbox'
            >
              <CheckBox
                onChange={() => changeProjectsFilter('reaching')}
                checked={filters?.filter === 'reaching'}
              />
              <span className='form__checkbox-text'>
                {
                  t('filters_reaching')
                }
              </span>
            </label>
            <label
              className='form__checkbox__label'
              htmlFor='checkbox'
            >
              <CheckBox
                onChange={() => changeProjectsFilter('last_days')}
                checked={filters?.filter === 'last_days'}
              />
              <span className='form__checkbox-text'>
                {
                  t('filters_lastdays')
                }
              </span>
            </label>
          </div>
          <div className='form-group'>
            <div className='form-group__title'>
              {
                t('filters_meta')
              }
            </div>
            <label
              className='form__checkbox__label'
              htmlFor='checkbox'
            >
              <CheckBox
                onChange={() => changeGoal(0, 1000)}
                checked={filters?.goal_max !== null && filters?.goal_max <= 1000}
              />
              <span className='form__checkbox-text'>
                &gt; 1000€
              </span>
            </label>
            <label
              className='form__checkbox__label'
              htmlFor='checkbox'
            >
              <CheckBox
                onChange={() => changeGoal(1000, 2000)}
                checked={filters?.goal_max !== null && filters?.goal_max <= 2000 && filters?.goal_min >= 1000}
              />
              <span className='form__checkbox-text'>
                1000-2000€
              </span>
            </label>
            <label
              className='form__checkbox__label'
              htmlFor='checkbox'
            >
              <CheckBox
                onChange={() => changeGoal(2000, 5000)}
                checked={filters?.goal_max !== null && filters?.goal_max <= 5000 && filters?.goal_min >= 2000}
              />
              <span className='form__checkbox-text'>
                2000-5000€
              </span>
            </label>
            <label
              className='form__checkbox__label'
              htmlFor='checkbox'
            >
              <CheckBox
                onChange={() => changeGoal(5000, 1000000)}
                checked={filters?.goal_min >= 5000}
              />
              <span className='form__checkbox-text'>
                &lt; 5000€
              </span>
            </label>
          </div>
          <div className='form-group'>
            <div className='form-group__title'>
              {
                t('filters_percentage')
              }
            </div>
            <label
              className='form__checkbox__label'
              htmlFor='checkbox'
            >
              <CheckBox
                onChange={() => changePercentage(0, 25)}
                checked={filters?.percentage_max !== null && filters?.percentage_max <= 25}
              />
              <span className='form__checkbox-text'>
                &gt; 25%
              </span>
            </label>
            <label
              className='form__checkbox__label'
              htmlFor='checkbox'
            >
              <CheckBox
                onChange={() => changePercentage(25, 50)}
                checked={filters?.percentage_max !== null && filters?.percentage_max <= 50 && filters?.percentage_min >= 25}
              />
              <span className='form__checkbox-text'>
                25-50%
              </span>
            </label>
            <label
              className='form__checkbox__label'
              htmlFor='checkbox'
            >
              <CheckBox
                onChange={() => changePercentage(50, 75)}
                checked={filters?.percentage_max !== null && filters?.percentage_max <= 75 && filters?.percentage_min >= 50}
              />
              <span className='form__checkbox-text'>
                50-75%
              </span>
            </label>
            <label
              className='form__checkbox__label'
              htmlFor='checkbox'
            >
              <CheckBox
                onChange={() => changePercentage(75, null)}
                checked={filters?.percentage_min >= 75}
              />
              <span className='form__checkbox-text'>
                &lt; 75%
              </span>
            </label>
          </div>
            <Button
              type='submit'
              form='filter'
              onClick={() => resetFilters()}
            >
              {
                t('filters_reset')
              }
            </Button>
          </form>
        </ModalBody>
      </Modal>
      <div className={clsx('navbar__wrapper')}>
        <div className={clsx('navbar', menuOn && 'navbar--menu-on')}>
          <div className={clsx('navbar__inner', isRouteCategories && 'navbar__inner--pt')}>
            <animated.div
              style={contentProps}
              className='navbar__logo'
            >
            <MediaQuery query='(max-width: 62rem)'>
              <LogoSvg color={menuOn ? 'white' : color }/>
            </MediaQuery>
            <MediaQuery query='(min-width: 62rem)'>
              <LogoSvg color={color}/>
            </MediaQuery>
            </animated.div>
            <MediaQuery query={'(min-width: 62rem)'}>
              <div className='navbar__options-wrapper'>
                {
                  wideMenuOptions.map((option, i) => (
                    <span className={clsx('navbar__option', option.action.payload === location.pathname && 'navbar__option--selected')} key={option.name + i}>
                      <Link
                        onClick={() => setShowFilters(false)}
                        to={option.action.payload}
                      >
                        {
                          option?.i18n ? t(option.i18n) : option.name
                        }
                      </Link>
                    </span>
                  ))
                }
              </div>
            </MediaQuery>
            <animated.div style={rightProps} className={clsx('navbar__right-icons', searchOn && 'navbar__search-box')}>
              {/* {
                !menuOn && (
                  <button
                    type='button'
                    className='navbar__search-button'
                    onClick={(e) => handleToggleSearch(e)}
                  >
                    <object className={clsx('navbar__magnifier', searchOn && 'navbar__magnifier--nomargin')} data={MagnifierSvg} width="20" height="20" type='image/svg+xml' />
                  </button>
                )
              } */}
              {
                searchOn && (
                  <input className='navbar__search-input' type='text' placeholder='Buscar projecte...' />
                )
              }
              {
                <MediaQuery query='(min-width: 62rem)'>
                  <button type='button' className='navbar__notifications-btn'>
                    <img src={NotificationSvg} width="17" height="23" type='image/svg+xml' />
                  </button>
                  <button onClick={() => handleTouch()} className='navbar__myaccount-btn'>
                    <img
                      src={`${process.env.HOST_URL || ''}/locales/shared/avatars/${member?.id}.png?${member?.avatarKey}`}
                      alt='avatar'
                      onError={(e) => {
                        e.currentTarget.src = MemberSvg;
                        e.currentTarget.onerror = null;
                        e.currentTarget.classList.add('svg-loaded');
                      }}
                      onLoad={(e) => {
                        if (e.currentTarget.src?.includes('png')) {
                          e.currentTarget.classList.remove('svg-loaded');
                        }
                      }}
                    />
                  </button>
                  {
                    show && (
                      <div className='navbar__dropdown-desktop'>
                        <button onClick={() => {
                          handleClose();
                          onNavigate('/panel/account');
                        }}>
                          {
                            t('myAccount')
                          }
                        </button>
                        {/* <button onClick={() => onNavigate('/payment')}>Informació de pagament</button> */}
                        <button onClick={() => {
                          handleClose();
                          onNavigate('/panel/savedCrowdfundings');
                        }}>
                          {
                            t('navbar.menu.savedCrowdfundings')
                          }
                        </button>
                        <div className='navbar__dropdown-destkop__separator' />
                        <button onClick={() => {
                          handleClose();
                          onLogout();
                        }}>
                          {
                            t('logout')
                          }
                        </button>
                      </div>
                    )
                  }
                  <div className='btn-lang-wrapper'>
                    <button
                      onClick={() => languageMenu.handleTouch()}
                      className={clsx('btn-lang-selector', 'btn-lang-selector--trans', 'stucked', !languageMenu.show && 'active')}
                    >
                      {i18n.resolvedLanguage} <img src={arrowDownWhite} alt='arrow down' />
                    </button>
                    {
                      <animated.div className='btn-lang__dropdown' style={transitionMenuLang}>
                        <button
                          className={clsx(i18n.resolvedLanguage === 'ca' && 'selected')}
                          onClick={(e) => handleChangeLang(e, 'ca')}
                        >
                          {i18n.resolvedLanguage === 'ca' && (<img src={dropIcon}/>)}
                          {
                            t('landing_ca')
                          }
                        </button>
                        <button
                          className={clsx(i18n.resolvedLanguage === 'es' && 'selected')}
                          onClick={(e) => handleChangeLang(e, 'es')}
                        >
                          {i18n.resolvedLanguage === 'es' && (<img src={dropIcon}/>)}
                          {
                            t('landing_es')
                          }
                        </button>
                        <button
                          className={clsx(i18n.resolvedLanguage === 'en' && 'selected')}
                          onClick={(e) => handleChangeLang(e, 'en')}
                        >
                          {i18n.resolvedLanguage === 'en' && (<img src={dropIcon}/>)}
                          {
                            t('landing_en')
                          }
                        </button>
                        <button
                          className={clsx(i18n.resolvedLanguage === 'fr' && 'selected')}
                          onClick={(e) => handleChangeLang(e, 'fr')}
                        >
                          {i18n.resolvedLanguage === 'fr' && (<img src={dropIcon}/>)}
                          {
                            t('landing_fr')
                          }
                        </button>
                      </animated.div>
                    }
                  </div>
                </MediaQuery>
              }
              {
                <MediaQuery query='(max-width: 62rem)'>
                  <button
                    type='button'
                    className={clsx('navbar__search-button', searchOn && 'navbar__ham--right')}
                    onClick={(e) => !searchOn ? handleToggleMenu(e) : handleToggleSearch(e)}
                  >
                  {
                    menuOn
                      ? <object className='navbar__ham' data={CancelSvgWhite} width="29" height="18" type='image/svg+xml' />
                      : <object className='navbar__ham' data={searchOn ? CancelSvg : HamSvg} width="29" height="18" type='image/svg+xml' />
                  }
                  </button>
                </MediaQuery>
              }
            </animated.div>
          </div>
          {
            isRouteCategories && (
              <MediaQuery query='(min-width: 62rem)'>
                <div className='navbar__categories'>
                  <button
                    onClick={() => changeFilterCategory(null)}
                    className={clsx('navbar__category', filters?.category === 0 && 'navbar__category--selected')}
                  >
                    {t('categories_all')}
                  </button>
                  {
                    // console.log(data?.categories)
                  }
                  {
                    data?.categories.map((category, index) => (
                      <button
                        key={index}
                        type='button'
                        className={clsx('navbar__category', filters?.category === category.id && 'navbar__category--selected')}
                        onClick={() => changeFilterCategory(category.id)}
                      >
                        {category.name[i18n.resolvedLanguage || 'ca']}
                      </button>
                    ))
                  }
                  <button
                    onClick={() => setShowFilters(true)}
                    className='navbar__categories__filter-btn'
                  >
                    <img src={FilterSVG} />
                  </button>
                </div>
              </MediaQuery>
            )
          }
        </div>
        <MediaQuery query='(max-width: 62rem)'>
          {
            (<animated.div style={dropdownSpring} className={clsx('navbar__dropdown', menuOn && 'navbar__dropdown--active')}>
              { dropdownContent[subMenu || 'default'] }
              { footersContent[subMenu || 'default'] }
            </animated.div>)
          }
        </MediaQuery>
      </div>
      {children}
    </NavbarContext.Provider>
  );
};

Navbar.propTypes = {

};

export {
  Navbar,
  NavbarContext
};
