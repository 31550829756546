/* eslint-disable camelcase */
import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import api from '../services/api';

import antique_rose from '../assets/images/colors/antique_rose.png';
import bubble_gum from '../assets/images/colors/bubble_gum.png';
import caribbean_blue from '../assets/images/colors/caribbean_blue.png';
import georgia_peach from '../assets/images/colors/georgia_peach.png';
import glacier_blue from '../assets/images/colors/glacier_blue.png';
import glinty_green from '../assets/images/colors/glinty_green.png';
import sienna_brown from '../assets/images/colors/sienna_brown.png';
import tournesol from '../assets/images/colors/tournesol.png';

function hexToRGB (hex) {
  const h = '0123456789ABCDEF';
  const r = h.indexOf(hex[1]) * 16 + h.indexOf(hex[2]);
  const g = h.indexOf(hex[3]) * 16 + h.indexOf(hex[4]);
  const b = h.indexOf(hex[5]) * 16 + h.indexOf(hex[6]);

  return `${r}, ${g}, ${b}`;
}

const colorsResources = {
  1: {
    image: caribbean_blue,
    i18n_tag: 'caribbean_blue'
  },
  2: {
    image: glacier_blue,
    i18n_tag: 'glacier_blue'
  },
  3: {
    image: glinty_green,
    i18n_tag: 'glinty_green'
  },
  4: {
    image: tournesol,
    i18n_tag: 'tournesol'
  },
  5: {
    image: sienna_brown,
    i18n_tag: 'sienna_brown'
  },
  6: {
    image: bubble_gum,
    i18n_tag: 'bubble_gum'
  },
  7: {
    image: georgia_peach,
    i18n_tag: 'georgia_peach'
  },
  8: {
    image: antique_rose,
    i18n_tag: 'antique_rose'
  }
};

export const COLOR_CONTEXT = createContext();

const defaultColorValues = [
  {
    id: 1,
    image: colorsResources['1'].image,
    description: 'Saber nedar ens assegura la supervivència al mar, als rius i a les piscines. Fem que tothom tingui l’oportunitat d’aprendre’n!',
    name: 'Caribbean Blue',
    color1: '#39ADC1',
    color2: '#55C8DC',
    color3: '#CBEEF5',
    color4: '#EDF5F7',
    rgbcolor: `${hexToRGB('#39ADC1')}`
  }
];

const COLOR_CONTEXT_PROVIDER = (props) => {
  const [colors, setColors] = useState(defaultColorValues);
  const [selectedColor, setSelectedColor] = useState(defaultColorValues[0]);
  const [saveColorToServer, setSaveColorToServer] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleSelectPallete = (id, saveColor2DB = false) => {
    if (colors.length === 0) return;

    const pallete = colors.filter(color => color.id === id)[0];

    if (pallete) {
      setSelectedColor(pallete);
    }

    if (saveColor2DB) {
      setSaveColorToServer(true);
    }
  };

  useEffect(() => {
    const fetchColors = async () => {
      const result = await api.getColors();

      if (result instanceof Error) {
        console.log('Error fetching colors');
        setColors([...defaultColorValues]);
      } else {
        // console.log('fetchcolors', result);
        const colorValues = result.data.map((color) => {
          return {
            ...color,
            image: colorsResources[color.id].image,
            description: t(colorsResources[color.id].i18n_tag || 'caribbean_blue'),
            color1: `#${color.color1}`,
            color2: `#${color.color2}`,
            color3: `#${color.color3}`,
            color4: `#${color.color4}`,
            rgbcolor: `${hexToRGB('#' + color.color1)}`
          };
        });
        setColors([...colorValues]);
      }
    };
    fetchColors();
  }, []);

  useEffect(() => {
    const colorID = localStorage.getItem('color_id');
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');

    // If the user have token and email, then we load the color from the DB
    if (colors.length !== 0 && !token && !email) {
      if (colorID) {
        handleSelectPallete(parseInt(colorID), false);
      } else if (window.location.pathname === '/home') {
        navigate('/color');
      }
    }
  }, [colors]);

  useEffect(() => {
    document.documentElement.style.setProperty('--color1', selectedColor?.color1);
    document.documentElement.style.setProperty('--color2', selectedColor?.color2);
    document.documentElement.style.setProperty('--color3', selectedColor?.color3);
    document.documentElement.style.setProperty('--color4', selectedColor?.color4);
    document.documentElement.style.setProperty('--rgbcolor', selectedColor?.rgbcolor);
  }, [selectedColor]);

  return (
    <COLOR_CONTEXT.Provider
      value={{
        colors,
        selectedColor,
        handleSelectPallete,
        saveColorToServer
      }}>
      {props.children}
    </COLOR_CONTEXT.Provider>
  );
};

COLOR_CONTEXT_PROVIDER.propTypes = {
  children: PropTypes.node.isRequired
};

export default COLOR_CONTEXT_PROVIDER;
