import { useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import './panel.css';
import clsx from 'clsx';

export const Panel = () => {
  const location = useLocation();

  const { t, i18n } = useTranslation();

  const routeName = location.pathname.split('/panel/')[1] || 'account';

  const navigate = useNavigate();

  const [panelMenuOn, setPanelMenuOn] = useState(false);

  const handlePanelmenu = () => {
    setPanelMenuOn(!panelMenuOn);
  };

  return (
    <div className={clsx('panel-container', routeName)}>
      <div className='panel-menu'>
        <div className='panel-menu__title'>
          {
            t('panel_my_account')
          }
        </div>
        <div className={clsx('panel-menu__options', panelMenuOn && 'panel-menu__on')}>
          <button
            //onClick={() => handlePanelmenu()}
            onClick={() => navigate('account')}
            className={clsx(routeName === 'account' && 'active')}
          >
            <div className='ico-account'/>
            {
              t('panel_profile')
            }
          </button>
          <button
            onClick={() => navigate('history')}
            className={clsx(routeName === 'history' && 'active')}
          >
            <div className='ico-history'/>
            {
              t('panel_history')
            }
          </button>
          <button
            onClick={() => navigate('savedCrowdfundings')}
            className={clsx(routeName === 'savedCrowdfundings' && 'active')}
          >
            <div className='ico-saved'/>
            {
              t('panel_saved_projects')
            }
          </button>
          <button
            onClick={() => navigate('help')}
            className={clsx(routeName === 'help' && 'active')}
          >
            <div className='ico-help'/>
            {
              t('panel_help')
            }
          </button>
        </div>
      </div>
      <div className='panel-selected-page'>
        <Outlet/>
      </div>
    </div>
  );
};
