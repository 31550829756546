.box {
  border-radius: 8px;
  background-color: white;
  margin-bottom: 40px;
}

.box header {
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 80px;
  background-color: var(--color2);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font: normal normal bold 2.2rem/2.9rem Rubik;
  color: #FFFFFF;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--color3);
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.avatar img {
  min-width: 25px;
  min-height: 25px;
  max-width: 60px;
  max-height: 60px;
}

.header {
  padding: 20px;
  display: flex;
  flex-direction: row;
}
.name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}
.nameText {
  font: normal normal 800 1.6rem/2.1rem Karla;
  color: #212529;
  text-transform: capitalize;
}
.positionText {
  font: italic normal normal 1.6rem/2.1rem Karla;
  color: #212529;
  text-transform: capitalize;
}

.body {
  border-top: 0.5px solid #70707026;
  padding: 20px;
  font: normal normal normal 1.6rem/2.1rem Karla;
  color: #212529;
}