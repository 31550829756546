import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLOR_CONTEXT } from '/src/context/ColorProvider';
import { AUTH_CONTEXT } from '/src/context/AuthProvider';
import { CheckBox } from '/src/components/CheckBox';
import clsx from 'clsx';

import svgEyeShow from '/src/assets/icons/eye.svg';
import svgEyeHide from '/src/assets/icons/eye_slash.svg';

import api from '/src/services/api';
import { Button } from '/src/components/Button';

import './styles/login.css';

import { useTranslation } from 'react-i18next';

export const LoginPage = () => {
  const navigate = useNavigate();

  const {
    selectedColor,
    saveColorToServer,
    handleSelectPallete
  } = useContext(COLOR_CONTEXT);
  const { loadMember } = useContext(AUTH_CONTEXT);

  const { i18n } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const linkColors = {
    color: selectedColor?.color2,
    textDecoration: 'underline',
    textDecorationThickness: '0.3rem',
    textUnderlineOffset: '0.6rem'
  };
  const linkStyle = window.location.pathname === '/login' || window.location.pathname === '/' ? linkColors : {};

  const handleLogin = async () => {
    setLoading(true);

    const result = await api.login({ email, password });

    if (result.statusNumber === 200) {
      const token = result.token.token;

      setLoading(false);

      if (rememberMe) {
        localStorage.setItem('email', email);
        localStorage.setItem('token', token);
      } else {
        // If the users refreshes the page we're not going to mantain the color_id
        localStorage.removeItem('color_id');
      }

      loadMember({ email, token, id: result?.id });

      i18n.changeLanguage(result.language);

      navigate('/home');

      if (result.colorset === null) {
        navigate('/color');
      } else {
        handleSelectPallete(result.colorset);
      }

      // if (saveColorToServer) {
      //   await api.saveMemberColor({ email, token, color: selectedColor });
      // } else {
      //   console.log('result from login', result);
      //   handleSelectPallete(result.colorset);
      // }
    } else if (result.code === 'NOT_CONFIRMED') {
      setError('Revisa-hi el teu correu electrònic per a confirmar el teu compte.');
    } else if (result.code === 'DATABASE_ERROR') {
      setError('Hi ha un problema amb la base de dades. Si us plau, intenta-ho de nou.');
    } else if (result.statusNumber === 401) {
      setError('Email o contrasenya incorrectes');
    } else {
      setError('Error no esperat, si us plau, intenta-ho de nou.');
    }
    setLoading(false);
  };

  return (
    <div className='entrance-box'>
      <div className='entrance-box__header'>
        <span style={linkStyle} className='entrance-box__header__option'>
          <a href="" onClick={(e) => {
            e.preventDefault();
            navigate('/login');
          }}>INICIAR SESSIÓ</a>
        </span>
        <span className='entrance-box__header__option'>
          <a href="" onClick={(e) => {
            e.preventDefault();
            navigate('/login/register');
          }}>REGISTRAR-SE</a>
        </span>
      </div>
      <span className='form-title'>Et donem la benvinguda</span>
      <form
        className='form'
        onSubmit={(e) => {
          e.preventDefault();
          handleLogin();
        }}
      >
        <div className={'form-group'}>
          <label className='form__label'>
            <span className={'form__label__text'}>
              Email
            </span>
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              onBlur={() => setError('')}
              type='email'
              id='email'
              minLength={5}
              className={'form-control'}
              required={true}
            />
            {/* {
              !validations.identifier.validated && (
                <span className='form__label__error-message'>
                  {validations.identifier.message}
                </span>
              )
            } */}
          </label>
        </div>
        <div className={'form-group'}>
          <label className='form__label'>
            <span className={'form__label__text'}>
              Password
            </span>
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              onBlur={() => setError('')}
              type={showPassword ? 'text' : 'password'}
              id='password'
              minLength={8}
              className={'form-control'}
              required={true}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowPassword(!showPassword);
              }}
              type='button'
              className='password-eye'
            >
              <object
                className='password-eye__icon'
                data={showPassword ? svgEyeShow : svgEyeHide}
                width="20" height="18"
                type='image/svg+xml'
              />
            </button>
            {/* {
              !validations.identifier.validated && (
                <span className='form__label__error-message'>
                  {validations.identifier.message}
                </span>
              )
            } */}
          </label>
        </div>
        {
          error && (
            <span className='form__label__error-message form__label__error-message--login'>
              {error}
            </span>
          )
        }
        <span className={clsx('login-remember-password', error && 'login-remember-password--lessmargin')}>
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              navigate('/login/password-recovery');
            }}>
          Has oblidat la teva contrasenya?
          </a>
        </span>
        <Button
          style={{ backgroundColor: selectedColor?.color2 }}
          type='submit'
          className='form-button form-button--login'
          disabled={loading}
        >
          INICIAR SESSIÓ
        </Button>
        <div className='form-checkboxes'>
          <label
            className='form__checkbox__label'
            htmlFor='checkbox'
          >
            <CheckBox
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            />
            <span className='form__checkbox-text'>
              Recorda&apos;m
            </span>
          </label>
        </div>
      </form>
    </div>
  );
};
