const getProfile = async ({
  token,
  email,
  locale = 'es'
}) => {
  const response = await fetch(`https://dev1.youdrop.org/${locale}/users/info`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      email
    })
  });

  if (response.status !== 200) {
    throw new Error(response.json());
  }

  return await response.json();
};

const updateProfileInfo = async ({
  token,
  email,
  type = 'name',
  info = '',
  locale = 'es'
}) => {
  const supportedTypes = ['name', 'lastname', 'position'];

  if (!supportedTypes.includes(type)) {
    throw new Error('Type not allowed');
  }

  const response = await fetch(`https://dev1.youdrop.org/${locale}/users/changeinfo/${type}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      email,
      info
    })
  });

  if (response.status !== 200) {
    const responseJSON = await response.json();
    throw new Error(responseJSON?.message || 'Unrecognized error');
  }

  return await response.json();
};

const updateEmail = async ({
  token,
  email,
  newEmail,
  locale = 'es'
}) => {
  const response = await fetch(`https://dev1.youdrop.org/${locale}/users/changemail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      email,
      new_email: newEmail
    })
  });

  if (response.status !== 200) {
    const responseJSON = await response.json();
    throw new Error(responseJSON?.message || 'Unrecognized error');
  }

  return await response.json();
};

const uploadAvatar = async ({
  token,
  email,
  avatar,
  locale = 'es'
}) => {
  const response = await fetch(`https://dev1.youdrop.org/${locale}/users/avatar`, {
    method: 'POST',
    headers: {
      Authorization: token
    },
    body: JSON.stringify({
      email,
      avatar
    })
  });

  if (response.status !== 200) {
    const responseJSON = await response.json();
    throw new Error(responseJSON?.message || 'Unrecognized error');
  }

  return await response.json();
};

const updatePassword = async ({
  token,
  email,
  password,
  newPassword,
  locale = 'es'
}) => {
  const response = await fetch(`https://dev1.youdrop.org/${locale}/users/changepassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      email,
      current_password: password,
      new_password: newPassword
    })
  });

  if (response.status !== 200) {
    const responseJSON = await response.json();
    throw new Error(responseJSON?.message || 'Unrecognized error');
  }

  return await response.json();
};

export {
  getProfile,
  updateProfileInfo,
  updateEmail,
  updatePassword,
  uploadAvatar
};
