import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import * as styles from './CardsHeaderMenu.module.css';

const CardsHeaderMenu = ({
  title,
  disableArrows,
  leftArrowClick,
  rightArrowClick
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <h2>
        {title}
      </h2>
      {/* <button className={styles.showButton}>
        {t('button_view_all')}
      </button> */}
      {
        !disableArrows && (
          <div className={styles.arrows}>
            <button onClick={() => leftArrowClick()}/>
            <button onClick={() => rightArrowClick()}/>
          </div>
        )
      }
    </div>
  );
};

CardsHeaderMenu.propTypes = {
  title: PropTypes.string.isRequired,
  leftArrowClick: PropTypes.func,
  rightArrowClick: PropTypes.func,
  disableArrows: PropTypes.bool
};

CardsHeaderMenu.defaultProps = {
  disableArrows: false
};

export default CardsHeaderMenu;
