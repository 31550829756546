import { useContext, useState } from 'react';
import { AUTH_CONTEXT } from '/src/context/AuthProvider';
import { COLOR_CONTEXT } from '/src/context/ColorProvider';
import { NavbarContext } from '/src/components/Navbar';

import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import ProgressStats from './components/ProgressStats';
import ProjectsSlider from './components/ProjectsSlider';
import ProjectCard from '../../../components/ProjectCard/ProjectCard';
import CardsWrapper from '../../../components/CardsWrapper/CardsWrapper';
import CardsHeaderMenu from '../../../components/CardsHeaderMenu/CardsHeaderMenu';

import useIndexes from './hooks/useIndexes';

import api from '/src/services/api';

import './Home.css';

const defaultIndexes = {
  projects: 0,
  newProjects: 0,
  finalProjects: 0,
  lastDays: 0
};

const findCategoryName = (categories, id, i18n) => {
  return categories?.find(category => id === category.id)?.name[i18n.resolvedLanguage || 'ca'] || 'Ninguna';
};

export const Home = () => {
  const { selectedColor } = useContext(COLOR_CONTEXT);
  const { member } = useContext(AUTH_CONTEXT);

  const { categories } = useContext(NavbarContext);

  const {
    indexes,
    sumIndex,
    subIndex,
    organizeElements
  } = useIndexes(defaultIndexes);

  const [savingCards, setSavingCards] = useState([]);

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const { token, email } = member;

  const {
    data: projectsData,
    isLoading: projectsLoading,
    isError: projectsIsError,
    failureCount: projectsFailureCount,
    refetch
  } = useQuery(
    ['projects', { token, email }],
    () => api.getHomeProjects({ token, email }),
    {
      refetchOnWindowFocus: true,
      enabled: (!!token && !!email)
    });

  const handleSaveProject = async (id, saved) => {
    setSavingCards([...savingCards, id]);

    try {
      if (saved) {
        await api.unsaveProject({ token, email, id });
      } else {
        await api.saveProject({ token, email, id });
      }
    } catch (error) {
      console.error(error);
    } finally {
      refetch({
        token,
        email
      });
      setSavingCards(savingCards.filter(cardId => cardId !== id));
    }
  };

  const handleCardClick = (id) => {
    navigate('/project/' + id);
  };

  if (projectsLoading || (!token && !email)) {
    return (
      <div className='loading-container'>
        <div className='lds-dual-ring'/>
      </div>
    );
  }

  if (projectsIsError) {
    if (projectsFailureCount > 3) {
      return <Navigate
        to='/500'
        state={{ isError: true }}
      />;
    }
    return null;
  };

  // console.log(projectsData);

  const projectsLists = {
    projects: organizeElements(indexes.projects, projectsData?.projects).filter((project, i) => i < 4),
    newProjects: organizeElements(indexes.newProjects, projectsData?.new).filter((project, i) => i < 4),
    finalProjects: organizeElements(indexes.finalProjects, projectsData?.reaching).filter((project, i) => i < 4),
    lastDays: organizeElements(indexes.lastDays, projectsData?.last_days).filter((project, i) => i < 2)
  };

  return (
    <div className='container-app' style={{ fontSize: '5rem' }}>
      <header className='flex-section mb-40'>
        <div className='left-col-home'>
          <div>
            <h1 className='title-h1'>{t('home_title')}</h1>
            <span className='paragraph max-w-330'>{t('home_description')}</span>
          </div>
          <ProgressStats
            title={t('home_stats_title')}
            goals={[
              {
                title: t('home_stats_completed'),
                number: projectsData?.totals?.completed || '0'
              },
              {
                title: t('home_stats_active'),
                number: projectsData?.totals?.active || '0'
              },
              {
                title: t('home_stats_month'),
                number: `${projectsData?.totals?.donations_month}€` || '0 €'
              },
              {
                title: t('home_stats_total'),
                number: `${projectsData?.totals?.donations_youdrop}€` || '0 €'
              }
            ]}
          />
        </div>
        <div style={{ width: '100%' }}>
          <ProjectsSlider
            onProjectClick={() => console.log('Project clicked')}
            projects={projectsLists.lastDays}
          />
        </div>
      </header>
      <CardsHeaderMenu
        title={t('home_projects')}
        leftArrowClick={() => subIndex('projects', projectsLists.projects.length - 1)}
        rightArrowClick={() => sumIndex('projects', projectsLists.projects.length - 1)}
      />
      <CardsWrapper
        count={projectsLists.projects.length}
      >
        {
          projectsLists.projects.map((project, i) => (
            <ProjectCard
              key={project.id}
              id={project.id}
              title={project.title}
              description={project.description}
              goal={project.goal}
              current={project.total}
              endDate={project.finishes_at}
              saved={project.saved}
              disabled={savingCards.includes(project.id)}
              onSave={() => handleSaveProject(project.id, project.saved)}
              onClick={() => handleCardClick(project.id)}
              saveColor={selectedColor?.color1}
              tag={project.categories.length >= 1 && findCategoryName(categories, parseInt(project.categories[0]), i18n)}
            />
          ))
        }
      </CardsWrapper>
      {/* <CardsHeaderMenu
        title={t('home_new_projects')}
        leftArrowClick={() => subIndex('newProjects', projectsLists.newProjects.length - 1)}
        rightArrowClick={() => sumIndex('newProjects', projectsLists.newProjects.length - 1)}
      />
      <CardsWrapper
        count={projectsLists.newProjects.length}
      >
        {
          projectsLists.newProjects.map(project => (
            <ProjectCard
              key={project.id}
              id={project.id}
              title={project.title}
              description={project.description}
              goal={project.goal}
              current={project.total}
              endDate={project.finishes_at}
              saved={project.saved}
              disabled={savingCards.includes(project.id)}
              onSave={() => handleSaveProject(project.id, project.saved)}
              onClick={() => handleCardClick(project.id, project.saved)}
              saveColor={selectedColor?.color1}
              tag={project.categories.length >= 1 && findCategoryName(categories, parseInt(project.categories[0]), i18n)}
            />
          ))
        }
      </CardsWrapper>
      <CardsHeaderMenu
        title={t('home_final_projects')}
        leftArrowClick={() => subIndex('finalProjects', projectsLists.finalProjects.length - 1)}
        rightArrowClick={() => sumIndex('finalProjects', projectsLists.finalProjects.length - 1)}
      />
      <CardsWrapper
        count={projectsLists.finalProjects.length}
      >
        {
          projectsLists.finalProjects.map(project => (
            <ProjectCard
              key={project.id}
              id={project.id}
              title={project.title}
              description={project.description}
              goal={project.goal}
              current={project.total}
              endDate={project.finishes_at}
              saved={project.saved}
              disabled={savingCards.includes(project.id)}
              onSave={() => handleSaveProject(project.id, project.saved)}
              onClick={() => handleCardClick(project.id, project.saved)}
              saveColor={selectedColor?.color1}
              tag={project.categories.length >= 1 && findCategoryName(categories, parseInt(project.categories[0]), i18n)}
            />
          ))
        }
      </CardsWrapper>
      <CardsHeaderMenu
        title={t('home_last_days')}
        leftArrowClick={() => subIndex('projects', projectsLists.lastDays.length - 1)}
        rightArrowClick={() => sumIndex('projects', projectsLists.lastDays.length - 1)}
      />
      <section className='home-footer-slidercards'>
        {
          projectsLists.lastDays.map((project, i) => (
            <ProjectsSlider
              key={i}
              onProjectClick={() => console.log('Project clicked')}
              projects={[
                {
                  id: project.id,
                  name: project.title,
                  summary: project.description,
                  goal: project.goal,
                  total: project.total,
                  finishes_at: project.finishes_at
                }
              ]}
            />
          ))
        }
      </section> */}
    </div>
  );
};
