import { useState } from 'react';

export const useShow = (defaultShow = false) => {
  const [show, setShow] = useState(defaultShow);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleTouch = () => setShow(!show);

  return {
    show,
    handleShow,
    handleClose,
    handleTouch
  };
};
