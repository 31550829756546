import PropTypes from 'prop-types';

export const IconTick = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.997" height="10" viewBox="0 0 12.997 10">
      <path id="Trazado_2838_-_Contorno" data-name="Trazado 2838 - Contorno" d="M10.5,17a1.493,1.493,0,0,1-1.059-.437l-3-3a1.506,1.506,0,0,1,0-2.12l0,0a1.5,1.5,0,0,1,2.119,0L10.5,13.379l5.939-5.94a1.5,1.5,0,0,1,2.12,0l0,0a1.505,1.505,0,0,1,0,2.119l-7,7A1.494,1.494,0,0,1,10.5,17Z" transform="translate(-6.001 -7)" fill={fill || '#e84964'}/>
    </svg>
  );
};

IconTick.propTypes = {
  fill: PropTypes.string
};
