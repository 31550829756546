const updateLanguage = async ({
  locale = 'es',
  email,
  language,
  token
}) => {
  const response = await fetch(`https://dev1.youdrop.org/${locale}/users/language`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      email,
      language
    })
  });

  const result = await response.json();

  if (response.status !== 200) {
    const error = new Error('Failed to save language');
    error.status = response.status;
    throw error;
  }

  return { ...result, statusNumber: response.status };
};

export {
  updateLanguage
};
