
const subToNewsLetter = async ({ email }) => {
  try {
    const response = await fetch('https://dev1.youdrop.org/newsletter/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email
      })
    });

    const result = await response.json();

    return { ...result, statusNumber: response.status };
  } catch (error) {
    return error;
  }
};

export {
  subToNewsLetter
};
