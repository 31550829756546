.box {
  border-radius: 8px;
  background-color: white;
  margin-bottom: 40px;
}
.header {
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 80px;
  background-color: var(--color2);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font: normal normal bold 2.2rem/2.9rem Rubik;
  color: #FFFFFF;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font: normal normal normal 1.6rem/2.1rem Karla;
  color: #212529;
}
.inputWrapper {
  position: relative;
  width: 100%;
  font: normal normal normal 2.4rem/2.8rem Karla;
}
.input {
  width: 100%;
  height: 65px;
  border-radius: 10px;
  border: 1px solid #70707056;
  padding-left: 20px;
  padding-right: 20px;
  color: #212529;
  margin-bottom: 20px;
  font: normal normal normal 2.4rem/2.8rem Karla;
}

.inputWrapper::after {
  position: absolute;
  right: 25;
  top: 0;
  bottom: 0;
  margin-top: 20px;
  content: "€";
  color: #212529;
  text-align: right;
}


.infoTitle {
  display: block;
  color: #FFFFFF;
  font: normal normal bold 2.2rem/2.9rem Rubik;
}

.info {
  border-radius: 10px;
  background: var(--color1) 0% 0% no-repeat padding-box;
  margin-bottom: 30px;
  padding: 20px;
  font: normal normal normal 1.6rem/2.1rem Karla;
  color: #FFFFFF;
}


@media screen and (min-width: 82rem) {
  .header {
    font-size: 2.4rem;
  }
  .bigBoxDesktop {
    min-height: 390px;
  }
}