.cardBox {
  border-radius: 10px;
  width: 278px;
  min-width: 278px;
  background-color: white;
  position: relative;
  transition: all 0.3s ease-in-out;
  flex: 1;
}
.cardBoxDisabled {
  opacity: 0.7;
  pointer-events: none;
}
.cardTitle {
  display: block;
  font: normal normal bold 1.6rem/2.1rem Rubik;
  color: #212529;
  margin-bottom: 12px;
  text-align: left;
}
.cardDesc {
  display: block;
  font: normal normal normal 1.6rem/2.1rem Karla;
  color: #21252983;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 60px;
}
.cardHeadWrapper {
  min-height: 117px;
  margin-bottom: 34px;
}

.innerCard {
  display: block;
  width: 100%;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 23px 20px;
}
.cardHeaderImage {
  position: relative;
  width: 100%;
  height: 140px;
  border-radius: 10px 10px 0px 0px;
  background: url(/src/assets/images/portada_Foto2.jpeg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-clip: padding-box;
}

.boxGoalLabel {
  display: block;
  text-align: left;
  font: normal normal bold 1.6rem/2.1rem Rubik;
  color: #212529;
}

.boxGoalWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.daysLeftLabel {
  display: block;
  text-align: left;
  font: italic normal bold 1.4rem/1.7rem Karla;
  letter-spacing: 0px;
  color: var(--color1);
}
.cardBox .cardHeaderImage .saveButton {
  display: none;
  border: none;
  background: transparent;
}
.cardBox:hover .cardHeaderImage .saveButton {
  display: block;
  cursor: pointer;
  position: absolute;
  top: 15;
  right: 20;
}

.cardBox:hover .cardHeaderImage::before {
  content: attr(data-before);
  font: italic normal bold 1.2rem/1.4rem Karla;
  color: #FFFFFF;
  position: absolute;
  right: 20;
  bottom: 10;
  border-radius: 6px;
  background: var(--color1) 0% 0% no-repeat padding-box;
  line-height: 12px;
  padding: 6px 10px;
  text-transform: capitalize;
}
.cardBox:hover .innerCard .cardTitle {
  color: var(--color1);
  text-decoration: underline;
}

.cardBox:hover .innerCard .cardDesc {
  color: var(--color1);
}

@media screen and (min-width: 62rem) {
  .cardBox {
    min-width: 264px;
    max-width: 264px;
    height: 430px;
  }
}
@media screen and (min-width: 102rem) {
  .cardBox {
    min-width: 290px;
  }
}