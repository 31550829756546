import { useState, useContext } from 'react';

import clsx from 'clsx';
import { Navigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Modal, { ModalTitle, ModalBody } from '/src/components/Modal';
import { Button } from '/src/components/Button';

import { COLOR_CONTEXT } from '/src/context/ColorProvider';
import { ColorPicker } from '/src/components/ColorPicker';

import { useShow } from '/src/services/hooks/useShow';
import useForm from './hooks/useForm';

import './account.css';

import MemberSvg from '../../../assets/icons/member.svg';

import { AUTH_CONTEXT } from '/src/context/AuthProvider';

import file2Base64 from '/src/services/utils/base64';

import api from '../../../services/api';

import Delayed from '/src/components/helpers/Delayed';

export const Account = () => {
  const { member, loadMember, renderAvatars } = useContext(AUTH_CONTEXT);

  const { token, email } = member;

  const {
    colors,
    selectedColor,
    handleSelectPallete
  } = useContext(COLOR_CONTEXT);

  const { i18n, t } = useTranslation();

  const [draggingFile, setDraggingFile] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const modalUsername = useShow(false);
  const modalLastname = useShow(false);
  const modalPosition = useShow(false);
  const modalEmail = useShow(false);
  const modalAvatar = useShow(false);
  const modalPassword = useShow(false);
  const modalDelete = useShow(false);

  const {
    data,
    isLoading,
    isError,
    failureCount,
    refetch
  } = useQuery(
    ['account_page', {
      token,
      email,
      locale: i18n.resolvedLanguage
    }],
    () => api.getProfile({
      token,
      email,
      locale: i18n.resolvedLanguage
    }),
    {
      refetchOnWindowFocus: true,
      // this is tricky but it gonna make the query only be executed when we have token and email
      enabled: (!!token && !!email)
    });

  const {
    form,
    changeFormValue,
    resetValidation
  } = useForm({
    name: {
      type: 'name'
    },
    lastname: {
      type: 'name'
    },
    position: {
      type: 'name'
    },
    email: {
      type: 'email'
    },
    password: {
      type: 'password'
    },
    new_password: {
      type: 'password'
    },
    new_password2: {
      type: 'password'
    }
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleColorPicker = async (id) => {
    try {
      await api.saveMemberColor({
        email: member.email,
        token: member.token,
        color: id
      });
      handleSelectPallete(id, true);
    } catch (error) {
      console.log('Error saving color', error);
    }
  };

  const handleSubmitForm = async ({
    event,
    id,
    type = 'name',
    onSuccess = () => {},
    onError = () => {},
    avatar = null
  }) => {
    event.preventDefault();
    setLoading(true);

    const value = event?.target[id]?.value;

    let result;

    try {
      switch (type) {
        case 'avatar':
          result = await api.uploadAvatar({
            token,
            email,
            avatar,
            locale: i18n.resolvedLanguage
          });
          break;
        case 'password':
          result = await api.updatePassword({
            token,
            email,
            password: value,
            newPassword: event.target.new_password.value,
            locale: i18n.resolvedLanguage
          });
          break;
        case 'email':
          // console.log('email', value);
          result = await api.updateEmail({
            token,
            email,
            newEmail: value,
            locale: i18n.resolvedLanguage
          });

          result = {
            ...result,
            newEmail: value
          };
          break;
        default:
          result = await api.updateProfileInfo({
            token,
            email,
            type,
            info: value,
            locale: i18n.resolvedLanguage
          });
      }
    } catch (error) {
      onError(error?.message || t('account_unknown_error'));
    } finally {
      if (result) {
        onSuccess(result);
      }
      setLoading(false);
    }
  };

  if (isLoading || (!token && !email)) {
    return (
      <div className='loading-container'>
        <div className='lds-dual-ring'/>
      </div>
    );
  }
  if (isError) {
    if (failureCount > 3) {
      return <Navigate
        to='/500'
        state={{ isError: true }}
      />;
    }
    return null;
  };

  return (
    <div className='page-container'>
      <Modal
        visible={modalUsername.show}
      >
        <ModalTitle
          onClose={() => modalUsername.handleClose()}
        >
          <h1>
            {
              t('account_username')
            }
          </h1>
        </ModalTitle>
        <ModalBody>
        <form
          id='username'
          onSubmit={(e) => handleSubmitForm({
            event: e,
            id: 'userName',
            type: 'name',
            onSuccess: async () => {
              changeFormValue({
                key: 'name',
                value: ''
              });

              modalUsername.handleClose();

              await refetch({
                token,
                email,
                locale: i18n.resolvedLanguage
              });
            },
            onError: (message) => {
              setError(message);
            }
          })}
        >
          <div className={'form-group mb-35'}>
            <label className='form__label'>
              <span className='form__label__text'>
                {
                  t('account_name')
                }
              </span>
              <input
                className={clsx('form-control', !form.validations.name.isValid && 'is-invalid')}
                onChange={(e) => changeFormValue({ key: 'name', value: e.target.value })}
                value={form.name}
                id='userName'
                autoFocus
                onBlur={() => {
                  setError('');
                  resetValidation({ key: 'name' });
                }}
                onFocus={() => {
                  setError('');
                  resetValidation({ key: 'name' });
                }}
                type='username'
                placeholder=""
                minLength={3}
                maxLength={20}
                required={true}
                disabled={loading}
              />
              {
                (error || !form.validations.name.isValid) && (
                  <span className='font-color-error'>
                    {error || form.validations.name.message}
                  </span>
                )
              }
            </label>
          </div>
          <Button
            type='submit'
            disabled={loading || !form.validations.name.isValid}
            loading={loading}
          >
            {
              t('account_save')
            }
          </Button>
        </form>
        </ModalBody>
      </Modal>
      <Modal
        visible={modalLastname.show}
      >
        <ModalTitle
          onClose={() => modalLastname.handleClose()}
        >
          <h1>
            {
              t('account_lastname')
            }
          </h1>
        </ModalTitle>
        <ModalBody>
        <form
          id='lastname'
          onSubmit={(e) => handleSubmitForm({
            event: e,
            id: 'lastname',
            type: 'lastname',
            onSuccess: async () => {
              changeFormValue({
                key: 'lastname',
                value: ''
              });

              modalLastname.handleClose();

              await refetch({
                token,
                email,
                locale: i18n.resolvedLanguage
              });
            },
            onError: (message) => {
              setError(message);
            }
          })}
        >
          <div className={'form-group mb-35'}>
            <label className='form__label'>
              <span className='form__label__text'>
                {
                  t('account_lastname')
                }
              </span>
              <input
                onInput={(e) => changeFormValue({ key: 'lastname', value: e.target.value })}
                value={form.lastname}
                autoFocus
                onBlur={(event) => {
                  setError('');
                  resetValidation({ key: 'lastname' });
                }}
                onFocus={(event) => {
                  setError('');
                  resetValidation({ key: 'lastname' });
                }}
                className={clsx('form-control', !form.validations.lastname.isValid && 'is-invalid')}
                type='lastname'
                id='lastname'
                placeholder=""
                minLength={3}
                maxLength={30}
                required={true}
                disabled={loading}
              />
              {
                (error || !form.validations.lastname.isValid) && (
                  <span className='nunito-16 font-color-error'>
                    {error || form.validations.lastname.message}
                  </span>
                )
              }
            </label>
          </div>
          <Button
            type='submit'
            form='lastname'
            disabled={loading || !form.validations.lastname.isValid}
            loading={loading}
          >
            {
              t('account_save')
            }
          </Button>
        </form>
        </ModalBody>
      </Modal>
      <Modal
        visible={modalPosition.show}
      >
        <ModalTitle
          onClose={() => modalPosition.handleClose()}
        >
          <h1>
            {
              t('account_position')
            }
          </h1>
        </ModalTitle>
        <ModalBody>
        <form
          id='position'
          onSubmit={(e) => handleSubmitForm({
            event: e,
            id: 'position',
            type: 'position',
            onSuccess: async () => {
              changeFormValue({
                key: 'position',
                value: ''
              });

              modalPosition.handleClose();

              await refetch({
                token,
                email,
                locale: i18n.resolvedLanguage
              });
            },
            onError: (message) => {
              setError(message);
            }
          })}
        >
          <div className={'form-group mb-35'}>
            <label className='form__label'>
              <span className='form__label__text'>
                {
                  t('account_position')
                }
              </span>
              <input
                className={clsx('form-control', !form.validations.position.isValid && 'is-invalid')}
                onInput={(e) => changeFormValue({ key: 'position', value: e.target.value })}
                value={form.position}
                autoFocus
                onBlur={(event) => {
                  setError('');
                  resetValidation({ key: 'position' });
                }}
                onFocus={(event) => {
                  setError('');
                  resetValidation({ key: 'position' });
                }}
                type='position'
                id='position'
                placeholder=""
                minLength={3}
                maxLength={25}
                required={true}
                disabled={loading}
              />
              {
                (error || !form.validations.position.isValid) && (
                  <span className='nunito-16 font-color-error'>
                    {error || form.validations.position.message}
                  </span>
                )
              }
            </label>
          </div>
          <Button
            type='submit'
            form='position'
            disabled={loading || !form.validations.position.isValid}
            loading={loading}
          >
            {
              t('account_save')
            }
          </Button>
        </form>
        </ModalBody>
      </Modal>
      <Modal
        visible={modalEmail.show}
      >
        <ModalTitle
          onClose={() => modalEmail.handleClose()}
        >
          <h1>
            {
              t('account_email')
            }
          </h1>
        </ModalTitle>
        <ModalBody>
        <form
          id='email_form'
          onSubmit={(e) => handleSubmitForm({
            event: e,
            id: 'email',
            type: 'email',
            onSuccess: async (result) => {
              changeFormValue({
                key: 'email',
                value: ''
              });

              modalEmail.handleClose();

              // console.log('email submit', result);

              loadMember({
                email: result?.newEmail,
                token: result?.token.token,
                id: member.id
              });
            },
            onError: (message) => {
              setError(message);
            }
          })}
        >
          <div className={'form-group mb-35'}>
            <label className='form__label'>
              <span className='form__label__text'>
                {
                  t('account_email')
                }
              </span>
              <input
                className={clsx('form-control', !form.validations.email.isValid && 'is-invalid')}
                onInput={(e) => changeFormValue({ key: 'email', value: e.target.value })}
                value={form.email}
                onBlur={(event) => {
                  setError('');
                }}
                type='email'
                id='email'
                form='email_form'
                placeholder={t('account_email_placeholder')}
                minLength={5}
                maxLength={40}
                required={true}
                disabled={loading}
              />
              {
                (error || !form.validations.email.isValid) && (
                  <Delayed delay={1000}>
                    <span className='font-color-error'>
                      {error || form.validations.email.message}
                    </span>
                  </Delayed>
                )
              }
            </label>
          </div>
          <Button
            type='submit'
            form='email_form'
            disabled={loading || !form.validations.email.isValid}
            loading={loading}
          >
            {
              t('account_save')
            }
          </Button>
        </form>
        </ModalBody>
      </Modal>
      <Modal
        visible={modalAvatar.show}
      >
        <ModalTitle
          onClose={() => modalAvatar.handleClose()}
        >
          <h1>
            {
              t('account_avatar')
            }
          </h1>
        </ModalTitle>
        <ModalBody>
        {
          avatar &&
          (<div className='avatar-image mb-40'>
            <img src={avatar} />
          </div>)
        }
        {
          error && (
            <span className='font-color-error mb-35'>
              {error}
            </span>
          )
        }
        <form
          id='avatar-upload'
          onSubmit={(e) => {
            handleSubmitForm({
              event: e,
              avatar,
              id: 'avatar-upload',
              type: 'avatar',
              onSuccess: async (result) => {
                modalAvatar.handleClose();

                renderAvatars();

                await refetch({
                  token,
                  email,
                  locale: i18n.resolvedLanguage
                });
              },
              onError: (message) => {
                setError(message);
              }
            });
          }
          }
        >
          <div className={clsx('file-upload__droparea mb-35', draggingFile && 'file-upload__droparea--dragging')}>
              {
                t('account_avatar_upload')
              }
              <label>
                <input
                  accept="image/png"
                  form="avatar-upload"
                  type="file"
                  onInput={async (e) => {
                    e.preventDefault();

                    const fileSelected = e.target.files[0];

                    setDraggingFile(false);

                    if (fileSelected.size > 2097152) {
                      setError('Por favor, selecciona una imagen de menos de 2MB');
                      setAvatar('');
                      return;
                    }

                    try {
                      const base64 = await file2Base64(fileSelected);

                      setAvatar(base64);
                      setError('');
                    } catch (error) {
                      setAvatar(null);
                      setError('error al cargar la imagen');
                    }
                  }}
                  onDragOver={(e) => {
                    e.stopPropagation();
                    setDraggingFile(true);
                  }}
                  onDragLeave={(e) => {
                    e.stopPropagation();
                    setDraggingFile(false);
                  }}
                  id="avatar_input"
                  className='file-upload__fileinput'
                >
                </input>
                <a>{
                  t('account_avatar_upload_button')
                }</a>
              </label>
            <span>.png | {
              t('account_avatar_upload_size')
            } 2mb</span>
          </div>
          <Button
            type='submit'
            form='avatar-upload'
            disabled={loading || !avatar}
            loading={loading}
          >
            {
              t('account_save')
            }
          </Button>
        </form>
        </ModalBody>
      </Modal>
      <Modal
        visible={modalPassword.show}
      >
        <ModalTitle
          onClose={() => modalPassword.handleClose()}
        >
          <h1>
            {
              t('account_change_password')
            }
          </h1>
        </ModalTitle>
        <ModalBody>
        <form
          id="password_form"
          onSubmit={(e) => handleSubmitForm({
            event: e,
            id: 'password',
            type: 'password',
            onSuccess: async () => {
              changeFormValue({
                key: 'password',
                value: ''
              });

              changeFormValue({
                key: 'new_password',
                value: ''
              });

              changeFormValue({
                key: 'new_password2',
                value: ''
              });

              modalPassword.handleClose();

              await refetch({
                token,
                email,
                locale: i18n.resolvedLanguage
              });
            },
            onError: (message) => {
              setError(message);
            }
          })}

        >
          <div className={'form-group mb-35'}>
            <label className='form__label'>
              <span className={'form__label__text'}>
                {
                  t('account_current_password')
                }
              </span>
              <input
                onInput={(e) => changeFormValue({ key: 'password', value: e.target.value })}
                value={form.password}
                onBlur={(event) => {
                  setError('');
                  // resetValidation({ key: 'password' });
                }}
                onFocus={(event) => {
                  setError('');
                  // resetValidation({ key: 'password' });
                }}
                autoFocus
                type='password'
                id='password'
                form='password_form'
                placeholder="Xxxxxxx"
                minLength={8}
                className={clsx('form-control', !form.validations.password.isValid && 'is-invalid')}
                required={true}
                disabled={loading}
              />
              {
                (error || !form.validations.password.isValid) && (
                  <span className='font-color-error'>
                    {error || form.validations.password.message}
                  </span>
                )
              }
            </label>
          </div>
          <div className={'form-group mb-35'}>
            <label className='form__label'>
              <span className={'form__label__text'}>
                {
                  t('account_new_password')
                }
              </span>
              <input
                onInput={(e) => changeFormValue({ key: 'new_password', value: e.target.value })}
                value={form.new_password}
                onBlur={(event) => {
                  setError('');
                  // resetValidation({ key: 'new_password' });
                }}
                onFocus={(event) => {
                  setError('');
                  // resetValidation({ key: 'new_password' });
                }}
                type='password'
                id='new_password'
                form='password_form'
                placeholder="Xxxxxxx"
                minLength={8}
                className={clsx('form-control', !form.validations.new_password.isValid && 'is-invalid')}
                required={true}
                disabled={loading}
              />
              {
                (error || !form.validations.new_password.isValid) && (
                  <span className='nunito-16 font-color-error'>
                    {error || form.validations.new_password.message}
                  </span>
                )
              }
            </label>
          </div>
          <div className={'form-group mb-35'}>
            <label className='form__label'>
              <span className={'form__label__text'}>
                {
                  t('account_new_password_repeat')
                }
              </span>
              <input
                onInput={(e) => changeFormValue({ key: 'new_password2', value: e.target.value })}
                value={form.new_password2}
                onBlur={(event) => {
                  setError('');
                  // resetValidation({ key: 'new_password2' });
                }}
                onFocus={(event) => {
                  setError('');
                  // resetValidation({ key: 'new_password2' });
                }}
                type='password'
                id='newpassword2'
                form='password_form'
                placeholder="Xxxxxxx"
                minLength={8}
                className={clsx('form-control', !form.validations.new_password2.isValid && 'is-invalid')}
                required={true}
                disabled={loading}
              />
              {
                (error || !form.validations.new_password2.isValid) && (
                  <span className='font-color-error'>
                    {error || form.validations.new_password2.message}
                  </span>
                )
              }
            </label>
          </div>
          <Button
            type='submit'
            form='password_form'
            loading={loading}
            disabled={
              !form.validations.new_password.isValid ||
              !form.validations.new_password2.isValid ||
              !form.validations.password.isValid ||
              !form.new_password ||
              !form.new_password2 ||
              !form.password ||
              loading
            }
          >
            {
              t('account_save')
            }
          </Button>
        </form>
        </ModalBody>
      </Modal>
      <Modal
        visible={modalDelete.show}
      >
        <ModalTitle
          onClose={() => modalDelete.handleClose()}
        >
          <h1>
            {
              t('account_delete')
            }
          </h1>
        </ModalTitle>
        <ModalBody>
          <p>
            {
              t('account_delete_message')
            }
          </p>
          <div className='modal-buttons'>
            <Button className='form-button form-button--secondary'>
              {
                t('account_delete_button2')
              }
            </Button>
            <Button
              onClick={() => modalDelete.handleClose()}
            >
              {
                t('account_cancel')
              }
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <h1 className='page-container__title'>
        {
          t('account_title')
        }
      </h1>
      <h2 className='page-container__subtitle'>
        {
          t('account_intro')
        }
      </h2>
      <div className='account-data page-card'>
        <div className='account-data__row'>
          <div className='account-data__row-header'>
            {
              t('account_name_label')
            }
          </div>
          <div>{data?.name}</div>
          <div>
            <button
              onClick={() => {
                changeFormValue({
                  key: 'name',
                  value: data?.name
                });

                modalUsername.handleShow();
              }}
              type='button'
            >
              {
                t('account_edit')
              }
            </button>
          </div>
        </div>
        <div className='account-data__row'>
          <div className='account-data__row-header'>
            {
              t('account_lastname')
            }
          </div>
          <div>{data?.lastname}</div>
          <div>
            <button
              onClick={() => {
                changeFormValue({
                  key: 'lastname',
                  value: data?.lastname
                });

                modalLastname.handleShow();
              }}
              type='button'
            >
              {
                t('account_edit')
              }
            </button>
          </div>
        </div>
        <div className='account-data__row'>
          <div className='account-data__row-header'>
            {
              t('account_position')
            }
          </div>
          <div>{data?.position}</div>
          <div>
            <button
              onClick={() => {
                changeFormValue({
                  key: 'position',
                  value: data?.position
                });

                modalPosition.handleShow();
              }}
              type='button'
            >
              {
                t('account_edit')
              }
            </button>
          </div>
        </div>
        <div className='account-data__row'>
          <div className='account-data__row-header'>
            {
              t('account_email')
            }
          </div>
          <div>{email}</div>
          <div>
            <button
              onClick={() => {
                changeFormValue({
                  key: 'email',
                  value: email
                });

                modalEmail.handleShow();
              }}
              type='button'
            >
              {
                t('account_edit')
              }
            </button>
          </div>
        </div>
        <div className='account-data__row'>
          <div className='account-data__row-header'>
            {
              t('account_avatar')
            }
          </div>
          <div>
            <div className='account-data__avatar'>
              <img
                src={`${process.env.HOST_URL || ''}/locales/shared/avatars/${member?.id}.png?${member?.avatarKey}`}
                alt='avatar'
                onError={(e) => {
                  e.currentTarget.src = MemberSvg;
                  e.currentTarget.onerror = null;
                  e.currentTarget.classList.remove('png-loaded');
                  e.currentTarget.classList.add('svg-loaded');
                }}
                onLoad={(e) => {
                  if (e.currentTarget.src?.includes('png')) {
                    e.currentTarget.classList.remove('svg-loaded');
                    e.currentTarget.classList.add('png-loaded');
                  }
                }}
              />
            </div>
          </div>
          <div>
            <button
              onClick={() => {
                modalAvatar.handleShow();

                setAvatar(null);
                setDraggingFile(false);
                setError('');
              }}
              type='button'
            >
              {
                t('account_edit')
              }
            </button>
          </div>
        </div>
        <div className='account-data__row'>
          <div className='account-data__row-header'>
            {
              t('account_password')
            }
          </div>
          <div>xxxxx</div>
          <div>
            <button
              onClick={() => {
                modalPassword.handleShow();

                changeFormValue({
                  key: 'password',
                  value: ''
                });
                changeFormValue({
                  key: 'new_password',
                  value: ''
                });
                changeFormValue({
                  key: 'new_password2',
                  value: ''
                });

                resetValidation({
                  key: 'password'
                });

                resetValidation({
                  key: 'new_password'
                });

                resetValidation({
                  key: 'new_password2'
                });
              }}
              type='button'
            >
              {
                t('account_edit')
              }
            </button>
          </div>
        </div>
      </div>
      <h2 className='page-container__subtitle'>
        {
          t('account_pick_theme')
        }
      </h2>
      <div className='color-container page-card'>
        <div className='colors'>
        {
          colors.length > 0 && colors.map((color, i) => (
            <ColorPicker
              key={i}
              id={color.id}
              isSelected={color.id === selectedColor?.id}
              color={color.color2}
              handleClick={(id) => handleColorPicker(id)}
            />
          ))
        }
        </div>
        <div className='color-name'>{selectedColor.name}</div>
      </div>
      <h2 className='page-container__subtitle'>
        {
          t('account_delete')
        }
      </h2>
      <div className='account-delete page-card'>
        <div>
          {
            t('account_delete_result')
          }
        </div>
        <button
          onClick={() => modalDelete.handleShow()}
          type='button'
        >
          {
            t('account_delete')
          }
        </button>
      </div>
    </div>
  );
};
