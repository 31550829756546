video {
  width: 95%;
  margin-left: 2.5%;
  margin-bottom: 8px;
}


.projectContainer {
  position: relative;
  padding-top: calc(225px + 25px);
  background-color: #F4F6F7;
  padding-bottom: 0px;
}
.headerBackground {
  background-color: var(--color3);
  background-position: 50% 50%;
  background-size:contain;
  height: 225px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.saveButton {
  cursor: pointer;
  border: none;
  background: none;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 25px;
}

.button {
  cursor: pointer;
  border: none;
  padding: 20px;
  width: 100%;
  background: var(--color2) 0% 0% no-repeat padding-box;
  border-radius: 100px;
  font: normal normal 700 1.8rem/2.2rem Rubik;
  color: #FFFFFF;
  transition: all 0.7s ease;
}

.button:hover {
  background: var(--color1);
}

.button:disabled {
  background: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
}


.donationText {
  margin-top: 25px;
}

.tags {
  display: block;
  font: normal normal normal 1.6rem/2.1rem Lato;
  color: var(--color1);
  margin-bottom: 3rem;
}

.title {
  margin-bottom: 1.5rem;
}
.subTitle {
  margin-bottom: 2.2rem;
}

.text {
  display: block;
  font: normal normal normal 1.6rem/2.1rem Karla;
  color: #212529;
}

.boxGoalLabel {
  display: block;
  font: normal normal bold 2.4rem/1.8rem Rubik;
  color: #212529;
}

.boxGoalWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  text-transform: uppercase;
  width: 100%;
}

.boxGoalLabelLeft {
  display: block;
  font: normal normal bold 1.2rem/1.8rem Rubik;
  color: #2125297e;
}

.daysLeftLabel {
  display: block;
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
  font: italic normal bold 1.8rem/2.2rem Karla;
  color: var(--color2);
}

.odsWrapper {
  display: flex;
  flex-direction: row;
  gap: 7px;
  flex-wrap: wrap;
}

.progressBarWrapper {
  margin-top: 45px;
  width: 100%;
}

.recommendedProjects {
  background-color: var(--color3);
  padding: 25px;
  padding-bottom: 0px;
}

.headerDesktop {
  margin-bottom: 4rem;
}

.col1  {
  margin-bottom: 4rem;
}


@media screen and (min-width: 62rem) {
  .recommendedProjects {
    padding-left: 80px;
    padding-right: 80px;
  }
  .text {
    display: block;
    font: normal normal normal 1.8rem/2.3rem Karla;
    color: #212529;
  }
  .title {
    font-size: 4.2rem;
  }

  .subTitle {
    font-size: 3.6rem;
  }

  .projectContainer {
    padding-top: 35px;
  }

  .gridContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  .col1 {
    grid-column: 1 / 4;
    margin-bottom: 0px;
  }
  .col2 {
    padding-left: 30px;
    grid-column: 4 / 6;
  }
  
  .headerImageDesktop {
    height: 284px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    object-fit: cover;
  }

  .headerDesktop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .saveButtonDesktop {
    border: 2px solid var(--color2);
    height: 40px;
    border-radius: 100px;
    padding: 11px 18px;
    font: normal normal bold 1.4rem/1.7rem Karla;
    color: var(--color2);
    background: transparent;
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: 8px;
    transition: all 0.3s ease;
  }

  .saveButtonDesktop.saving {
    animation: loading 1.2s linear infinite;
  }

  @keyframes loading {
    0% {
      background: var(--color2);
      color: white;
    }
    50% {
      background: transparent;
      color: var(--color2);
    }
    100% {
      background: var(--color2);
      color: white;
    }
  }



  .saveButtonDesktop:hover {
    background: var(--color2);
    color: white;
  }

  .textSummary {
    max-width: 425px;
  }
}

@media screen and (min-width: 82rem) {
  .recommendedProjects {
    padding-left: 132px;
    padding-right: 132px;
  }
  .headerImageDesktop {
    height: 390px;
  }
}

@media screen and (min-width: 100rem) {
  .recommendedProjects {
    padding-left: 275px;
    padding-right: 275px;
  }
}