import {
  getColors,
  saveMemberColor,
  getMemberColor
} from './colors';

import {
  register,
  login,
  recoverPassword,
  validateAuthToken
} from './auth';

import {
  subToNewsLetter
} from './newsletter';

import {
  getHomeProjects,
  getProjectsCategories,
  getProjectsFiltered,
  saveProject,
  unsaveProject,
  getProjectInfo,
  getSavedProjects,
  uploadNewProject
} from './crowdfunding';

import {
  getProfile,
  updateProfileInfo,
  updateEmail,
  uploadAvatar,
  updatePassword
} from './profile';

import {
  getDonationsHistory
} from './donations';

import {
  updateLanguage
} from './language';

import {
  donatePaypal,
  processPaypalPayment
} from './payments/paypal';

const api = {
  getColors,
  register,
  login,
  recoverPassword,
  validateAuthToken,
  saveMemberColor,
  getMemberColor,
  subToNewsLetter,
  getHomeProjects,
  getProjectsCategories,
  getProjectsFiltered,
  saveProject,
  unsaveProject,
  uploadNewProject,
  getProjectInfo,
  getProfile,
  getSavedProjects,
  updateProfileInfo,
  getDonationsHistory,
  updateEmail,
  uploadAvatar,
  updatePassword,
  updateLanguage,
  donatePaypal,
  processPaypalPayment
};

export default api;
