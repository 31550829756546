import clsx from 'clsx';

import * as styles from './BoxPoints.module.css';

const BoxPoints = ({
  projects,
  projectsViews,
  onClickPoint
}) => {
  return (
    <div className={styles.boxPoints}>
      {
        projects.map((project, i) => (
          <button
            key={i}
            onClick={() => onClickPoint(i)}
            className={clsx(styles.boxPoint, projectsViews[i].inView && styles.active)}
          />
        ))
      }
    </div>
  );
};

export default BoxPoints;
