/*
Ruta: /{_locale}/donations/donate/paypal
Método: POST
Requiere:
  el token en header Authorization
  el locale en la url

Parámetros de body:
  email: el email del usuario
  crowdfundingid: (int)_ la id del crowdfunding al que se va a donar
  amount: (float) la cantidad que se va a donar, con 2 decimales

Puede devolver:
  Body mal formado: status error, message, 400
  Error de autenticación: status error, message, 401
  No autorizado: status error, message, 401
  Error en parámetros: status error, message, 400
  Token no válido: status error, code: INVALID_TOKEN, 400
  Error de conexión con paypal: status error, code: CONNECTION_ERROR, 400
  Petición correcta: status success, response, 200
*/
const donatePaypal = async ({
  locale = 'es',
  crowdfundingid,
  amount,
  email,
  token
}) => {
  // console.log(amount, ' donate...');

  const response = await fetch(`https://dev1.youdrop.org/${locale}/donations/donate/paypal`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      email,
      crowdfunding_id: parseInt(crowdfundingid),
      amount
    })
  });

  const result = await response.json();

  if (response.status !== 200) {
    const error = new Error('Failed to donate via paypal');
    error.status = response.status;
    throw error;
  }

  return { ...result, statusNumber: response.status };
};

/*
Ruta: /{_locale}/donations/process/paypal
Método: POST
Requiere:
  el token en header Authorization
  el locale en la url

Parámetros de body:
  email: el email del usuario
  crowdfundingid: (int)_ la id del crowdfunding al que se va a donar
  paymentintent: (string)_ el hash del payment intent que ha devuelto Paypal
  canreasing: (bool)_ si la donación se puede reasignar a otros proyectos si este no se completa

Puede devolver:
  Body mal formado: status error, message, 400
  Error de autenticación: status error, message, 401
  No autorizado: status error, message, 401
  Error en parámetros: status error, message, 400
  Token no válido: status error, code: INVALID_TOKEN, 400
  Error de paypal: status error, code (paypal tiene varios). message (descripción del error), 400
  Error desconocido: status error, code: UNKNOWN_ERROR, 400
  Petición correcta: status success, response, 200
*/
const processPaypalPayment = async ({
  locale = 'es',
  crowdfundingid,
  paymentintent,
  canreasing,
  email,
  token
}) => {
  const response = await fetch(`https://dev1.youdrop.org/${locale}/donations/process/paypal`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      email,
      crowdfunding_id: parseInt(crowdfundingid),
      payment_intent: paymentintent,
      can_reassign: canreasing
    })
  });

  const result = await response.json();

  if (response.status !== 200) {
    const error = new Error('Failed to process paypal payment');
    error.status = response.status;
    throw error;
  }

  return { ...result, statusNumber: response.status };
};

export {
  donatePaypal,
  processPaypalPayment
};
