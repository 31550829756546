import { useContext, useState } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import './savedcrowdfundings.css';

import { AUTH_CONTEXT } from '/src/context/AuthProvider';
import { NavbarContext } from '/src/components/Navbar';
import { COLOR_CONTEXT } from '/src/context/ColorProvider';

import ProjectCard from '/src/components/ProjectCard/ProjectCard';
import Modal, { ModalBody, ModalTitle } from '/src/components/Modal';
import { Button } from '/src/components/Button';

import NoProjectsSVG from '/src/assets/icons/ilustracio_no_notificacions.svg';

import { useShow } from '/src/services/hooks/useShow';

import api from '/src/services/api';

export const SavedCrowdfundings = () => {
  const { member } = useContext(AUTH_CONTEXT);
  const { selectedColor } = useContext(COLOR_CONTEXT);
  const { findCategoryName, categories } = useContext(NavbarContext);

  const [savingCards, setSavingCards] = useState([]);
  const [project2remove, setProject2Remove] = useState([]);

  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const modalRemove = useShow(false);

  const { token, email } = member;

  const {
    data,
    isLoading,
    isError,
    failureCount,
    refetch
  } = useQuery(
    ['saved_crowdfundings', {
      token,
      email
    }],
    () => api.getSavedProjects({
      token,
      email
    }),
    {
      refetchOnWindowFocus: true,
      // this is tricky but it gonna make the query only be executed when we have token and email
      enabled: (!!token && !!email)
    });

  const handleRemoveProject = async (id) => {
    setSavingCards([...savingCards, id]);

    try {
      await api.unsaveProject({
        token,
        email,
        id,
        locale: i18n.language
      });
    } catch (e) {
      console.error(e);
    } finally {
      setSavingCards(savingCards.filter((card) => card !== id));
      await refetch({
        token,
        email
      });
      modalRemove.handleClose();
    }
  };

  const handleCardClick = (id) => {
    navigate(`/project/${id}`);
  };

  const handleOpenRemoveModal = (id) => {
    setProject2Remove(id);
    modalRemove.handleShow();
  };

  if (isLoading || (!token && !email)) {
    return (
      <div className='loading-container'>
        <div className='lds-dual-ring'/>
      </div>
    );
  }

  if (isError) {
    if (failureCount > 3) {
      return <Navigate
        to='/500'
        state={{ isError: true }}
      />;
    }
    return null;
  };

  return (
    <div className='page-container'>
      <Modal
        visible={modalRemove.show}
      >
        <ModalTitle
          onClose={() => modalRemove.handleClose()}
        >
          <h1>
            {
              t('saved_projects_remove')
            }
          </h1>
        </ModalTitle>
        <ModalBody>
          <p className='account-delete__message'>
            {
              t('saved_projects_warning')
            }
          </p>
          <div className='modal-buttons account-delete__buttons'>
            <Button
              className='form-button form-button--secondary'
              onClick={() => handleRemoveProject(project2remove)}
            >
              {
                t('saved_projects_delete')
              }
            </Button>
            <Button
              onClick={() => modalRemove.handleClose()}
            >
              {
                t('saved_projects_cancel')
              }
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <h1 className='page-container__title'>
        {
          t('account_saved_projects_title')
        }
      </h1>
      <div className='saved-projects-wrapper'>
        {
          data?.saved_crowdfundings?.map((project) => {
            return (
              <ProjectCard
                key={project.id}
                id={project.id}
                title={project.name}
                description={project.summary}
                goal={project.goal}
                current={project.total}
                endDate={project.finishes_at}
                saved={true}
                disabled={savingCards.includes(project.id)}
                onSave={() => handleOpenRemoveModal(project.id)}
                onClick={() => handleCardClick(project.id)}
                saveColor={selectedColor?.color1}
                tag={project.categories.length >= 1 && findCategoryName(categories, parseInt(project.categories[0]), i18n)}
              />
            );
          })
        }
        {
          data?.saved_crowdfundings?.length === 0 && (
            <div className='savedcrowfundings-wrapper'>
              <img src={NoProjectsSVG} />
              <span className='savedcrowfundings-wrapper__text'>
                {
                  t('saved_projects_notfound')
                }
              </span>
            </div>
          )
        }
      </div>
    </div>
  );
};
