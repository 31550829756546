/* eslint-disable camelcase */
import { createContext, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import useMember from '../services/hooks/useMember';
import api from '../services/api';

import { COLOR_CONTEXT } from './ColorProvider';
import { useTranslation } from 'react-i18next';

export const AUTH_CONTEXT = createContext();

const token = localStorage.getItem('token') || '';
const email = localStorage.getItem('email') || '';

const AUTH_CONTEXT_PROVIDER = (props) => {
  const {
    member,
    loadMember,
    unloadMember,
    renderAvatars,
    checkLoggedIn
  } = useMember(token, email);

  const { i18n } = useTranslation();

  const { handleSelectPallete, colors } = useContext(COLOR_CONTEXT);

  useEffect(() => {
    const validateAutoLogin = async () => {
      const email = localStorage.getItem('email');
      const token = localStorage.getItem('token');

      // If the user tries to navigate to a private route this makes them redirect to the login page
      if (!email || !token) {
        handleLogout('No token or not email found!');
        return;
      }

      const result = await api.validateAuthToken({ email, token });

      if (result.statusNumber !== 200) {
        handleLogout('Token is not valid!');
      }

      i18n.changeLanguage(result.language);

      loadMember({ email, token, id: result.id });

      console.info('member sucessfully loaded', result);

      if (result.colorset !== null) {
        handleSelectPallete(result.colorset);
      }
      // Just in case the user lost color_id in the localStorage
      localStorage.setItem('color_id', result.colorset);
    };
    // If colors are not loaded we cannot auto login
    // because we cannot set a color that is not loaded yet
    // * Note: this is gonna be solved when we have default colors
    if (colors) {
      validateAutoLogin();
    }
  }, [colors]);

  const handleLogout = (message = 'normal logut') => {
    if (message) {
      console.info(message);
    }

    localStorage.removeItem('email');
    localStorage.removeItem('token');
    localStorage.removeItem('color_id');

    // This gonna make private routes navigate to the login page
    unloadMember();
  };

  return (
    <AUTH_CONTEXT.Provider
      value={{
        member,
        loadMember,
        unloadMember,
        handleLogout,
        renderAvatars,
        loggedIn: checkLoggedIn()
      }}>
      {props.children}
    </AUTH_CONTEXT.Provider>
  );
};

AUTH_CONTEXT_PROVIDER.propTypes = {
  children: PropTypes.node.isRequired
};

export default AUTH_CONTEXT_PROVIDER;
