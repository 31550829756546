/* eslint-disable camelcase */

const getHomeProjects = async ({ token, email, recommended = true, language = 'es' }) => {
  const response = await fetch(`https://dev1.youdrop.org/${language}/home`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      email,
      recommended
    })
  });

  if (response.status !== 200) {
    throw new Error(await response.json());
  }

  return await response.json();
};

const getProjectsCategories = async ({ token, email }) => {
  const response = await fetch('https://dev1.youdrop.org/categories/list', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      email
    })
  });

  if (response.status !== 200) {
    throw new Error(await response.json());
  }

  return await response.json();
};

const getProjectsFiltered = async ({
  token,
  email,
  category,
  filter,
  goal_min,
  goal_max,
  percentage_min,
  percentage_max,
  order,
  language = 'es',
  page = 1
}) => {
  const optionalParams = {
    filter,
    goal_min,
    goal_max,
    percentage_min,
    percentage_max,
    order,
    category
  };

  Object.keys(optionalParams).forEach((key) => (
    optionalParams[key] === null ||
    optionalParams[key] === undefined ||
    optionalParams[key] === '') && delete optionalParams[key]);

  const response = await fetch(`https://dev1.youdrop.org/${language}/crowdfundings/list/${page}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      email,
      ...optionalParams
    })
  });

  if (response.status !== 200) {
    throw new Error(await response.json());
  }

  return await response.json();
};

const saveProject = async ({
  token,
  email,
  id,
  locale = 'es'
}) => {
  const response = await fetch(`https://dev1.youdrop.org/${locale}/savedcrowfundings/save/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      email
    })
  });

  if (response.status !== 200) {
    throw new Error(await response.json());
  }

  return await response.json();
};

const unsaveProject = async ({
  token,
  email,
  id,
  locale = 'es'
}) => {
  const response = await fetch(`https://dev1.youdrop.org/${locale}/savedcrowfundings/delete/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      email
    })
  });

  if (response.status !== 200) {
    throw new Error(await response.json());
  }

  return await response.json();
};

const getProjectInfo = async ({
  token,
  email,
  recommended = true,
  id,
  locale = 'es'
}) => {
  const response = await fetch(`https://dev1.youdrop.org/${locale}/crowdfundings/info/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      email,
      recommended
    })
  });

  if (response.status !== 200) {
    throw new Error(await response.json());
  }

  return await response.json();
};

const getSavedProjects = async ({
  token,
  email,
  locale = 'es'
}) => {
  const response = await fetch(`https://dev1.youdrop.org/${locale}/savedcrowfundings/list`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify({
      email
    })
  });

  if (response.status !== 200) {
    throw new Error(await response.json());
  }

  return await response.json();
};

const uploadNewProject = async ({
  token,
  email,
  locale = 'es',
  goal,
  image,
  video,
  zip,
  formValues
}) => {
  const body = {
    email,
    goal,
    image,
    video,
    translations: JSON.stringify({
      [locale]: {
        title: formValues.title,
        description: formValues.description,
        contact: formValues.contact
      }
    })
  };

  const data = new FormData();

  for (const key in body) {
    if (body[key] !== null && body[key] !== undefined && body[key] !== '') {
      data.append(key, body[key]);
    }
  }

  if (zip) {
    data.append('zip', new Blob([zip], { type: 'application/zip' }), 'zipfile.zip');
  }

  const response = await fetch(`https://dev1.youdrop.org/${locale}/crowdfundings/save/new`, {
    method: 'POST',
    headers: {
      Authorization: token
    },
    body: data
  });

  // custom error for internal server error, inject the error status
  if (response.status === 500) {
    const error = new Error('Internal server error');
    error.status = 500;
    throw error;
  } else if (response.status !== 201) {
    const responseJSON = await response.json();
    throw new Error(responseJSON?.message || 'Unrecognized error');
  }

  return await response.json();
};

export {
  getHomeProjects,
  getProjectsCategories,
  getProjectsFiltered,
  saveProject,
  unsaveProject,
  getProjectInfo,
  getSavedProjects,
  uploadNewProject
};
