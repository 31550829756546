import svg404 from '../../assets/images/errors/404.svg';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Link, Navigate } from 'react-router-dom';

import * as styles from './errors.module.css';

export const Page404 = ({ notFound }) => {
  const { t } = useTranslation();

  // If you try to access directly to 404 page, it will redirect to home
  if (!notFound) {
    return <Navigate to="/" />;
  }

  return (
    <div
      className={styles.container}
    >
      <div className={styles.innerWrapper}>
        <div className='youdrop-logo youdrop-logo--white youdrop-logo--top youdrop-logo--long' alt="YouDrop Logo" />
        <img className={styles.errorImage} type="image/svg+xml" src={svg404}/>
        <h1 className={styles.title}>
          {
            t('error404_title')
          }
        </h1>
        <span className={styles.description}>
          {
            t('error404_text')
          }
        </span>
        <Link to="/">
          <button className={styles.button}>
            {
              t('error404_go_home')
            }
          </button>
        </Link>
      </div>
    </div>
  );
};

Page404.propTypes = {
  notFound: PropTypes.bool
};
