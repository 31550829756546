.wrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  overflow-x: auto;
  padding-right: 25px;
}

@media screen and (min-width: 62rem) {
  .wrapper {
    flex-direction: row;
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: hidden;
    flex-wrap: wrap;
    width: 100%;
    height: 440px;
    gap: 15px;
  }
}