import { useTranslation, Trans } from 'react-i18next';

import clsx from 'clsx';
import { Link } from 'react-router-dom';
import './style.css';

export const Footer = ({ color, border }) => {
  const { t } = useTranslation();

  return (
    <div className={
      clsx(
        'footer',
        ((color === 'blue' && 'footer--blue') || (color && 'footer--color')),
        (border && 'footer--border')
      )

    }>
      <span>© Copyright 2023 - Fundació Fluidra</span>
      <div className='footer-separator'>
        <Link to={'/legal_notice'}>{t('legal_warning')}</Link>
        <Link to={'/privacy_policy'}>{t('privacy_policy')}</Link>
      </div>
    </div>
  );
};