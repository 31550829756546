import { useState } from 'react';

const defaultFilters = {
  filter: '',
  goal_min: 0,
  goal_max: 0,
  percentage_min: 0,
  percentage_max: 0,
  category: 0
};

const useFilters = (initFilters = defaultFilters) => {
  const [filters, setFilters] = useState();

  const changeFilterCategory = (id) => {
    setFilters({ ...filters, category: id });
  };

  const changeProjectsFilter = (filter) => {
    setFilters({ ...filters, filter });
  };

  const changeGoal = (min, max) => {
    setFilters({ ...filters, goal_min: min, goal_max: max });
  };

  const changePercentage = (min, max) => {
    setFilters({ ...filters, percentage_min: min, percentage_max: max });
  };

  const resetFilters = () => {
    setFilters({});
  };

  return {
    filters,
    changeFilterCategory,
    changeProjectsFilter,
    changeGoal,
    changePercentage,
    resetFilters
  };
};

export default useFilters;
