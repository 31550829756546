
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import ProjectCard from '/src/components/ProjectCard/ProjectCard';

import * as styles from './Categories.module.css';

import { COLOR_CONTEXT } from '/src/context/ColorProvider';
import { AUTH_CONTEXT } from '/src/context/AuthProvider';

import { NavbarContext } from '/src/components/Navbar';
import { useQuery } from 'react-query';

import { Navigate, useNavigate } from 'react-router-dom';

import api from '/src/services/api';

import NoProjectsSVG from '../../../assets/icons/ilustracio_no_notificacions.svg';

const findCategoryName = (categories, id, i18n) => {
  return categories?.find(category => id === category.id)?.name[i18n.resolvedLanguage || 'ca'] || 'Ninguna';
};

const Categories = (props) => {
  const { selectedColor } = useContext(COLOR_CONTEXT);

  const { member } = useContext(AUTH_CONTEXT);
  const { filters, categories } = useContext(NavbarContext);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [savingCards, setSavingCards] = useState([]);

  const { token, email } = member;

  const {
    data,
    isLoading,
    isError,
    failureCount,
    refetch
  } = useQuery(
    ['projects_filtered', {
      token,
      email,
      category: filters?.category,
      filter: filters?.filter,
      goal_min: filters?.goal_min,
      goal_max: filters?.goal_max,
      percentage_min: filters?.percentage_min,
      percentage_max: filters?.percentage_max
    }],
    () => api.getProjectsFiltered({
      token,
      email,
      category: filters?.category,
      filter: filters?.filter,
      goal_min: filters?.goal_min,
      goal_max: filters?.goal_max,
      percentage_min: filters?.percentage_min,
      percentage_max: filters?.percentage_max
    }),
    {
      refetchOnWindowFocus: true,
      // this is tricky but it gonna make the query only be executed when we have token and email
      enabled: (!!token && !!email)
    });

  // console.log(data);

  // console.log(categories);

  const handleSaveProject = async (id, saved) => {
    setSavingCards([...savingCards, id]);

    try {
      if (saved) {
        await api.unsaveProject({ token, email, id });
      } else {
        await api.saveProject({ token, email, id });
      }
    } catch (error) {
      console.error(error);
    } finally {
      refetch({
        token,
        email,
        category: filters?.category,
        filter: filters?.filter,
        goal_min: filters?.goal_min,
        goal_max: filters?.goal_max,
        percentage_min: filters?.percentage_min,
        percentage_max: filters?.percentage_max
      });

      setSavingCards(savingCards.filter(cardId => cardId !== id));
    }
  };

  if (isLoading || (!token && !email)) {
    return (
      <div className='loading-container'>
        <div className='lds-dual-ring'/>
      </div>
    );
  }

  if (isError) {
    if (failureCount > 3) {
      return <Navigate
        to='/500'
        state={{ isError: true }}
      />;
    }
    return null;
  };

  if (data?.crowdfundings?.length === 0) {
    return (
      <div className={clsx('container-app', styles.noProjectsWrapper)}>
        <h1 className={styles.categoryTitle}>
          {
            t('categories_notfound')
          }
        </h1>
        <img src={NoProjectsSVG} />
      </div>
    );
  }

  return (
    <div className='container-app'>
      {
        (filters?.category && (
          <h1 className={styles.categoryTitle}>
            {
              (categories?.find(category => filters?.category === category.id)?.name[i18n.resolvedLanguage || 'ca']) || t('categories_all')
            }
          </h1>
        )) || null
      }
      <div className={styles.projectsContainer}>
        {
          data?.crowdfundings?.map(project => (
            <ProjectCard
              key={project.id}
              id={project.id}
              title={project.title}
              description={project.description}
              goal={project.goal}
              current={project.total}
              endDate={project.finishes_at}
              saved={project.saved}
              onClick={() => navigate(`/project/${project.id}`)}
              onSave={() => handleSaveProject(project.id, project.saved)}
              saveColor={selectedColor?.color1}
              disabled={savingCards.includes(project.id)}
              tag={project.categories.length >= 1 && findCategoryName(categories, parseInt(project.categories[0]), i18n)}
            />
          ))
        }
      </div>
    </div>
  );
};

export {
  Categories
};
