import { useContext } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { AUTH_CONTEXT } from '../../context/AuthProvider';
import { COLOR_CONTEXT } from '../../context/ColorProvider';
import { Navbar } from '../Navbar';
import { Footer } from '/src/components/Footer';
import PropTypes from 'prop-types';

const PrivateRoute = ({ children }) => {
  const { member, handleLogout } = useContext(AUTH_CONTEXT);
  const { selectedColor } = useContext(COLOR_CONTEXT);

  const navigate = useNavigate();

  // If the user is not logged in, redirect to the login page
  if (member.token === null || member.email === null) {
    return <Navigate to="/" replace />;
  }

  return (
    <Navbar
      onLogout={handleLogout}
      onNavigate={navigate}
      color={selectedColor?.color2 }>
      {children || <Outlet/>}
      <Footer
        border={true}
        color={true}
      >
      </Footer>
    </Navbar>
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node
};

export { PrivateRoute };
