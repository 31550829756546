import React from 'react';
import * as styles from './CardsWrapper.module.css';

import PropTypes from 'prop-types';

import { MediaQuery } from '/src/components/helpers/MediaQuery';

const MAX_CARDS_ROW = 4;

const CardsWrapper = ({ children }) => {
  const leftCount = MAX_CARDS_ROW - children.length;

  return (
    <section className='mb-35 home-cards-wrapper'>
      <div
        className={styles.wrapper}
      >
        {
          children
        }
        <MediaQuery query='(min-width: 62rem)'>
          {
            leftCount > 0 && (
              Array.from({ length: leftCount }).map((_, index) => (
                <div
                  key={index}
                  style={{
                    minWidth: '290px'
                  }}
                >
                </div>
              ))
            )
          }
        </MediaQuery>
      </div>
    </section>
  );
};

CardsWrapper.defaultProps = {
  count: 0,
  children: null
};

CardsWrapper.propTypes = {
  count: PropTypes.number,
  children: PropTypes.node.isRequired
};

export default CardsWrapper;
