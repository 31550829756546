import { useRef, useState } from 'react';
import { animated, useTransition } from '@react-spring/web';

import { useTranslation, Trans } from 'react-i18next';

import './landing.css';

import { Link, useNavigate } from 'react-router-dom';

import { useInView } from 'react-intersection-observer';
import clsx from 'clsx';
import { useShow } from '../../../services/hooks/useShow';

import Modal from '/src/components/Modal';
import { Footer } from '/src/components/Footer';

import dropCharImage from '/src/assets/images/drop_char.png';
// import platformImage from '../assets/images/mockup_plataforma.png';

import platformImageCA from '/src/assets/images/mockup_cat.png';
import platformImageEN from '/src/assets/images/mockup_eng.png';
import platformImageES from '/src/assets/images/mockup_es.png';
import platformImageFR from '/src/assets/images/mockup_fr.png';

import heartSVG from '/src/assets/icons/heart.svg';
import squareSVG from '/src/assets/icons/square.svg';
import frameVideoImage from '/src/assets/images/frame_video.jpg';
import presidentImage from '/src/assets/images/joan_planes.jpg';

import playIconSVG from '/src/assets/icons/play.svg';

import registerIconSVG from '/src/assets/icons/icono_registro.svg';
import shareIconSVG from '/src/assets/icons/icono_compartir.svg';
import giveIconSVG from '/src/assets/icons/icono_donar.svg';
import sendSVG from '/src/assets/icons/icono_envisr_smartphone.svg';

import blueHeartSVG from '/src/assets/icons/icono_corazon.svg';
import footerPeopleSVG from '/src/assets/collection/people.svg';

import { useOutsideCallback } from '/src/services/hooks/useOutsideCallback';

import Animation from '/src/animations/Animation';

import api from '/src/services/api';
import Navbar from './components/navbar';


const videoMap = {
  ca: 'https://player.vimeo.com/video/768957774?h=ebc93795d5',
  fr: 'https://player.vimeo.com/video/768963793?h=b210ddd751',
  es: 'https://player.vimeo.com/video/768961443?h=ccadfc1fb4',
  en: 'https://player.vimeo.com/video/768959884?h=07dd2780c6'
};

const resolvePlatformImage = (lang = 'en') => {
  switch (lang) {
    case 'ca':
      return platformImageCA;
    case 'en':
      return platformImageEN;
    case 'es':
      return platformImageES;
    case 'fr':
      return platformImageFR;
    default:
      return platformImageEN;
  }
};

const LandingPage = () => {
  const { show, handleShow, handleClose } = useShow();

  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailSubscribed, setEmailSubscribed] = useState(false);

  const footerView = useInView({
    treshold: 0.5
  });

  const videoView = useInView({
    treshold: 0.7
  });

  const navigate = useNavigate();

  // const transitions = useTransition(mockupView.inView, {
  //   from: { opacity: 0, transform: 'translateY(200px)' },
  //   enter: { opacity: 1, transform: 'translateY(0px)' },
  //   leave: { opacity: 0, transform: 'translateY(200px)' }
  // });

  const transitionsTextTitleVideo = useTransition(videoView.inView, {
    from: { opacity: 0, transform: 'translateX(-200px)' },
    enter: { opacity: 1, transform: 'translateX(0px)' },
    leave: { opacity: 0, transform: 'translateX(-200px)' },
    trail: 700
  });

  const transitionsTextVideo = useTransition(videoView.inView, {
    from: { opacity: 0, transform: 'translateX(-200px)' },
    enter: { opacity: 1, transform: 'translateX(0px)' },
    leave: { opacity: 0, transform: 'translateX(-200px)' },
    trail: 900
  });

  const videoRef = useRef(null);

  useOutsideCallback(videoRef, handleClose);

  const validateEmail = (email, showError = true) => {
    let errorMsg = '';
    let isValid = true;

    if (!email) {
      errorMsg = 'El camp no pot estar buit';
      isValid = false;
    } else if (!email.includes('@')) {
      errorMsg = 'La direcció de correu electrònic no és vàlida';
      isValid = false;
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      errorMsg = 'La direcció de correu electrònic no és vàlida';
      isValid = false;
    }

    if (showError && errorMsg) {
      setError(errorMsg);
    }
    return isValid;
  };

  const handleEmailChange = (email) => {
    setEmail(email);
    setError('');
  };

  const handleSubmitEmail = async (email) => {
    if (validateEmail(email, true) && !loading) {
      setLoading(true);
      const response = await api.subToNewsLetter({ email });

      if (response.statusNumber === 201) {
        // console.log(email + ' Subscribed to newsletter');
        setEmail('');
        setError('');
        setEmailSubscribed(true);
      } else if (response.statusNumber === 400) {
        setError(response.message);
        // console.log('Error subscribing to newsletter');
        // console.log(response);
      } else {
        setError('Error al registrar el correu electrònic');
        // console.log('Error subscribing to newsletter');
        // console.log(response);
      }
      setLoading(false);
    }
  };

  return (
    <div className='landing__container'>
      <Modal
        visible={show}
        size={'none'}
      >
        <div ref={videoRef} className='video-landing-container'>
          <iframe src={videoMap[i18n.resolvedLanguage]} width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
      </Modal>
      <Navbar></Navbar>
      <section className={'landing__top-section'}>
        <button
          type='button'
          className='colors-btn'
          onClick={() => navigate('/login')}
        >
          <span className='colors-btn__text'>Vés-hi al login →</span>
        </button>
        <div className='landing__top-space-between'>
          <div className='intro'>
            <h1>
              <Trans i18nKey={'landing_title'} t={t}>
                {'Registra’t<br/>Comparteix<br/>Dona'}
              </Trans>
            </h1>
            <span>
              {
                t('landing_youdrop_desc')
              }
            </span>
            <button
            onClick={() => document.getElementById('newsletter').scrollIntoView({ behavior: 'smooth' })}
            type='button'
            className='btn btn-primary btn-follow-us'
          >
            {
              t('landing_follow_us')
            }
          </button>
          </div>
          <picture>
            <img src={dropCharImage} alt='fluidra char'/>
          </picture>
        </div>
        <div className='landing__top-center'>
          <img src={heartSVG} alt='heart'></img>
          <h2>
            {
              t('landing_subtitle')
            }
          </h2>
          <span>
            {
              t('landing_subtitle_desc')
            }
          </span>
          <Animation
            className={'mockup-wrapper'}
            delay={700}
            animation={(inView) => (
              (!inView && { opacity: 0, transform: 'translateY(200px)' }) || { opacity: 1, transform: 'translateY(0px)' }
            )}
          >
            <picture>
              <img src={resolvePlatformImage(i18n.resolvedLanguage)} alt='fludidra platform' />
            </picture>
          </Animation>
        </div>
      </section>
      <section className='landing__body-section'>
        <div className='intro'>
          <h2>
            {
              t('landing_body_title')
            }
          </h2>
          <span>
            {
              t('landing_body_desc')
            }
          </span>
        </div>
        <div className='landing-boxes'>
          <Animation
            delay={400}
            animation={(inView) => (
              (!inView && { opacity: 0 }) || { opacity: 1 }
            )}>
              <div className='landing-box'>
                <img src={registerIconSVG}/>
                <span>
                  {
                    t('landing_body_box1')
                  }
                </span>
                <span>
                  {
                    t('landing_body_box1_desc')
                  }
                </span>
              </div>
          </Animation>
          <Animation
            delay={700}
            animation={(inView) => (
              (!inView && { opacity: 0 }) || { opacity: 1 }
            )}>
            <div className='landing-box'>
              <img src={shareIconSVG}/>
              <span>
                {
                  t('landing_body_box2')
                }
              </span>
              <span>
                {
                  t('landing_body_box2_desc')
                }
              </span>
            </div>
          </Animation>
          <Animation
            delay={100}
            animation={(inView) => (
              (!inView && { opacity: 0 }) || { opacity: 1 }
            )}>
              <div className='landing-box'>
                <img src={giveIconSVG}/>
                <span>
                  {
                    t('landing_body_box3')
                  }
                </span>
                <span>
                  {
                    t('landing_body_box3_desc')
                  }
                </span>
              </div>
          </Animation>
        </div>
        <div ref={videoView.ref} className='video-section'>
          <div className='video-intro'>
            {
              transitionsTextTitleVideo((style, item) => (
                item && (
                  <animated.h2 style={style}>
                    {
                      t('landing_video_title')
                    }
                  </animated.h2>
                )
              ))
            }
            {
              transitionsTextVideo((style, item) => (
                item && (
                  <animated.span style={style}>
                    {
                      t('landing_video_desc_1')
                    }
                      <p>&nbsp;</p>
                    {
                      t('landing_video_desc_2')
                    }
                      <p>&nbsp;</p>
                    {
                      t('landing_video_desc_3')
                    }
                  </animated.span>
                )
              ))
            }
          </div>
          <div className='landing-fakevideo-wrapper'>
            <Animation
              delay={1000}
              animation={(inView) => (!inView && { opacity: 0.5, filter: 'blur(3px)' }) || { opacity: 1, filter: 'blur(0px)' }}
            >
              <img className='landing-videoplayer' src={frameVideoImage} alt='video frame' onClick={handleShow}/>
            </Animation>
            <img className='landing-play-btn' src={playIconSVG}></img>
          </div>
        </div>
        <div className='landing-president-quote'>
          <picture>
            <img src={presidentImage} alt='fludidra platform' />
          </picture>
          <div className='big-quote-wrapper'>
            <div>
              <span className='big-quote'>
              {
                t('landing_quote')
              }
              </span>
              <span className='big-quote__president-name'>
                <strong>Joan Planes</strong><br></br>
                {
                  t('landing_quote_president')
                }
              </span>
            </div>
          </div>
        </div>
      </section>
      <footer id='newsletter' className='landing-footer'>
        <div ref={footerView.ref} className='landing-footer__inner'>
        {
          (<Animation
            delay={900}
            disableExit={true}
            animation={(inView) => (
              (!inView && { opacity: 0, transform: 'translateX(-200px)' }) || { opacity: 1, transform: 'translateX(0px)' }
            )}>
              <h2>
                {
                  t('landing_follow_us')
                }
              </h2>
              <span>
              {
                t('landing_more_info_desc')
              }
              </span>
                {
                  !emailSubscribed && (
                    <form onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmitEmail(email);
                    }}>
                      <div className={clsx('landing-footer__email-wrapper', error && 'landing-footer__email-wrapper--error')}>
                        <input
                          onChange={(e) => handleEmailChange(e.target.value)}
                          onBlur={() => validateEmail(email)}
                          value={email}
                          size='30'
                          type='email'
                          placeholder={t('landing_email_placeholder')}
                        />
                        <button
                          // disabled={!error && !validateEmail(email, false)}
                          type='submit'
                          disabled={loading}
                          className={clsx('btn btn-primary btn--email', error && 'btn--email--error')}
                          onMouseEnter={() => validateEmail(email)}
                          // onClick={() => handleSubmitEmail(email)}
                        >
                          {
                          !error && (<>
                            <div>
                              <img src={sendSVG} />
                            </div>

                            <span>
                              {t('landing_email_button')}
                            </span>
                          </>)
                          }
                        </button>
                      </div>
                    </form>
                  )
                }
              {
                emailSubscribed && (
                  <div className='email-sub-box'>
                    <img src={blueHeartSVG} alt='heart'></img>
                    <span className='email-sub-box__title'>
                      {
                        t('landing_email_subscribed')
                      }
                    </span>
                    <span>
                      {
                        t('landing_email_subscribed_desc')
                      }
                    </span>
                  </div>
                )
              }
              {
                !emailSubscribed && error && <span className='landing-email__error'>
                  {error}
                </span>
              }
            </Animation>
          )
        }
        {
          <Animation
            delay={600}
            className='footer-picture-wrapper'
            disableExit={true}
            animation={(inView) => (
              (!inView && { opacity: 0, transform: 'translateX(200px)' }) || { opacity: 1, transform: 'translateX(0px)' }
            )}>
            <picture className='footer-picture'>
                <img src={footerPeopleSVG} alt='footer people'/>
            </picture>
          </Animation>
        }
        </div>
        <Footer border={true}></Footer>
      </footer>
    </div>
  );
};

export { LandingPage };
