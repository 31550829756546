import { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { animated, useTransition } from '@react-spring/web';

import { COLOR_CONTEXT } from '/src/context/ColorProvider';

import { CheckBox } from '/src/components/CheckBox';
import { IconTick } from '/src/components/svg/IconTick';
import { Button } from '/src/components/Button';

import svgEyeShow from '/src/assets/icons/eye.svg';
import svgEyeHide from '/src/assets/icons/eye_slash.svg';

import api from '/src/services/api';

import './styles/register.css';

const initialValidations = {
  // name: { validated: true, message: '' },
  email: { validated: true, message: '' },
  password: { validated: true, message: '' },
  // passwordConfirmation: { validated: true, message: '' },
  identifier: { validated: true, message: '' },
  terms: { validated: true, message: '' }
  // acceptEmails: { validated: true, message: '' }
};

export const RegisterPage = (props) => {
  const navigate = useNavigate();

  const state = useLocation().state || { success: false };
  const { success } = state;

  const { selectedColor } = useContext(COLOR_CONTEXT);

  const [form, setForm] = useState({
    name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    identifier: '',
    terms: false,
    acceptEmails: false
  });

  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [validations, setValidations] = useState(initialValidations);
  const [passwordFocused, setPasswordFocused] = useState(false);

  const linkColors = {
    color: selectedColor?.color2,
    textDecoration: 'underline',
    textDecorationThickness: '0.3rem',
    textUnderlineOffset: '0.6rem'
  };
  const linkStyle = window.location.pathname.includes('register') ? linkColors : {};

  const transitions = useTransition(success, {
    from: { opacity: 0, transform: 'translateX(-300px)' },
    enter: { opacity: 1, transform: 'translateX(0px)' },
    leave: { opacity: 0, transform: 'translateX(300px)' }
  });

  const validateForm = (name) => {
    const {
      email,
      password,
      identifier,
      terms
    } = form;

    const formValidations = { ...initialValidations };

    if (email.length === 0) {
      formValidations.email.message = 'El camp email no pot estar buit';
      formValidations.email.validated = false;
    }

    if (password.length === 0) {
      formValidations.password.message = 'El camp contrasenya no pot estar buit';
      formValidations.password.validated = false;
    }

    if (identifier.length === 0 && identifier.length === 20) {
      formValidations.identifier.message = 'El camp identificador no pot estar buit';
      formValidations.identifier.validated = false;
    }

    if (!terms) {
      formValidations.terms.message = 'Has d\'acceptar les condicions';
      formValidations.terms.validated = false;
    }

    setValidations({ ...formValidations });

    // console.log(Object.values(formValidations));
    return !Object.values(formValidations).some(({ validated }) => !validated);
  };

  const invalidateFormValue = (name, message) => {
    if (name === 'license') name = 'identifier';

    const formValidations = { ...validations };
    formValidations[name].message = message;
    formValidations[name].validated = false;
    setValidations({ ...formValidations });
  };

  const validateFormValue = (name) => {
    const formValidations = { ...validations };
    formValidations[name].message = '';
    formValidations[name].validated = true;
    setValidations({ ...formValidations });
  };

  // console.log(validations);

  const handleBlur = (event) => {
    if (event.target.id === 'password') {
      setPasswordFocused(false);
    }
    if (!event.target.validity.valid) {
      invalidateFormValue(event.target.id, 'El camp no és vàlid');
    }
  };

  const handleInputChange = (event, name) => {
    form[name] = event.target.value;
    setForm({ ...form });

    if (event.target.validity.valid) {
      if (name === 'password') {
        setPasswordFocused(false);
      }

      validateFormValue(name);
    } else if (name === 'password') {
      setPasswordFocused(true);
    }
  };

  const handleSubmitForm = async () => {
    setLoading(true);

    if (validateForm()) {
      const result = await api.register({
        terms: form.terms,
        email: form.email,
        password: form.password,
        license: form.identifier,
        colorsetID: selectedColor?.id || 1
      });

      if (result instanceof Error) {
        return;
      };

      if (result.status === 'error') {
        if (result.parameter) {
          invalidateFormValue(result.parameter, 'Comprova aquest camp');
        }
      } else {
        setLoading(false);

        navigate('/login/register', {
          state: {
            success: true
          }
        });
      }
    }
    setLoading(false);
  };

  if (success) {
    return transitions((style, item) => (
      item && (
        <div className='entrance-box'>
          <animated.div style={style} className='register-success-container'>
            <span className='form-title'>Registre completat!</span>
            <span className='register-success-desc'>Revisa el teu correu per rebre un email de confirmació. Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incidid unt ut labore et dolore magna aliqua.</span>
            <button
              style={{ backgroundColor: selectedColor?.color2 }}
              onClick={() => navigate('/login/register')}
              type='button'
              className='form-button'
            >
              ENTESOS
            </button>
          </animated.div>
        </div>
      )
    ));
  }

  return (
    <div className='entrance-box'>
      <div className='entrance-box__header'>
        <span
          className='entrance-box__header__option'
        >
          <a href="" onClick={(e) => {
            e.preventDefault();
            navigate('/login');
          }}>INICIAR SESSIÓ</a>
        </span>
        <span
          style={linkStyle}
          className='entrance-box__header__option'
        >
          <a href="" onClick={(e) => {
            e.preventDefault();
            navigate('/login/register');
          }}>REGISTRAR-SE</a>
        </span>
      </div>
      <span className='form-title'>Completa el formulari</span>
      <form
        className='form'
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitForm();
        }}
      >
        <div className={`form-group ${passwordFocused && 'from-group--hidden'}`}>
          <label className='form__label'>
            <span className={`form__label__text ${!validations.identifier.validated && 'form__label__text-error'}`}>
              Identificador
            </span>
            <input
              onChange={(e) => handleInputChange(e, 'identifier')}
              value={form.identifier}
              onBlur={handleBlur}
              type='text'
              id='identifier'
              minLength={20}
              className={`form-control ${!validations.identifier.validated && 'form-control--error'}`}
              required={true}
            />
            {
              !validations.identifier.validated && (
                <span className='form__label__error-message'>
                  {validations.identifier.message}
                </span>
              )
            }
          </label>
        </div>
        <div className='form-group'>
          <label className='form__label'>
            <span className={`form__label__text ${!validations.email.validated && 'form__label__text-error'}`}>
              Email
            </span>
            <input
              onChange={(e) => handleInputChange(e, 'email')}
              value={form.email}
              onBlur={handleBlur}
              type='email'
              id='email'
              className={`form-control ${!validations.email.validated && 'form-control--error'}`}
              required={true}
            />
            {
              !validations.email.validated && (
                <span className='form__label__error-message'>
                  {validations.email.message}
                </span>
              )
            }
          </label>
        </div>
        <div className='form-group'>
          <label className='form__label'>
            <span className={`form__label__text ${!validations.password.validated && 'form__label__text-error'}`}>
                Contrasenya
            </span>
            <input
              onChange={(e) => handleInputChange(e, 'password')}
              value={form.password}
              onBlur={handleBlur}
              onFocus={() => setPasswordFocused(true)}
              type={showPassword ? 'text' : 'password'}
              id='password'
              minLength={8}
              className={`form-control ${!validations.password.validated && 'form-control--error'}`}
              pattern='^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,32}$'
            />
            {
              !validations.password.validated && (
                <span className='form__label__error-message'>
                  {validations.password.message}
                </span>
              )
            }
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowPassword(!showPassword);
              }}
              type='button'
              className='password-eye'
            >
              <object
                className='password-eye__icon'
                data={showPassword ? svgEyeShow : svgEyeHide}
                width="20" height="18"
                type='image/svg+xml'
              />
            </button>
          </label>
        </div>
        {
          (<div className={`password-box ${passwordFocused && 'password-box--show'}`}>
            <span className='password-box__info'>La contrasenya ha de incloure :</span>
            <div className='password-box__badges-wrapper'>
              <span className='password-box__badge'>
                {
                  form.password.length >= 8 && (<div className='password-box__check-icon'>
                    <IconTick fill={selectedColor?.color2} />
                  </div>)
                }
                Mínim 8 caràcters
              </span>
              <span className='password-box__badge'>
                {
                  /(?=.*[A-Z])/.test(form.password) && (<div className='password-box__check-icon'>
                    <IconTick fill={selectedColor?.color2} />
                  </div>)
                }
                1 lletra majúscula
              </span>
              <span className='password-box__badge'>
                {
                  /(?=.*[0-9])/.test(form.password) && (<div className='password-box__check-icon'>
                  <IconTick fill={selectedColor?.color2} />
                  </div>)
                }
                1 número
              </span>
              <span className='password-box__badge'>
                {
                  /(?=.*[a-z])/.test(form.password) && (<div className='password-box__check-icon'>
                    <IconTick fill={selectedColor?.color2} />
                  </div>)
                }
                1 lletra minúscula
              </span>
              <span className='password-box__badge'>
                {
                  /(?=.*[!@#$%^&*])/.test(form.password) && (<div className='password-box__check-icon'>
                    <IconTick fill={selectedColor?.color2} />
                  </div>)
                }
                1 símbol: ! @ + - . $ € % = #
              </span>
            </div>
          </div>)
        }
        {/* <div className='form-group'>
          <label className='form__label'>
            <span className='form__label__text'>Confirma la contrasenya</span>
            <input
              onChange={(e) => setForm({ ...form, passwordConfirmation: e.target.value })}
              value={form.passwordConfirmation}
              type='password'
              minLength={8}
              className='form-control'
              required
            />
          </label>
        </div> */}
        <div className='form-checkboxes'>
          <label
            className={`form__checkbox__label ${!validations.terms.validated && 'form__checkbox__label--error'}`}
            htmlFor='checkbox'
          >
            <CheckBox
              checked={form.terms}
              onChange={(event) => {
                if (!form.terms) {
                  validations.terms.validated = true;
                  setValidations({ ...validations });
                }
                setForm({ ...form, terms: !form.terms });
              }}
            />
            <span className='form__checkbox-text'>
              Accepto els termes i condicions
            </span>
          </label>
        </div>
        <Button
          style={{ backgroundColor: selectedColor?.color2 }}
          type='submit'
          className='form-button'
          disabled={loading}
        >
          REGISTRAR-ME
        </Button>
      </form>
    </div>
  );
};
