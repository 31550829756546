import * as styles from './ProgressStats.module.css';

const ProgressStats = ({ title, goals }) => {
  return (
    <div>
      <span className={styles.title}>{title}</span>
      <div className={styles.box}>
        {
          goals.map((goal, i) => (
            <div className={styles.innerBox} key={i}>
              <span className={styles.statNumber}>{goal.number}</span>
              <span className={styles.statName}>{goal.title}</span>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default ProgressStats;
