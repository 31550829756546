import { useReducer } from 'react';

import { useTranslation } from 'react-i18next';

const regexValidations = {
  name: /^[a-zA-ZáéíñóúüÁÉÍÑÓÚÜàèìòùÀÈÌÒÙ´` ][a-zA-ZáéíñóúüÁÉÍÑÓÚÜàèìòùÀÈÌÒÙ´`, ]*$/,
  textarea: /^[a-zA-Z0-9áéíñóúüÁÉÍÑÓÚÜàèìòùÀÈÌÒÙ´`@,;.:¡!¿?\-+/()\s\r\n]*$/,
  email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.@#$%^&*]).{8,32}$/,
  // add currency to allow only numbers and commas
  currency: /^[0-9.]*$/,
  default: /.*/
};

/**
 * Will return an error message depending on the complexity of the string entered as the password parameter.
 * @param {string} password - The password to validate
 * @param {function} t - The translation function
*/
const validatePasswordStrength = (password, t) => {
  if (!/(?=.*[0-9])/.test(password)) { // Check if password contains at least one number
    return t('useForm.missingNumber');
  } else if (!/.{8,32}/.test(password)) { // Check if password length is between 8 and 32 characters
    return t('useForm.shortLength');
  } else if (!/(?=.*[a-z])/.test(password)) { // Check if password contains at least one lowercase letter
    return t('useForm.missingLowerCase');
  } else if (!/(?=.*[A-Z])/.test(password)) { // Check if password contains at least one uppercase letter
    return t('useForm.missingUpperCase');
  } else if (!/(?=.*[@.#$%^&*])/.test(password)) { // Check if password contains at least one of the following characters @ . # $ % ^ & *
    return t('useForm.missingSpecialChar');
  } else {
    // If all criteria are met, return an empty string
    return '';
  }
};

/**
* Generates a form object
* Availables types:
* - "name" (only letters and spaces)
* - "email"
* - "password"
* - "currency"
* @param {object} fields - The fields of the form
* @returns {object} - The form object
*/
function generateFormObject (fields) {
  const formObject = {
    validations: {},
    types: {},
    customRegex: {}
  };

  for (const key in fields) {
    formObject.validations[key] = {
      message: '',
      isValid: false,
      required: fields[key].required ??= true
    };

    formObject.types[key] = fields[key].type;

    if (fields[key].regex) {
      formObject.customRegex[key] = fields[key].regex;
    }

    formObject[key] = '';
  }

  return formObject;
}

function formatCurrency (n) {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

/**
  * This function returns previous input (used when the input isn't valid)
  * @param {string} prevValue - The old text of the input
  * @param {string} newValue - The new value of the input
  * @returns {string} - The text to show
  * @example
  * getText('old text', 'new value') // => 'old text'
*/
const getPrevInput = (prevValue, newValue) => (
  newValue === '' ? '' : prevValue
);

/**
  * This function returns the error message to show in the input
  * @param {string} lastChar - The last character of the input
  * @param {function} t - The translation function
  * @returns {string} - The error message to show
  * @example
  * getErrorMessage('a', t) // => 'Invalid character (a)'
  * getErrorMessage(undefined, t) // => 'This field is required'
*/
const getErrorMessage = (lastChar, t) => (
  lastChar === undefined ? t('useForm.emptyField') : `${t('useForm.invalidChar')} (${lastChar})`
);

const getEmailErrorMessage = (lastChar, t) => (
  lastChar === undefined ? t('useForm.emptyField') : `${t('useForm.invalidEmail')}`
);

/**
  * This hook returns a form state and methods to change the form values
  * @param {object} formInitialFields - The initial fields of the form
  * @returns {object} - The form object and the functions to change the form values
  * @example
  * const { form, changeFormValue, resetValidation } = useForm({
  *  name: {
  *   type: 'name'
  * },
  * lastname: {
  *  type: 'name'
  * },
  * position: {
  *   type: 'name'
  * }
  * });
  * @example
  *   <input
  *     type='textarea'
  *     value={form.name}
  *     onChange={(e) => changeFormValue({ key: 'name', value: e.target.value })}
  *     onBlur={() => resetValidation('name')}
  *    />
  * @example
  * <p className='form-error'>
  *   {form.validations.name.message}
  * </p>
*/
const useForm = (
  formInitialFields = {
    name: {
      type: 'name'
    },
    lastname: {
      type: 'name'
    },
    position: {
      type: 'name'
    }
  }
) => {
  const { t } = useTranslation();

  const convertedFields = generateFormObject(formInitialFields);

  // console.log('convertedFields', convertedFields);

  const [form, dispatch] = useReducer(
    (form, action) => {
      const actionByKey = form.types && form.types[action.key];
      const isValid = action.value === undefined ||
        (convertedFields.customRegex[action.key]
          ? convertedFields.customRegex[action.key].test(action.value)
          : regexValidations[action?.type || actionByKey]?.test(action.value)) ||
        false;

      // console.log('action', action);
      // console.log('actionByKey', actionByKey);
      // console.log(isValid, 'isValid');

      switch (action.type || actionByKey) {
        case 'name':
        case 'textarea':
          return {
            ...form,
            [action.key]: isValid ? action.value : getPrevInput(form[action.key], action.value),
            validations: {
              ...form.validations,
              [action.key]: {
                message: isValid ? '' : getErrorMessage(action.value[action.value.length - 1], t),
                isValid
              }
            }
          };
        case 'currency':
          return {
            ...form,
            [action.key]: isValid ? formatCurrency(action.value) : getPrevInput(form[action.key], action.value),
            validations: {
              ...form.validations,
              [action.key]: {
                message: isValid ? '' : getErrorMessage(action.value[action.value.length - 1], t),
                isValid
              }
            }
          };
        case 'password':
          return {
            ...form,
            [action.key]: action.value,
            validations: {
              ...form.validations,
              [action.key]: {
                message: isValid ? '' : validatePasswordStrength(action.value, t),
                isValid
              }
            }
          };
        case 'email':
          return {
            ...form,
            [action.key]: action.value,
            validations: {
              ...form.validations,
              [action.key]: {
                message: isValid ? '' : getEmailErrorMessage(action.value, t),
                isValid
              }
            }
          };
        case 'resetValidation':
          return {
            ...form,
            validations: {
              ...form.validations,
              [action.key]: {
                message: '',
                isValid: true
              }
            }
          };

        default:
          throw new Error('Invalid action type ' + action.type || actionByKey);
      }
    },
    convertedFields
  );

  const changeFormValue = ({
    value,
    key
  }) => {
    // console.log('changeFormValue', value, key);
    dispatch({
      key,
      value
    });
  };

  const resetValidation = ({
    key
  }) => {
    dispatch({
      type: 'resetValidation',
      key
    });
  };

  const resetAllValidations = () => {
    for (const key in form.validations) {
      dispatch({
        type: 'resetValidation',
        key
      });
    }
  };

  const checkValidation = () => {
    let isValid = true;

    for (const key in form.validations) {
      if (!form.validations[key].isValid && form.validations[key].required) {
        isValid = false;
        break;
      }
    }

    return isValid;
  };

  return {
    form,
    changeFormValue,
    resetValidation,
    checkValidation,
    resetAllValidations
  };
};

export default useForm;
