import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const MediaQuery = ({ query = '(min-width: 62rem)', children }) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const handleResize = (event) => {
      setMatches(window.matchMedia(query).matches);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [query]);

  if (matches) {
    return children;
  } else {
    return null;
  }
};

MediaQuery.propTypes = {
  query: PropTypes.string,
  children: PropTypes.node
};
