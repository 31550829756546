import svg404 from '../../assets/images/errors/ilustracio_error_500.svg';

import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import * as styles from './errors.module.css';

// To make Page500 work, you need to add a state to the location object
// Example:
// <Navigate to="/500" state={{ isError: true }} />
export const Page500 = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const isError = location.state?.isError || false;

  // If you try to access directly to 500 page, it will redirect to home
  if (!isError) {
    return <Navigate to="/" />;
  }

  return (
    <div
      className={styles.container}
    >
      <div className={styles.innerWrapper}>
        <div className='youdrop-logo youdrop-logo--white youdrop-logo--top youdrop-logo--long' alt="YouDrop Logo" />
        <img className={styles.errorImage} type="image/svg+xml" src={svg404}/>
        <h1 className={styles.title}>
          {
            t('error500_title')
          }
        </h1>
        <span className={styles.description}>
          {
            t('error500_text')
          }
        </span>
        <button
          onClick={() => navigate('/')}
          className={styles.button}
        >
          {
            t('error500_go_home')
          }
        </button>
      </div>
    </div>
  );
};
