import clsx from 'clsx';
import PropTypes from 'prop-types';

import './style.css';

const ColorPicker = ({ handleClick, isSelected, hasBorder, color, id }) => (
  <div
    className={clsx(
      'color-picker__container',
      hasBorder && 'color-picker__container--less-width'
    )}
  >
    <button
      type='button'
      onClick={() => handleClick(id)}
      style={{
        backgroundColor: color
      }}
      className={
        clsx(
          'color-picker',
          isSelected && 'color-picker--bigger',
          hasBorder && 'color-picker--bordered'
        )
      }
    />
  </div>
);

ColorPicker.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  hasBorder: PropTypes.bool,
  color: PropTypes.string,
  id: PropTypes.number
};

export { ColorPicker };
