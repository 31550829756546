.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F4F6F7;
}

.innerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 200px;
  height: 189px;
  margin-bottom: 30px;
}

.title {
  font: normal normal 900 3rem/4.3rem Rubik;
  color: #212529;
  margin-bottom: 30px;
}

.description {
  display: block;
  font: normal normal normal 2rem/2.8rem Karla;
  color: #212529;
  margin-bottom: 80px;
  max-width: 325px;
  text-align: center;
}

.button {
  background: var(--color2);
  border-radius: 100px;
  padding: 15px 40px;
  font: normal normal 900 2rem/4.3rem Rubik;
  color: #FFFFFF;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.button:hover {
  transform: scale(1.05);
}

@media screen and (min-width: 62rem) {
  .title {
    font: normal normal 900 3.6rem/4.7rem Rubik;
  }
  .description {
    font: normal normal normal 2rem/2.8rem Karla;
    margin-bottom: 100px;
    max-width: 542px;
  }
}

