import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLOR_CONTEXT } from '/src/context/ColorProvider';

import clsx from 'clsx';

import { animated, useTransition } from '@react-spring/web';

import api from '/src/services/api';
import { MailSvg } from '/src/components/svg/Mail';

import { Button } from '/src/components/Button';

import './styles/reset.css';

const Footer = () => (
  <div className='reset-footer'>
    <span className='reset-footer__text'>
      Per a qualsevol dubte o problema pots escriuren’s a
    </span>
    <a className='reset-footer__link' href='mailto:info@youdrop.org'>
      info@youdrop.org
    </a>
  </div>
);

export const ResetPage = () => {
  const navigate = useNavigate();

  const { selectedColor } = useContext(COLOR_CONTEXT);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async () => {
    setLoading(true);

    const result = await api.recoverPassword({ email });

    // console.log(result);
    if (result.statusNumber === 200) {
      setSuccess(true);
    } else if (result.statusNumber === 401) {
      setError('Email invàlid');
    } else {
      setError('Error no esperat, si us plau, intenta-ho de nou.');
    }

    setLoading(false);
  };

  const handleAcceptConfirmation = () => {
    navigate('/login');
  };

  const transitions = useTransition(showConfirmation, {
    from: { opacity: 0, transform: 'translateX(230px)' },
    enter: { opacity: 1, transform: 'translateX(0px)' }
    // leave: { opacity: 0, transform: 'translateX(-230px)' },
  });

  const transitions2 = useTransition(!success, {
    from: { opacity: 0, transform: 'translateX(230px)' },
    enter: { opacity: 1, transform: 'translateX(0px)' },
    leave: { opacity: 0, transform: 'translateX(-230px)', display: 'block' },
    onChange: (animation) => {
      if (success && animation.value.display === 'block') {
        if (showConfirmation === false) {
          animation.value.opacity < 0.2 && setShowConfirmation(true);
        }
      }
    }
  });

  return (
    <div className='entrance-box entrance-box--minheight'>
      {
        transitions((styles, item) => item && (
          <animated.div style={styles} className='reset-container reset-container--success'>
            <span className='reset-title'>Mira el teu correu</span>
            <span className='reset-page-desc'>T’ hem envia’t un email amb les instruccions per restablir la teva contrasenya.</span>
            <div className='flex-center reset-icon'>
              <MailSvg color={selectedColor?.color2}/>
            </div>
            <button
                style={{ backgroundColor: selectedColor?.color2 }}
                onClick={() => handleAcceptConfirmation()}
                type='submit'
                className='form-button'
              >
                ENTESOS
            </button>
            <Footer/>
          </animated.div>
        ))
      }
      {
        transitions2((styles, item) => item && (
          <animated.div style={styles} className='reset-container'>
          <span className='reset-title'>Has oblidat la teva contrasenya?</span>
          <span className='reset-page-desc'>Introdueix la teva adreça de correu electrònic i t’enviarem un enllaç per restablir la teva contrasenya.</span>
          <form
            className='form'
            onSubmit={(e) => {
              e.preventDefault();
              handleResetPassword();
            }}
          >
            <div className='form-group'>
              <label className='form__label'>
                <span className={`form__label__text ${error && 'form__label__text-error'}`}>
                  Email
                </span>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  onBlur={() => setError(false)}
                  type='email'
                  id='email'
                  className={`form-control ${error && 'form-control--error'}`}
                  required={true}
                />
                {
                  error && (
                    <span className='form__label__error-message'>
                      Email no valid
                    </span>
                  )
                }
              </label>
            </div>
            <div className='flex-row'>
              <button
                style={{ backgroundColor: selectedColor?.color2 }}
                onClick={() => navigate('/login')}
                type='button'
                className='form-button form-button--50w form-button--secondary'
              >
                Cancelar
              </button>
              <div className='separator--15w'/>
              <Button
                style={{ backgroundColor: selectedColor?.color2 }}
                type='submit'
                className={clsx('form-button form-button--50w')}
                disabled={loading}
              >
                ENVIAR
              </Button>
            </div>
            <Footer/>
          </form>
          </animated.div>
        ))
      }
    </div>
  );
};
