import svgSent from '/src/assets/icons/ilustracio_projecte_enviat.svg';

import PropTypes from 'prop-types';

import { useTranslation, Trans } from 'react-i18next';

import { Link, Navigate, useLocation } from 'react-router-dom';

import * as styles from '../../UploadProject/sent.module.css';

export const DonationSuccess = (props) => {
  const { t } = useTranslation();

  const location = useLocation();
  const isDonation = location.state?.donation || false;

  // If you try to access directly to this page, it will redirect to home
  if (!isDonation) {
    return <Navigate to="/" />;
  }

  const donation = location.state.project.donation;
  const title = location.state.project.title;

  // console.log(location);

  return (
    <div
      className={styles.container}
    >
      <div className={styles.innerWrapper}>
        <img className={styles.image} type="image/svg+xml" src={svgSent}/>
        <h1 className={styles.title}>
          {
            t('donation.title')
          }
        </h1>
        <p className={styles.description}>
          <Trans
            i18nKey='donation.message'
            donation={donation}
            title={title}
            t={t}
          >
            ¡Muchas gracias por tu donación de {{ donation }} € al proyecto {{ title }}!
          </Trans>
        </p>
        <Link to="/home">
          <button className={styles.button}>
            {
              t('sent_go_home')
            }
          </button>
        </Link>
      </div>
    </div>
  );
};

DonationSuccess.propTypes = {
  sent: PropTypes.bool
};
