.box {
  position: relative;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 10px;

  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  min-height: 417px;
  max-width: 100%;
  /* Hide scrollbar for Chrome, Safari and Opera */

   /* Hide scrollbar for IE, Edge and Firefox */
   -ms-overflow-style: none;  /* IE and Edge */
   scrollbar-width: none;  /* Firefox */
}
.box::-webkit-scrollbar {
  display: none;
}

.boxInner {
  position: relative;
  scroll-snap-align: center;
  flex-shrink: 0;
  width: 100%;
  transform-origin: center center;
  padding: 30px 20px 25px 20px;
  background: url(/src/assets/images/portada_Foto2.jpeg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-clip: padding-box;
  /* make text not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: transform 0.5s, background-size 0.3s ease-in-out;
}

.box:hover .boxInner {
  background-size: 110%;
}

.box:hover .boxInner .boxTitle {
  text-decoration: underline;
}
.box:hover .boxInner .boxButton {
  background: var(--color1) 0% 0% no-repeat padding-box;
  color: #FFFFFF;
}

.boxInner * {
  position: relative;
  z-index: 10;
}
.boxTitle {
  display: block;
  text-align: left;
  font: normal normal bold 2.6rem/3.4rem Rubik;
  letter-spacing: -0.78px;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.boxDesc {
  display: block;
  text-align: left;
  font: normal normal normal 16px/21px Karla;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-bottom: 35px;
  min-height: 84px;
}

.boxButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #212529;
  border-radius: 100px;
  height: 40px;
  padding: 0px 20px;
  border: none;
  align-self: flex-start;
  cursor: pointer;
  transition: all 0.3s;
}
.boxButton:hover {
  background: var(--color1) 0% 0% no-repeat padding-box;
  color: #FFFFFF;
}
.boxHead {
  display: flex;
  flex-direction: column;
  margin-bottom: 107px;
  border: none;
  background: none;
  cursor: pointer;
}

.boxGoalLabel {
  display: block;
  text-align: left;
  font: normal normal bold 1.6rem/2.1rem Rubik;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.boxGoalWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 33px;
  text-transform: uppercase;
}


.blackBackground {
  background-color: #000000aa;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0 !important;
  filter: brightness(0.35);
  object-fit: cover;
}

.boxWrapper {
  position: relative;
  width: 100%;
}




@media screen and (min-width: 62rem) {
  .boxGoalLabel {
    font: normal normal bold 1.2rem/1.8rem Rubik;
  }
  .boxHead {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 50px;
  }
  .boxDesc {
    margin-bottom: 25px;
  }
  .boxWrapper {
    height: auto;
  }
  .box {
    min-height: 312px;
    max-height: 100%;
    max-width: 850px;
  }
  .boxButton {
    min-width: 149px;
    font: normal normal bold 1.4rem/1.5rem Karla;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    position: absolute;
    right: 0;
  }
  .boxTitle {
    margin-bottom: 26px;
  }
}

.boxGoalNoMargin {
  margin-bottom: 0px;
}


@media screen and (min-width: 82rem) {
  .boxInner {
    background-size: 100%;
  }
}