.notificationsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notificationsTitle {
  color: #212529;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 2.8rem;
  font-weight: 700;
}

.markAsRead {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 165px;
  margin-left: -25px;
  background: var(--color2);
  display: flex;
  flex-direction: column;
  padding: 29px 36px 0;
}

.markAsRead button {
  color: #FFFFFF;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
  background: rgba(255,255,255, .3);
  border: none;
  min-height: 60px;
  border-radius: 35px;
  padding: 19px 25px;
}

.notificationsMenu {
  display: flex;
  flex-direction: row;
  column-gap: 25px;
}

.notificationsMenu button {
  color: rgba(33, 37, 41, .25);
  font-family: Karla, Roboto, sans-serif;
  font-size: 2rem;
  cursor: pointer;
  background: none;
  border: none;
}

.notificationsMenu button.active {
  color: var(--color1);
  font-family: Karla, Roboto, sans-serif;
  font-weight: 700;
  border-bottom: 3px solid var(--color1);
}

.noNotifications {
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 30px;
}

.noNotifications img {
  height: 130px;
}

.noNotifications h2 {
  color: #212529;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  margin: 0 42px;
  text-align: center;
}

.notificationsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.notificationRow {
  display: flex;
  align-items: center;
  column-gap: 45px;
}

.dateColumn {
  display: none;
}

.card {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  gap: 25px 20px;
}

.thumbnail {
  background: #000000;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title {
  color: #212529;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.date {
  color: #212529;
  margin-bottom: 20px;
}

.text {
  color: rgba(33, 37, 41, 0.5);
  font-family: Karla, Roboto, sans-serif;
}

.time {
  color: rgba(33, 37, 41, 0.5);
  font-family: Karla, Roboto, sans-serif;
  display: flex;
  width: 100%;
  justify-content: end;
}

@media screen and (min-width: 62rem) {
  .notificationsTitle {
    font-size: 3.2rem;
  }

  .markAsRead {
    width: auto;
    height: auto;
    background: none;
    position: relative;
    padding: 0;
  }

  .markAsRead button {
    background: none;
    color: var(--color1);
    font-size: 1.6rem;
    padding: 0;
    min-height: auto;
  }

  .dateColumn {
    display: flex;
    font-weight: 700;
    width: 25%;
    color: #212529;
  }

  .date {
    display: none;
  }

  .time {
    width: 25%;
    align-self: flex-end;
  }
}