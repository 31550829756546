import * as styles from './ProgressBar.module.css';

const calcPercent = (current, goal) => {
  return Math.ceil((current * 100) / goal);
};

const getCirclePosition = (current, goal, circleSize = 15) => {
  const position = calcPercent(current, goal);

  let newPosition = `${position}%`;

  if (position < 15) {
    newPosition = `calc(${position}% - ${circleSize / 2}px)`;
  } else {
    newPosition = `calc(${position}% - ${circleSize}px)`;
  }

  return {
    left: newPosition
  };
};

const ProgressBar = ({ current, goal, options }) => {
  return (
    <div className={styles.boxProgressWrapper}>
      <progress
        className={styles.boxProgress}
        style={options.backgroundColor && { backgroundColor: options.backgroundColor }}
        value={current} max={goal}
      />
      <div
        style={getCirclePosition(current, goal, 15)}
        className={styles.boxProgressCircle}
      >
      </div>
      {
        options.showBubble && (
          <span
            style={getCirclePosition(current, goal, 30)}
            className={styles.boxProgressCircleText}
          >
            {calcPercent(current, goal)}%
          </span>
        )
      }
    </div>
  );
};

ProgressBar.defaultProps = {
  goal: 0,
  current: 0,
  options: {
    backgroundColor: undefined,
    showBubble: false
  }
};

export default ProgressBar;
