import { useTranslation, Trans } from 'react-i18next';

import './legal.css';

import { Link } from 'react-router-dom';

import privacyIcon from '/src/assets/icons/aviso_legal.svg';

import Navbar from '/src/routes/public/landing/components/navbar';
import { Footer } from '/src/components/Footer';

const LegalPage = () => {
  const { t } = useTranslation();

  return (
    <div className='legal-container'>
      <Navbar forceStuck={true}></Navbar>
      <div className='legal-content'>
        <img src={privacyIcon} className='legal-icon' />
        <div className='legal-section'>
          <h1>{t('legal_title_1')}</h1>
          <h2>{t('legal_title_2')}</h2>
          <span>{t('legal_text_2')}</span>
          <span>{t('legal_text_2_1')}</span>
        </div>
        <div className='legal-section'>
          <h2>{t('legal_title_3')}</h2>
          <span>{t('legal_text_3_1')}</span>
          <span>{t('legal_text_3_2')}</span>
        </div>
        <div className='legal-section'>
          <h2>{t('legal_title_4')}</h2>
          <span>{t('legal_text_4_1')}</span>
          <span>{t('legal_text_4_2')}</span>
          <span>{t('legal_text_4_3')}</span>
          <span>{t('legal_text_4_4')}</span>
          <span>{t('legal_text_4_5')}</span>
          <span>{t('legal_text_4_6')}</span>
          <span>{t('legal_text_4_7')}</span>
          <span>{t('legal_text_4_8')}</span>
          <span>{t('legal_text_4_9')}</span>
        </div>
        <div className='legal-section'>
          <h2>{t('legal_title_5')}</h2>
          <span>{t('legal_text_5_1')}</span>
          <span>{t('legal_text_5_2')}</span>
        </div>
        <div className='legal-section'>
          <h2>{t('legal_title_6')}</h2>
          <span>{t('legal_text_6_1')}</span>
          <span>{t('legal_text_6_2')}</span>
        </div>
        <div className='legal-section'>
          <h2>{t('legal_title_7')}</h2>
          <span>{t('legal_text_7_1')}</span>
        </div>
        <div className='legal-section'>
          <h2>{t('legal_title_8')}</h2>
          <span>{t('legal_text_8_1')}</span>
        </div>
        <div className='legal-section'>
          <h2>{t('legal_title_9')}</h2>
          <span>{t('legal_text_9_1')}</span>
          <span>{t('legal_text_9_2')}</span>
        </div>
        <div className='legal-section'>
          <h2>{t('legal_title_10')}</h2>
          <span>{t('legal_text_10')}</span>
        </div>
      </div>
      <Footer color={'blue'}></Footer>
    </div>
  );
};

export default LegalPage;
