function checkIfImageExists (url, callback) {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(undefined, true);
  } else {
    img.onload = () => {
      callback(undefined, true);
    };

    img.onerror = () => {
      callback(undefined, false);
    };
  }
}

export default checkIfImageExists;
