import './help.css';

import { useTranslation } from 'react-i18next';

export const Help = () => {
  const { t } = useTranslation();

  return (
    <div className='page-container'>
      <h1 className='page-container__title'>
        {
          t('help_title')
        }
      </h1>
      <p className='help-text'>
        {
          t('help_text')
        }
      </p>
      <a className='help-mail' href='mailto:info@youdrop.org'>info@youdrop.org</a>
    </div>
  );
};
