.title {
  font: normal normal bold 1.4rem/2.1rem Karla;
  color: var(--color1);
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 11px;
}

.box {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  height: 152px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  margin-left: -25px;
  margin-right: -25px;
  padding: 20px 0px 20px 0px;
}

.innerBox {
  border-right: 1px solid rgba(var(--rgbcolor), 0.3);
  width: auto;
  padding-left: 25px;
  padding-right: 25px;
}
.innerBox:last-child {
  border-right: none;
}
.statNumber {
  display: block;
  font: normal normal bold 3rem/3.9rem Rubik;
  color: var(--color1);
}

.statName {
  display: block;
  font: normal normal bold 1.6rem/2.1rem Karla;
  text-align: left;
  color: #212529;
}

@media screen and (min-width: 62rem) {
  .box {
    min-width: 425px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 10px;
    max-height: 120px;
  }
  .statNumber {
    font: normal normal bold 1.8rem/2.1rem Rubik;
    margin-bottom: 13px;
  }
  .innerBox {
    padding-left: 15px;
    padding-right: 15px;
  }
  .statName {
    font: normal normal bold 1.2rem/1.6rem Karla;
  }
  .title {
    font: normal normal bold 1.4rem/2.1rem Karla;
  }
}

@media screen and (min-width: 82rem) {
  .box {
    padding-top: 26px;
    padding-bottom: 26px;
    max-height: 135px;
  }
  .statNumber {
    font: normal normal bold 24px/21px Rubik;
  }
  .innerBox {
    padding-top: 10px;
    padding-bottom: 5px;
    min-width: 136px;
  }
  .statName {
    font: normal normal bold 13px/17px Karla;
  }
  .innerBox {
    padding-left: 20px;
    padding-right: 20px;
  }

}
